import React, { useEffect, useState, useReducer, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL, IMG_URL } from "../../constant";
import * as geolib from "geolib";
import { INITIAL_STATE, searchedDataReducer } from "../../reducers/search";
// import {useBannerSearch} from '../../hooks/useBannerSearch';
import characterSearch from '../../hooks/CharacterSearch';
import { useSelector } from "react-redux";
import { searchBannerDropdownDisplayStatus } from "../../redux/actions/action";


const BannerSearchBox = () => {
  const [searchListingData, setsearchListingData] = useState([]);
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(searchedDataReducer, INITIAL_STATE);
  const [userLongitude, setUserLongitude] = useState();
  const [userLatitude, setUserLatitude] = useState();
  const [searchingBoxCategories, setsearchingBoxCategories] = useState([]);
  const [searchFoundDataInAPI, setsearchFoundDataInAPI] = useState([]);
  const [latitude, setlatitude] = useState(28.7119444);
  const [longitude, setlongitude] = useState(77.102025);
  const [convertedDistance, setconvertedDistance] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [serviceName, setserviceName] = useState("");
  const [notFoundText, setnotFoundText] = useState(false);
  const [catSelected, setCatSelected] = useState("");
  const [mainData, setMainData] = useState([]);
  const [subData, setSubData] = useState([]);

  const searchBannerDisplayStatus = useSelector((state) => state.searchBannerDropdownDisplayStatus)

 
  useEffect(() => {
    setnotFoundText(false);

    navigator.geolocation.getCurrentPosition(function (position) {
      // console.log(position.coords.latitude,position.coords.longitude )
      setUserLatitude(position.coords.latitude);
      setUserLongitude(position.coords.longitude);
      localStorage.setItem("lat", JSON.stringify(position.coords.latitude));
      localStorage.setItem("long", JSON.stringify(position.coords.longitude));
    });
    if (!JSON.parse(window.localStorage.getItem("categories"))) {
      getSearchingCategories();
    } else {
      setsearchingBoxCategories(
        JSON.parse(window.localStorage.getItem("categories"))
      );
    }
  }, []);

  async function getSearchingCategories() {
    await axios({
      method: "POST",
      url: API_URL + "search/main-category-name",
    }).then((response) => {
      if (response.data.success === true) {
        localStorage.setItem("categories", JSON.stringify(response.data.data));

        setsearchingBoxCategories(response.data.data);
      }
    });
  }

  // useEffect(() => {
  //     console.log(searchingBoxCategories)
  // },[searchingBoxCategories])

  function convertLatLongToKM(data) {
    const kmDistance = [];
    let newSearchData = [...data];
    // console.log(newSearchData);
    // console.log(userLatitude, userLongitude);
    let lat = userLatitude;
    let long = userLongitude;
    for (let i in data) {
      const dis = geolib.getDistance(
        { latitude: lat, longitude: long },
        {
          latitude: Number(data[i].users.providers.latitude),
          longitude: Number(data[i].users.providers.longitude),
        }
      );
    //   console.log(geolib.convertDistance(dis,'km'))
      newSearchData[i].kms = geolib.convertDistance(dis, "km");
    }
    // console.log(sortedData)
    // console.log(kmDistance.sort())
    // setconvertedDistance(kmDistance.sort());
    let sortedataTemp = [];
    sortedataTemp = newSearchData.sort(function (a, b) {
      return a.kms - b.kms;
    });
    // console.log(sortedataTemp)
    localStorage.setItem("searchedData", JSON.stringify(sortedataTemp));
    // navigate("/search");
    if (localStorage.getItem("searchedData")) {
        // navigate("/search");
    //   goto();
    }
  }


  const searchResult = async (mainId, subId) => {
    setnotFoundText(false);
    // console.log(id);
    await axios({
      url: API_URL + "search",
      method: "POST",
      headers: { "content-Type": "application/json/x-www-form-urlencoded" },
      data: { 
        main_category_id: mainId, 
        sub_category_id: subId
    },
    })
      .then((response) => {

            if(response.data.ProviderService.length !== 0)
            {
                convertLatLongToKM(response.data.ProviderService);
            } else {
                alert("No Data Found")
            }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const searchByCatName = async (event) => {
    event.preventDefault();
    // console.log(event.target.id);
    setCatSelected(event.target.id);
    // searchResult(event.target.id);
    let data = await Promise.resolve(characterSearch(event.target.id, '', userLongitude, userLatitude ))
    // console.log(data)
    // console.log("length", data.length)
    if(data.length >0){
        //alert("data found")
         navigate("/search")
    }  else {
        alert("no data fond")
    }
    // console.log("returning hooks button ")

  };
  const getMainId = async (e) => {
    console.log("main", e.target.id)
    // mainCatId(e.target.id)
    // searchResult(e.target.id, '');
    let ddd =  await Promise.resolve(characterSearch(e.target.id, '', userLongitude, userLatitude ))

    if(ddd){
        navigate("/search")
    } 
    // console.log("returning hooks main")
  };

  const getSubCategoryID = async (e) => {
    console.log("sub", e.target.id)
    // subCatId(e.target.id)
    // searchResult('', e.target.id);
 let ddd =  await Promise.resolve(characterSearch('',e.target.id, userLongitude, userLatitude ))
//  console.log(ddd)
if(ddd){
    navigate("/search")
} 
   
  };

  const searchFromAPI = async (data) => {
    // console.log("ss", data);
    await fetch(API_URL + "search/list-service/" + data, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setMainData(data.data[0].main);
        setSubData(data.data[0].sub);
        // navigate("/search")
      })
      .catch((err) => {
        console.log(err);
      });
  };


  let interval;
  const handleCharacterSearch = async (e) => {
    clearTimeout(interval);
    const allWithClass = Array.from(document.getElementsByClassName("autocomplete-dropdown-container"));
    // console.log(allWithClass[0].style.display);
    let displayStatus = allWithClass[0].style.display

    if(displayStatus === 'none')
  {
    // alert(" empty")
    document.getElementsByClassName("autocomplete-dropdown-container")[0].style.display = '';
    dispatch(searchBannerDropdownDisplayStatus(''))
    // console.log(displayStatus)

  } 

    if (e.target.value) {
        searchFromAPI(e.target.value);
      // interval = setTimeout(() => {
      //   searchFromAPI(e.target.value);
      // }, 200);
    }
  };

  const changeSearchBarDisplayStatus = () => {
    const allWithClass = Array.from(document.getElementsByClassName("autocomplete-dropdown-container"));
    // console.log(allWithClass[0].style);
    let displayStatus = allWithClass[0].style.display
    // console.log(displayStatus)
  
  
    if(displayStatus === '')
    {
      // alert(" empty")
      document.getElementsByClassName("autocomplete-dropdown-container")[0].style.display = 'none';
      // console.log(displayStatus)
      dispatch(searchBannerDropdownDisplayStatus('none'))
  
  
    } 
    // else { 
    //   console.log(displayStatus)
    //   // alert(" not empty")
    //   // document.getElementsByClassName("autocomplete-dropdown-container")[0].style.display = '';
    // }
  }
  


  return (
    <div className="banner-content"
    >
      <div className="container" onClick={changeSearchBarDisplayStatus}>
        <div className="row clearfix">
          <div className="col-12 text-center">
            <h1>
              Find Trusted Pet Hostels,<span>Day Care and more near you</span>
            </h1>
            <div className="search-box mt-4">
              <div className="search-area clearfix">
                <div className="searchBar">
                  {notFoundText && (
                    <>
                      <span style={{ color: "red" }}>Not Found</span>
                    </>
                  )}
                  <input
                    type="text"
                    onChange={handleCharacterSearch}
                    name="search"
                    id="search"
                    className="form-control"
                    placeholder="Search for Dog Trainer, Boarding etc..."
                  />


                  <div className="autocomplete-dropdown-container"
                 
                  >
                    <ul className="category">
                      {mainData.map((main) => {
                        return (
                            <li key={main.id}>
                              <a id={main.id} onClick={getMainId} key={main.id}>
                                {main.main_category_name}
                              </a>
                            </li>
                        );
                      })}
                      {subData.map((main) => {
                        return (
                            <li key={main.id}>
                              <a
                                id={main.id}
                                onClick={getSubCategoryID}
                                key={main.id}
                              >
                                {main.sub_category_name}
                              </a>
                            </li>
                        );
                      })}
                    </ul>
                   
                  </div>
                </div>
                <input
                  // onClick={searchResult}
                  type="submit"
                  name="submit"
                  id="search"
                  value="search"
                  className="btn"
                />
              </div>

              <ul className="services clearfix" >
                {searchingBoxCategories.map((categoryName, index) => {
                  return (
                    <li
                    className="listitem"
                      key={categoryName.id}
                      id={categoryName.id}
                      style={{ cursor: "pointer" }}
                      onClick={searchByCatName}
                    >
                      <a id={categoryName.id}>
                        <img
                          id={categoryName.id}
                          src={IMG_URL + categoryName.image}
                        />
                        <p id={categoryName.id} onClick={searchByCatName}>
                          {categoryName.main_category_name}
                        </p>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerSearchBox;
