import React from 'react'

const Inbox = () => {
  return (
    <div className="col-12 col-md-9 col-lg-8 col-xl-8">
                        <div className="dashboard-title">Inbox<span><a href="#">Delete all</a></span></div>
                        <div className="inbox box-shadow align-middle">
                            <img src="images/pawsitive-logo.jpg" alt="Image goes here" />
                            <div className="notification-text">
                                Pawsitive Service
                                <span>Lorem Ipsum is simply dummy text of the printing and......</span>
                            </div>
                            <div className="info">11:59 AM<br /><a href="#"><i className="bi bi-trash-fill"></i></a></div>
                        </div>
                        <div className="inbox box-shadow align-middle">
                            <img src="images/pawsitive-logo.jpg" alt="Image goes here" />
                            <div className="notification-text">
                                Pawsitive Service
                                <span>Lorem Ipsum is simply dummy text of the printing and......</span>
                            </div>
                            <div className="info">11:59 AM<br /><a href="#"><i className="bi bi-trash-fill"></i></a></div>
                        </div>
                        <div className="inbox box-shadow align-middle">
                            <img src="images/pawsitive-logo.jpg" alt="Image goes here" />
                            <div className="notification-text">
                                Pawsitive Service
                                <span>Lorem Ipsum is simply dummy text of the printing and......</span>
                            </div>
                            <div className="info">11:59 AM<br /><a href="#"><i className="bi bi-trash-fill"></i></a></div>
                        </div>
                        <div className="inbox box-shadow align-middle">
                            <img src="images/pawsitive-logo.jpg" alt="Image goes here" />
                            <div className="notification-text">
                                Pawsitive Service
                                <span>Lorem Ipsum is simply dummy text of the printing and......</span>
                            </div>
                            <div className="info">11:59 AM<br /><a href="#"><i className="bi bi-trash-fill"></i></a></div>
                        </div>
                    </div>
  )
}

export default Inbox