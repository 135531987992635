import React from 'react'

const Pets = () => {
  return (
    <div className="col-12 col-md-9 col-lg-8 col-xl-8">
                        <div className="dashboard-title mb-2">My Pets<span><a href="#" data-bs-toggle="modal" data-bs-target="#addpet"><i className="bi bi-plus-circle-fill"></i> Pets</a></span></div>
                        <div className="d-block box-shadow p-3">
                            <div className="table-responsive">
                                <table className="table my-pets align-middle text-center">
                                    <thead>
                                        <tr>
                                            <th>Pet Name</th>
                                            <th>Pet Weight</th>
                                            <th>Pet Height</th>
                                            <th>Pet Breed</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <img src="images/dog-pic.jpg" alt="Image goes here" /> Scooby
                                            </td>
                                            <td>27 Kg</td>
                                            <td>2.5 ft.</td>
                                            <td>German Shepherd</td>
                                            <td><a href="#"><i className="bi bi-pencil-fill"></i></a> | <a href="#"><i className="bi bi-trash-fill"></i></a></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src="images/dog-pic.jpg" alt="Image goes here" /> Scooby
                                            </td>
                                            <td>27 Kg</td>
                                            <td>2.5 ft.</td>
                                            <td>German Shepherd</td>
                                            <td><a href="#"><i className="bi bi-pencil-fill"></i></a> | <a href="#"><i className="bi bi-trash-fill"></i></a></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src="images/dog-pic.jpg" alt="Image goes here" /> Scooby
                                            </td>
                                            <td>27 Kg</td>
                                            <td>2.5 ft.</td>
                                            <td>German Shepherd</td>
                                            <td><a href="#"><i className="bi bi-pencil-fill"></i></a> | <a href="#"><i className="bi bi-trash-fill"></i></a></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src="images/dog-pic.jpg" alt="Image goes here" /> Scooby
                                            </td>
                                            <td>27 Kg</td>
                                            <td>2.5 ft.</td>
                                            <td>German Shepherd</td>
                                            <td><a href="#"><i className="bi bi-pencil-fill"></i></a> | <a href="#"><i className="bi bi-trash-fill"></i></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
  )
}

export default Pets