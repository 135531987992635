import React from 'react'

const NewsLetters = () => {
  return (
    <section>
    <div className="container">
        <div className="row clearfix">
            <div className="col-12">
                <h3>Newsletter to Get in Touch</h3>
            </div>
        </div>
        <div className="row clearfix">
            <div className="col-12 col-lg-6 col-xl-6 offset-md-3">
                <div className="input-group">
                    <input type="email" name="newsletterEmail" id="newsletterEmail" placeholder="Email Address" className="form-control" required />
                    <input type="submit" name="submit" id="submit" value="SUBSCRIBE" className="btn btn-theme" />

                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default NewsLetters