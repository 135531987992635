import React, { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login";
import { API_URL, IMG_URL } from "../constant";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { logout } from '../redux/actions/action';
import {loginStatusAction} from '../redux/actions/action';
import { useDispatch } from "react-redux";
// import {fullName} from '../redux/actions'
import { fullName } from "../redux/actions/action";
import { storingUserData } from "../redux/actions/action";
import { profileImage } from "../redux/actions/action";
import characterSearch from '../hooks/CharacterSearch';
import { searchNavBarDropdownDisplayStatus } from "../redux/actions/action";


const Navmenu = () => {
  const dispatch = useDispatch();
  const localStorageData = JSON.parse(window.localStorage.getItem("paw5-login-data"))
  const [userLongitude, setUserLongitude] = useState();
  const [userLatitude, setUserLatitude] = useState();
  const searchNavBarDisplayStatus = useSelector((state) => state.searchNavBarDropdownDisplayStatus)
const loginStatusRedux = useSelector((state) => state.storeLoginStatusReducer)
const userDataRedux = useSelector((state) => state.storeUserDataReducer)

  // let userData = JSON.parse(window.localStorage.getItem("paw5-login-data"));
  const [userData, setUserData] = useState();
  const [providerData, setProviderData] = useState();
  const completeName = useSelector((state) => state.fullName);
  // console.log("completeName", completeName)
  const profile_Image = useSelector((state) => state.profileImage);
  const [userGoogledata, setUserGoogledata] = useState()
  // console.log(1,profile_Image.image)
  const [mainData, setMainData] = useState([]);
  const [subData, setSubData] = useState([]);
  const [show, setShow] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [loginSuccessfully, setLoginSuccessfully] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [emailMessage, setEmailMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [profileLogo, setProfileLogo] = useState()
  const [userName, setUserName] = useState()
const [pathname, setPathName] = useState()
const [pathStatus, setPathStatus]= useState(false)
  useEffect(() => {
    const path = window.location.pathname //returns the current url minus the domain name
    // console.log(path)
    setPathName(path)
    if(path === '/')
    {
      setPathStatus(true)
    } else {
      setPathStatus(false)
    }
  },[window.location.pathname])
  const navigate = useNavigate();
  const msgStyle = {
    color: "red",
    backgroundColor: "Transparent",
    margin: "10px",
  };
  const session = window.sessionStorage;
  // console.log("isLoggedIn", isLoggedIn)

  // useEffect(() => {
  //   if(searchNavBarDisplayStatus === 'none')
  //   {
  //     document.getElementsByClassName("autocomplete-dropdown-container")[0].style.display = 'none';

  //   }
  // },[searchNavBarDisplayStatus])



  useEffect(() => {
let lat =    JSON.parse(window.localStorage.getItem("lat"));
setUserLongitude(lat)
let long = JSON.parse(window.localStorage.getItem("long"))
setUserLatitude(long)

  },[])

  useEffect(() => {
    setIsLoggedIn(loginStatusRedux)

  },[loginStatusRedux])
  useEffect(() => {

    setEmailMessage("")
    setLoginSuccessfully("")
      // console.log(profile_Image)
      // console.log(completeName)
      // console.log(session.email)

    // if(completeName.length >0 ){
    //   console.log("pro is und")
    // } else {
    //    console.log("image exist")
    // }
    // let userData = JSON.parse(window.localStorage.getItem("paw5-login-data"));
    let data = JSON.parse(window.localStorage.getItem("paw5-login-data"));
    if(data)
    {
        if(data.data.providers)
          {
      // console.log(data.data);
      setProviderData(data.data.providers);

            // setTimeout(handleClose, navigate("/provider-dashboard"), 30000);
        } else {
      
      // setUserData(JSON.parse(window.localStorage.getItem("paw5-login-data")));

      setTimeout(handleClose, navigate("/user-dashboard"), 30000);

      }
    }
    if (JSON.parse(window.localStorage.getItem("paw5-login-data"))) {
      // alert("found")
      setUserData(JSON.parse(window.localStorage.getItem("paw5-login-data")));
      setUserName(JSON.parse(window.localStorage.getItem("paw5-login-data")).data.first_name + 
      ' ' +
      JSON.parse(window.localStorage.getItem("paw5-login-data")).data.last_name)
      setProfileLogo(IMG_URL+
        JSON.parse(window.localStorage.getItem("paw5-login-data")).data.image)
    } else {
      setUserData();
    }
    if (session.getItem("email")) {
      setFormValues(session.getItem("email"));
      // setFormValues(session.getItem('password'))
      setIsLoggedIn(true);
      dispatch(loginStatusAction(true))
    }

    // if( JSON.parse(window.localStorage.getItem("paw5-login-data")))
    // {
    //   setIsLoggedIn(true)

    // } else {

    // }
  }, []);

  async function handleCredentialResponse(response) {
    // console.log(response.credential)
    const decoded_user = jwt_decode(response.credential);
    // let navigate = useNavigate();

    // setEmail(decoded_user.email);
    // setPicture(decoded_user.picture);
    // setgId(decoded_user.exp);
    // console.log(decoded_user);
    axios({
      method: "post",
      url: API_URL + "user/socialite",
      data: {
        firstName: decoded_user.name,
        lastName: decoded_user.name,
        email: decoded_user.email,
        socialite_id: decoded_user.sub,
      },
    }).then((response) => {
      if (response.data.success === true) {
        // console.log(response.data)
        setIsLoggedIn(true);
        dispatch(loginStatusAction(true))
        dispatch(storingUserData(response.data))
        // navigate("/dashboard");
        // console.log(response);
      } else if (response.data.success === false) alert(response.data.msg);
    });
  }

  const logoClick = () => {
    navigate("/");
  };

  const logoutUser = async (e) => {
    e.preventDefault();
    await axios({
      method: "post",
      url: API_URL + "user/sign_out",

      headers: {
        Accept: "application/json",
        Authorization:
          "Bearer " +
          JSON.parse(window.localStorage.getItem("paw5-login-data")).token,
      },
    })
      .then((response) => {
        if (response.data.success === true) {
          // console.log(response.data);
          // alert(response.data.message)
          localStorage.removeItem("paw5-login-data");
          localStorage.removeItem("searchedData");
          localStorage.removeItem("categories");
          localStorage.removeItem("profle-latlng");
          localStorage.removeItem("map-address-marker");

          session.clear();
          sessionStorage.clear();
          navigate("/");
          setIsLoggedIn(false)
          dispatch(loginStatusAction(false))
          dispatch(logout());
          dispatch(fullName(null));
          dispatch(profileImage(null));
          dispatch(storingUserData(null))
        } else if (response.data.success === false) {
          console.log(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleChange = (e) => {
    // console.log(e.target.id)
    // console.log(e.target.value)
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
  };
  const handleSubmit = (e) => {
    //console.log(formValues.email)
    e.preventDefault();
    axios({
      method: "post",
      url: API_URL + "user/sign_in",

      headers: {
        Accept: "application/json",
      },
      data: {
        email: formValues.email,
        password: formValues.password,
      },
    })
      .then((response) => {
        // console.log(response)
        if (response.data.success === true) {
          // console.log(response.data);
          // console.log(formValues.email)
          setIsLoggedIn(true);
          dispatch(loginStatusAction(true))
          setLoginSuccessfully(response.data.data.message);
          dispatch(profileImage( response.data.data.image));
          dispatch(fullName(response.data.data.first_name + response.data.data.last_name))
          dispatch(storingUserData(response.data))
          session.setItem("email", formValues.email);
          // session.setItem('password',formValues.password)
          // console.log(response.data.message);
          // alert(response.data.message)
          let fulldata = response.data;
          if (fulldata.data.providers) {
            localStorage.setItem(
              "paw5-login-data",
              JSON.stringify(response.data)
            );
            setTimeout(handleClose, navigate("/p-profile"), 30000);
          } else {
            localStorage.setItem(
              "paw5-login-data",
              JSON.stringify(response.data)
            );
            setTimeout(handleClose, navigate("/user-dashboard"), 30000);
          }

          // localStorage.setItem('paw5-login-data', JSON.stringify(response.data))
          // setTimeout( handleClose,navigate("/user-dashboard"), 30000);
          //console.log(response.data.token);
          // setTimeout(, 30000);
        } else if (response.data.success === false) {
          setEmailMessage(response.data.message);
          console.log(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    //console.log(formValues);
  };

  useEffect(() => {
   
    if(userGoogledata){
      // console.log("google image")
      // console.log(userGoogledata.image)
      setProfileLogo(userGoogledata.image)
    } else
    {
            setProfileLogo("images/user.jpg")
    }
  },[userGoogledata])
  // useEffect(() => {
  //   // console.log("nav saga");
  //   // console.log(profile_Image.image)
  //     setProfileLogo(profile_Image.image)
   
  // },[profile_Image])

  useEffect(() => {
    if(JSON.parse(window.localStorage.getItem("paw5-login-data")))
    {
      let dd = JSON.parse(window.localStorage.getItem("paw5-login-data"))
      // console.log(JSON.parse(window.localStorage.getItem("paw5-login-data")))
   
        //  console.log("userData image")
        //  console.log(dd.data.image)
         setProfileLogo(dd.data.image)
    }
   
   
  },[])


 
  // console.log(email)

  // console.log(gId)

  // let f=[];
  // let l=[];
  // for(let i=0; i<= nameSpliting.length; i++) {
  //     if(i==0) {
  //     f.push(nameSpliting[i])
  //     setfName(f.toString())
  //     console.log(fname)
  // } else if(i==1) {
  //         l.push(nameSpliting[i])
  //         setlName(l.toString())
  //         console.log(lname)
  //     }
  // }

const changeSearchBarDisplayStatus = () => {
  const allWithClass = Array.from(document.getElementsByClassName("autocomplete-dropdown-container"));
  // console.log(allWithClass[0].style);
  let displayStatus = allWithClass[0].style.display
  // console.log(displayStatus)


  if(displayStatus === '')
  {
    // alert(" empty")
    document.getElementsByClassName("autocomplete-dropdown-container")[0].style.display = 'none';
    // console.log(displayStatus)
    dispatch(searchNavBarDropdownDisplayStatus('none'))


  } 
  // else { 
  //   console.log(displayStatus)
  //   // alert(" not empty")
  //   // document.getElementsByClassName("autocomplete-dropdown-container")[0].style.display = '';
  // }
}

  const forgotPassword = () => {
    // console.log(formValues.email)
    axios({
      method: "post",
      url: API_URL + "user/password_reset",
      data: {
        email: formValues.email
      },
    }).then((response) => {
      console.log(response)
     if(response.data.success == true)
     {
      alert("Password reset email is sent to your email id. ")
     }
    });
  }
  const responseFacebook = (response) => {
    // console.log(response);
    axios({
      method: "post",
      url: API_URL + "user/socialite",
      data: {
        firstName: response.name,
        email: response.email,
        socialite_id: response.id,
      },
    }).then((response) => {
      response.data.success === false
        ? alert(response.data.msg)
        : console.log(response.data);
    });
  };


  let interval;
  const handleCharacterSearch = async (e) => {
    clearTimeout(interval);
    const allWithClass = Array.from(document.getElementsByClassName("autocomplete-dropdown-container"));
    // console.log(allWithClass[0].style.display);
    let displayStatus = allWithClass[0].style.display

    if(displayStatus === 'none')
  {
    // alert(" empty")
    document.getElementsByClassName("autocomplete-dropdown-container")[0].style.display = '';
    dispatch(searchNavBarDropdownDisplayStatus(''))
    // console.log(displayStatus)

  } 

    if (e.target.value) {
      interval = setTimeout(() => {
        searchFromAPI(e.target.value);
      }, 500);
    }
  };

  const searchFromAPI = async (data) => {
    // console.log(data);
    await fetch(API_URL + "search/list-service/" + data, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setMainData(data.data[0].main);
        setSubData(data.data[0].sub);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (respose) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${respose.access_token}`,
            },
          }
        );

        // console.log(res.data)
        const decoded_user = res.data;
        axios({
          method: "post",
          url: API_URL + "user/socialite",
          data: {
            first_name: decoded_user.given_name,
            last_name: decoded_user.family_name,
            email: decoded_user.email,
            socialite_id: decoded_user.sub,
            address: "ss",
          },
        }).then((response) => {
          if (response.data.success === true) {
            setUserGoogledata(response.data.data)
            session.setItem("email", decoded_user.email);
            setShow(false);
            localStorage.setItem(
              "paw5-login-data",
              JSON.stringify(response.data)
            );
            dispatch(profileImage( response.data.image));
            // alert("Sign in successfully");
            setIsLoggedIn(true);
            navigate("/user-dashboard");
            // console.log(response);
            // console.log(response.data.image);

          } else if (response.data.success === false) alert(response.data.msg);
        });
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
  });


  //getting id of main and subcat

  const getMainId = async (e) => {
    console.log("main", e.target.id)
    // mainCatId(e.target.id)
    // searchResult(e.target.id, '');
    let ddd =  await Promise.resolve(characterSearch(e.target.id, '', userLongitude, userLatitude ))

    if(ddd){
        navigate("/search")
    } 
    // console.log("returning hooks main")
  };

  const getSubCategoryID = async (e) => {
    // console.log("sub", e.target.id)
    // subCatId(e.target.id)
    // searchResult('', e.target.id);
 let ddd =  await Promise.resolve(characterSearch('',e.target.id, userLongitude, userLatitude ))
//  console.log(ddd)
if(ddd){
    navigate("/search")
} 
    // console.log("lenght", ddd.length)
    // console.log("returning hooks sub")
  };


  return (

      <nav className="navbar navbar-expand-lg sticky-top">
        <div className="container" onClick={changeSearchBarDisplayStatus}>
          <a className="navbar-brand">
            <img
              src="/images/logo.png"
              className="img-fluid"
              alt="Logo goes here"
              style={{ height: "36px", cursor: "pointer" }}
              onClick={logoClick}
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* search box */}
        
        { !pathStatus && (
          <div className="col-6">
              <div className="search-area clearfix">
                <div className="searchBar">
            <input
              className="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
              onChange={handleCharacterSearch}
            />
            <div className="autocomplete-dropdown-container">
                    <ul className="category">
                      {mainData.map((main) => {
                        return (
                            <li key={main.id}>
                              <a id={main.id} onClick={getMainId} key={main.id}>
                                {main.main_category_name}
                              </a>
                            </li>
                        );
                      })}
                      {subData.map((main) => {
                        return (
                            <li key={main.id}>
                              <a
                                id={main.id}
                                onClick={getSubCategoryID}
                                key={main.id}
                              >
                                {main.sub_category_name}
                              </a>
                            </li>
                        );
                      })}
                    </ul>
                    </div>
                    </div>
                    {/* <input
                  // onClick={searchResult}
                  type="submit"
                  name="submit"
                  id="search"
                  value="search"
                  className="btn"
                /> */}
              </div>
                    </div>
        )
              }
            {/* <button className="btn btn-theme" type="submit">
              <i className="fa fa-search"></i>
            </button> */}
           {/* search box finished*/}
          
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a className="nav-link">Help</a>
              </li>
              <li className="nav-item">
                <a className="nav-link">Pawfive Membership</a>
              </li>
              
              {
                  isLoggedIn && 
                  <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
{
  console.log("img",  userDataRedux.data.data.image)
}
                 <img
                    src={
                      userDataRedux.data.image !== null ? 
                      IMG_URL+userDataRedux.data.data.image :
                       profile_Image.image !== null ?
                        IMG_URL+profile_Image.image : "images/user.jpg"
                    }
                    alt="Image"
                    className="nav-user"
                  />
                </a>
                {/* dashboard menu */}
                
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" 
                    to={
                      providerData ?  
                      "/p-profile" :
                      "/user-dashboard"
                    }
                    
                    
                    >
                      <i className="bi bi-person-fill"></i> Profile
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                  {isLoggedIn && (
                    <a className="dropdown-item" style={{cursor: "pointer"}}
                    onClick={logoutUser}
                    >
                      <i className="bi bi-box-arrow-right"></i> Logout
                    </a>
                     )}
                  </li>
                </ul>


              </li>
              }

              <li className="nav-item">
                {!isLoggedIn && (
                    <Button
                      className="nav-link btn btn-theme"
                      variant="primary"
                      onClick={handleShow}
                    >
                      Login
                    </Button>
                )}
                {/* {isLoggedIn && (
                  <>
                    <Button
                      className="nav-link btn btn-theme"
                      variant="primary"
                      onClick={logoutUser}
                    >
                      Logout
                    </Button>
                  </>
                )} */}
              </li>
            </ul>
          </div>
 
        </div>
      
      <Modal show={show} onHide={handleClose}>
        <div className="modal-content">
          <Modal.Header>
            <div className="modal-body text-center">
              <img
                src="images/logo.png"
                className="modal-logo"
                alt="Image goes here"
              />
              <Modal.Title>Create Account or Login</Modal.Title>

              <div className="row gx-3 mt-4 mb-4 clearfix">
                <div className="col-12 col-lg-6 col-xl-6">
                  {/* <FacebookLogin
                    appId="675644994128392"
                    autoLoad={true}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    size="small"
                    textButton="Go with FaceBook"
                  /> */}
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <button
                    id="buttonDiv"
                    className="g-btn mb-3 w-100 border-0"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    onClick={googleLogin}
                  >
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-google"
                      viewBox="0 0 16 16"
                      // callback={responseGoogle}
                    > */}
                    {/* <path 
                      d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" /> */}
                    {/* </svg> */}
                    Continue with Google
                  </button>
                </div>
              </div>
            </div>
          </Modal.Header>
          <div className="divider"></div>
          <div className="modal-body">
            <form
              onSubmit={handleSubmit}
              method="post"
              action=""
              id="loginForm"
            >
              <div className="row mt-3 mb-3 clearfix">
                <div className="col-12">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email Address"
                      aria-label="username"
                      aria-describedby="emailField"
                      name="email"
                      id="email"
                      value={formValues.email}
                      onChange={handleChange}
                    />
                    <span className="input-group-text" id="emailField">
                      <i className="fa fa-user"></i>
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="input-group mb-3">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      aria-label="password"
                      aria-describedby="passwordField"
                      name="password"
                      id="password"
                      value={formValues.password}
                      onChange={handleChange}
                    />
                    <span className="input-group-text" id="passwordField">
                      <i className="fa fa-lock"></i>
                    </span>
                  </div>
                  <span style={msgStyle}>{emailMessage}</span>
                  <span style={msgStyle}>{loginSuccessfully}</span>
                </div>
                <div className="col-12">
                  <input
                    type="submit"
                    name="submit"
                    id="submit"
                    value="LOGIN"
                    className="btn btn-theme w-100"
                  />
                  <div className="row clearfix">
                    <div className="col-6 text-start">
                      <NavLink
                        onClick={handleClose}
                        to="signup"
                        data-dismiss="close"
                      >
                        New User? Sign up now
                      </NavLink>
                    </div>
                    <div className="col-6 text-end">
                      <a  style={{
                        color: '#0d6efd',
                        textDecoration: 'underline',
                        cursor: "pointer"
                      }}
                      onClick={forgotPassword}>Forgot Password?</a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      </nav>

  );
};

export default Navmenu;
