export const SET_NAME = "SET_NAME"
export const SET_PROFILE_IMAGE = "SET_PROFILE_IMAGE"
export const LOG_OUT = "LOG_OUT"
export const GET_BANNERS = "GET_BANNERS"
export const SET_NAVBAR_SEARCH_DROPDOWN_DISPLAY_STATUS = 'SET_NAVBAR_SEARCH_DROPDOWN_DISPLAY_STATUS'
export const SET_BANNER_SEARCH_DROPDOWN_DISPLAY_STATUS = 'SET_BANNER_SEARCH_DROPDOWN_DISPLAY_STATUS'
export const SET_PROVIDER_SERVICES = 'SET_PROVIDER_SERVICES'
export const SET_SUB_CATEGORIES = 'SET_SUB_CATEGORIES'
export const STORE_EDIT_DATA = 'STORE_EDIT_DATA'
export const STORE_MAIN_CATEGORIES_DATA = 'STORE_MAIN_CATEGORIES_DATA'
export const STORE_AMENITIES_FOR_SELECTED_SERVICE_ADD_MODULE = 'STORE_AMENITIES_FOR_SELECTED_SERVICE_ADD_MODULE'
export const STORE_AUTO_ADDRESS_LOCATION = 'STORE_AUTO_ADDRESS_LOCATION'

export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS'
export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_ACCORDION_AMENITIES_DATA = 'SET_ACCORDION_AMENITIES_DATA'
export const SET_ACCORDION_MAIN_CAT_IDS = 'SET_ACCORDION_MAIN_CAT_IDS'
export const SET_ACCORDION_CHECKED_STATUS = 'SET_ACCORDION_CHECKED_STATUS'





