import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {Provider} from 'react-redux'
import {  store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from 'react-router-dom'
import {persistStore} from "redux-persist";


const root = ReactDOM.createRoot(document.getElementById('root'));
let persistor = persistStore(store)

root.render(
  <Provider store={store}>
     {/* <React.StrictMode> */}
     <BrowserRouter >
     <PersistGate persistor={persistor}>
        <GoogleOAuthProvider clientId="829551531303-vdmpjll3foc1rancnbkue88rf318qqbf.apps.googleusercontent.com">

        <App />
        </GoogleOAuthProvider>
        </PersistGate>
        </BrowserRouter>

        {/* </React.StrictMode> */}
        </Provider>


);
