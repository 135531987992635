import React, {useEffect, useState} from "react";
import Profile from "../components/ProviderDashboard/Profile";
import Inbox from "../components/ProviderDashboard/Inbox";
import Help from "../components/ProviderDashboard/Help";
import ManagePassword from "../components/ProviderDashboard/ManagePassword";
import Services from "../components/ProviderDashboard/Services";
import Reviews from "../components/ProviderDashboard/Reviews";
import Orders from "../components/ProviderDashboard/Orders";
// import {categoriesList} from '../redux/ServicesData/servicesAction'
import { useSelector} from 'react-redux';
import { useDispatch} from 'react-redux'
import { API_URL,IMG_URL } from "../constant";
import { profileImage } from "../redux/actions/action";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";

const ProviderDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 

  const [ userData, setuserData] = useState(JSON.parse(window.localStorage.getItem("paw5-login-data")))
  const [ openServicesComponent, setOpenServicesComponent] = useState(false)
  const [ isProfileComponentOpen, setisProfileComponentOpen] = useState(true)
  const [profileLogo, setProfileLogo] = useState()
  const [ userLocalStorageData, setuserLocalStorageData] = useState()
  const profileImageRedux = useSelector((state) =>state.profileImage )
  const [userFullName, setUserFullName] = useState()
  const [profile64Image, setProfile64Image] = useState([])
  // const dispatch = useDispatch();
//   const data = useSelector((state) =>state )
// console.log(data)

const updateLocalStorage = (e) => {
e.preventDefault();
userData.data.image= 'upload/user/R3P1679638611.jpeg'
// console.log(userData)
  localStorage.setItem(
    "paw5-login-data",
    JSON.stringify(userData)
  );
}
const [open, setOpen] = React.useState(false);

  const openServices = () => {

    // setOpenServicesComponent(true)
    // setisProfileComponentOpen(false)
    navigate('/services')

  }

  const callSnackBar = () => {
    return (
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          This is a success message!
        </Alert>
      </Snackbar>
    )
  }
  // useEffect(() => {
  //   dispatch(categoriesList())

  // },[])

  useEffect(() => {
    // console.log(profileImage)
    if (profileImageRedux.image)
    { 
    setProfileLogo(profileImageRedux.image)
    } else if(userData.data.image){
      setProfileLogo(userData.data.image)
    }
    else {
    //   console.log("none")
      setProfileLogo("images/user.jpg")
    }
    // setuserLocalStorageData(userData)
},[profileImageRedux])


  const opeProfile = () => {
    // setisProfileComponentOpen(true)
    // setOpenServicesComponent(false)
    navigate('services')

  }
  const sendStatus = (data) => {
    console.log(data);
  };
 
  useEffect(() => {
    if(window.localStorage.getItem("paw5-login-data")){
        setuserData(JSON.parse(window.localStorage.getItem("paw5-login-data")));
        // setuserProfilePic(userData.data.image)
    } 
    localStorage.removeItem("amenitiesSelectedService");
    localStorage.removeItem("selectedServicesForAdd");

},[])

const handleImage = (e) => {
  // console.log(e.target.files)
  const selectedImage = e.target.files;
  let reader = new FileReader();
  reader.readAsDataURL(selectedImage[0]);
  reader.onload = (e) => {
    // console.log(e.target.result)
    handlerProfileImage(e.target.result)
    setProfile64Image(e.target.result);
  };
};

const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setOpen(false);
};

const handlerProfileImage =  (base64code) => {
  fetch(API_URL+'user/profile-image',{
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer '+ userData.token,
      }, 
      body: JSON.stringify({image: base64code}),
  }).then((res) => res.json())
  .then((data) =>
  {
    console.log(data)
    if(data.success == true) {
      setOpen(true)
      dispatch(profileImage(data.image_path))
      userData.data.image= data.image_path
  localStorage.setItem(
    "paw5-login-data",
    JSON.stringify(userData)
  );
      callSnackBar();
      //alert("Image is updated")

    } else { alert("Something is wrong")}

  }
  )
  .catch((err) => console.log(err))
}
// useEffect(() => {
//   if (JSON.parse(window.localStorage.getItem("paw5-login-data"))) {
//       setUserFullName(JSON.parse(window.localStorage.getItem("paw5-login-data")).data.first_name + 
//     ' ' +
//     JSON.parse(window.localStorage.getItem("paw5-login-data")).data.last_name)

//   //   console.log(IMG_URL+
//   //     JSON.parse(window.localStorage.getItem("paw5-login-data")).data.image)
//     // setProfileLogo(IMG_URL+
//     //   JSON.parse(window.localStorage.getItem("paw5-login-data")).data.image)

//   } 
// }, [userLocalStorageData]);
const handleImageButtonForImage = (e) => {
let element = document.getElementById("uploadFile");
element.click();
}

  return (
    <>
      <section>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Image is Updated
        </Alert>
      </Snackbar>
        <div className="container">
      
          <div className="row clearfix">
            <div className="col-12 col-md-3 col-lg-4 col-xl-4">
              <aside className="box-shadow">
                <div className="user-header">
               
                  <div className="user-pic">
                  
                    <img
                      src=
                      {profileLogo  ? IMG_URL+profileLogo : "images/user.jpg" } 
                      // {userData.data ? IMG_URL+userData.data.providers.logo  :"images/pawsitive-logo.jpg"}
                      alt="Image goes here"
                    />
                     
                    {/* <a className="edit" > */}
                    
                    <input  id = "uploadFile" 
                type="file" 
                onChange={handleImage}
                name="img"
                accept="image/*"
                style={{display: "none"}}
               />
               
               <button  
               className="edit"
               onClick={handleImageButtonForImage}>
               <i className="fa fa-pencil"></i>
               </button>
               
                   {/* </a> */}
                    
                  </div>
                  <div className="username mt-3">{userData.data.first_name + ' '+ userData.data.last_name}</div>
                </div>
                <div className="user-body">
                  <ul className="nav nav-pills flex-column sidenav">
                    <li className="nav-item">
                      <a
                        className={isProfileComponentOpen ? "nav-link active" : "nav-link"}
                        aria-current="page"
                        onClick={opeProfile}
                      >
                        <i className="bi bi-person-fill"></i> Profile
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className= {openServicesComponent ?
                         "nav-link active" : "nav-link"} onClick={ openServices}>
                        <i className="bi bi-clipboard2-check-fill"></i> My Services
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" >
                        <i className="bi bi-clipboard-fill"></i> Orders
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" >
                        <i className="bi bi-chat-right-text-fill"></i> Inbox
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" >
                        <i className="bi bi-stars"></i> Reviews
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" >
                        <i className="bi bi-key-fill"></i> Manage Password
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" >
                        <i className="bi bi-question-circle-fill" ></i> Help
                      </a>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
            {/* {
            isProfileComponentOpen && (

           <Profile key={userData} userData={userData}/>

            )
           } */}
           {/* {
            openServicesComponent && (
              <>
              <Services onClick={sendStatus}/>
              </>
            )
           } */}
            
          </div>
        </div>
      </section>
    </>
  );
};

export default ProviderDashboard;
