import React, {useEffect,useState,useMemo} from 'react';
import jwt_decode from "jwt-decode";
import axios from 'axios';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './styles/globals.css';
import NavMenu from './components/NavMenu';
import Home from './pages/Home';
// import Dashboard from './pages/Dashboard';
import SearchResult from './components/SearchPageDetails/SearchResult'
import Signup from './pages/Signup';
import UserDashboard from './pages/UserDashboard';
import ProviderDashboard from './pages/ProviderDashboard';
import StepOne from './components/ProviderDashboard/Step1';
import StepTwo from './components/ProviderDashboard/Step2';
import StepThree from './components/ProviderDashboard/Step3';
import UserProfile from './components/UserDashboard/Profile'
import Inbox from './components/UserDashboard/Inbox'
import Favourite from './components/UserDashboard/Favourite'
import Help from './components/UserDashboard/Help'
import ManagePassword from './components/UserDashboard/ManagePassword'
import Pets from './components/UserDashboard/Pets'
import Reviews from './components/UserDashboard/Reviews'
import MyOrders from './components/UserDashboard/MyOrders'
import 'bootstrap/dist/css/bootstrap.min.css';
import ProviderDetails from './pages/ProviderDetails';
import { API_URL, IMG_URL } from "./constant";
import { useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Services from './components/ProviderDashboard/Services'
import ProviderProfile from './components/ProviderDashboard/Profile'
import ServicesEdit from './components/ProviderDashboard/ServiceEdit'

import { useDispatch } from "react-redux";
import { banners } from "./redux/actions/action";


function App() {
  const [user, setUser] = useState(null)
  const images = useSelector((state) => state.banners)
  const dispatch = useDispatch();


  useEffect(() => {
if(JSON.parse(window.localStorage.getItem("banners"))) {

} else {
  callBanner()

}


    
  },[])
  function callBanner() {
       fetch(API_URL+'banner/view',{
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    }).then((response) => response.json())
    .then((data) => {
      // console.log(data)
      dispatch(banners(data.data))
      localStorage.setItem(
        "banners",
        JSON.stringify(data.data)
      );
      // setBannerImgs(data.data)
    });
     }
  return (
    <>
              <NavMenu />

    <Routes >
      <Route path="/" element={<Home />} />
      {/* <Route path="dashboard" element={<Dashboard></Dashboard>} /> */}
      <Route path="search" element={<SearchResult></SearchResult>} />
      <Route path="signup" element={<Signup></Signup>} />
      <Route path="providerDetails" element={<ProviderDetails></ProviderDetails>} />
      <Route path="user-dashboard" element={<UserDashboard></UserDashboard>} />
      <Route path="provider-dashboard" element={<ProviderDashboard></ProviderDashboard>} />
      <Route path="step1" element={<StepOne></StepOne>} />
      <Route path="step2" element={<StepTwo></StepTwo>} />
      <Route path="step3" element={<StepThree></StepThree>} />
      <Route path="u-profile" element={<UserProfile></UserProfile>} />
      <Route path="inbox" element={<Inbox></Inbox>} />
      <Route path="help" element={<Help></Help>} />
      <Route path="mgpass" element={<ManagePassword></ManagePassword>} />
      <Route path="pets" element={<Pets></Pets>} />
      <Route path="reviews" element={<Reviews></Reviews>} />
      <Route path="fav" element={<Favourite></Favourite>} />
      <Route path="myorders" element={<MyOrders></MyOrders>} />
      <Route path="services" element={<Services></Services>} />
      <Route path="p-profile" element={<ProviderProfile></ProviderProfile>} />
      <Route path="editService" element={<ServicesEdit></ServicesEdit>} />

    </Routes>
    
    </>
  );
}

export default App;
