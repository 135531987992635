import React from 'react'
import LeftSide from '../components/Signup/LeftSide'
import RightSide from '../components/Signup/RightSide'

const SignupPage = () => {
  return (
    <section>
      <div className="container">
      <div className="row clearfix">
        <LeftSide />

      <RightSide />
      </div>
      </div>
      </section>
  )
}

export default SignupPage