import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./rootReducer";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const sagaMiddleWare = createSagaMiddleware();
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const  store = configureStore({
  reducer: persistedReducer,
  middleware: () => [sagaMiddleWare],
});
// sagaMiddleWare.run(getProviderServices);


