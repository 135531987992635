export const INITIAL_STATE = {
  searchedDataSorted: {},
  allcategoriesSaved: [],
  homeSearchDetails: [],
};


export const searchedDataReducer = (state, action) => {
  // console.log(action.payload)
  switch (action.type){
    case  "SEARCHED_DATA_SORTED":
    return {
      ...state,
      searchedDataSorted: action.payload,
    };
    case "ALL_CATEGORIES":
      return {
        allcategoriesSaved: action.payload
      };

      case "HOME_SEARCHDATAFOUND":
        console.log(action.payload)
        return {
          homeSearchDetails: action.payload
        };
    default: 
    return state;
  }
}

