import React, { useState, useEffect } from "react";
import { API_URL, IMG_URL } from "../../constant";
import axios from "axios";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
// import {fullName} from '../../redux/actions'
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fullName } from "../../redux/actions/action";
import { profileImage } from "../../redux/actions/action";

const Profile = ({ sendImage }) => {
  const dispatch = useDispatch();
  // const fullName = useSelector((state) =>state )
  // console.log(fullName)

  const userData = JSON.parse(window.localStorage.getItem("paw5-login-data"));
  const [editStatus, setEditStatus] = useState(false);
  const [formValues, setFormValues] = useState([userData.data]);
  const [newformValues, setnewFormValues] = useState([]);
  const [emailExist, setEmailExist] = useState([]);
  const [base64Image, setbase64Image] = useState("");
  const [profilePic, setProfilePic] = useState();
  const [radioValue, setRadioValue] = useState(0);
  const [loggedInUserData, setloggedInUserData] = useState([]);
  const [imgSelected, setImageSelected] = useState(0);
  const radios = [
    { name: "Male", value: "1" },
    { name: "Female", value: "0" },
    { name: "Other", value: "2" },
  ];

  const [genderStatus, setgenderStatus] = useState(userData.data.gender);
  const [femaleActiveClass, setfemaleActiveClass] = useState();
  const session = window.sessionStorage;

  useEffect(() => {
    // console.log(session)
    let fullname = "";
    if (!userData.data.first_name || !userData.data.last_name) {
      fullname = userData.data.first_name + " ";
    } else {
      fullname = userData.data.first_name + ' ' + userData.data.last_name
    }

    // console.log(userData.data.first_name)
    dispatch(
      fullName(fullname)
    );
    dispatch(profileImage( userData.data.image));
    setloggedInUserData(
      JSON.parse(window.localStorage.getItem("paw5-login-data"))
    );
  }, []);

  const editProfile = () => {
    setEditStatus(true);
    //console.log(formValues[0].last_name)
  };

  // function sendImage() {
  //   // console.log(userData.data.image)
  //   return userData.data.image;
  // }

  const showActiveClass = (e) => {
    // console.log(e.target.value)
    if (e.target.value === "Male") {
      setgenderStatus("1");
    } else if (e.target.value === "Female") {
      setgenderStatus("0");
    } else if (e.target.value === "Other") {
      setgenderStatus("2");
    }
  };

  const handleImage = (e) => {
    //var input = e.target;
    // console.log(e.target.files)
    e.target.files ? setImageSelected(1) : setImageSelected(0);
    setProfilePic(URL.createObjectURL(e.target.files[0]));
    const selectedImage = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(selectedImage[0]);
    reader.onload = (e) => {
      // console.log(e.target.result)
      setbase64Image(e.target.result);
    };
  };

  const handleChange = (e) => {
    // console.log(e.target.id)
    // console.log(e.target.value)
    // console.log(1)
    // console.log(newformValues)
    setnewFormValues({ ...newformValues, [e.target.id]: e.target.value });
  };

  const uploadData = () => {
    let userData = JSON.parse(window.localStorage.getItem("paw5-login-data"));
    // console.log(formValues[0].last_name);
    // console.log(formValues[0].first_name);
    // console.log(newformValues.first_name);

    // console.log(newformValues.last_name);

    // setbase64Image(userData.image)
    // let fullname=''
    // if(!newformValues.first_name || !newformValues.last_name){
    //   fullname = formValues[0].first_name + ' ' +
    //   formValues[0].last_name !== null ? formValues[0].last_name : ''
    // } else {
    //   fullname = newformValues.first_name + ' ' + newformValues.last_name
    // }

    let fullname = "";
    if (!newformValues.first_name || !newformValues.last_name) {
      if (formValues[0].last_name) {
        // alert("not null")
        fullname = formValues[0].first_name + " " + formValues[0].last_name;
      } else {
        // alert("null")
      }
      fullname = formValues[0].first_name + " ";
    } else {
      fullname = newformValues.first_name + ' ' + newformValues.last_name
    }

    // let fullname=!newformValues.first_name ?  newformValues.first_name : formValues[0].first_name
    // + ' ' +
    // !newformValues.last_name ? newformValues.last_name : formValues[0].last_name
    // console.log(fullname)
    axios({
      method: "post",
      url: API_URL + "user/update",

      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userData.token,
      },
      data: {
        first_name: newformValues.first_name
          ? newformValues.first_name
          : formValues[0].first_name,
        last_name: newformValues.last_name
          ? newformValues.last_name
          : formValues[0].last_name,
        email: newformValues.email ? newformValues.email : formValues[0].email,
        phone: newformValues.phone ? newformValues.phone : formValues[0].phone,
        city: newformValues.city ? newformValues.city : formValues[0].city,
        pin_code: newformValues.pin_code
          ? newformValues.pin_code
          : formValues[0].pin_code,
        password: newformValues.password
          ? newformValues.password
          : formValues[0].password,
        image: imgSelected == 1 ? base64Image : null,
        gender: genderStatus,
      },
    })
      .then((response) => {
        // console.log(response)
        if (response.data.success === true) {
          console.log(response.data.data.image);
          alert("Update is successfully done");
          dispatch(fullName(fullname));
          dispatch(profileImage(response.data.data.image));
         
          localStorage.setItem(
            "paw5-login-data",
            JSON.stringify(response.data)
          );
          sendImage(userData.data.image);
          setEditStatus(false);
        } else if (response.data.success === false)
          console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <div className="col-12 col-md-9 col-lg-8 col-xl-8">
      <div className="dashboard-title">
        My Profile
        <span>
          <a style={{
            color: "#cc4678",
            cursor: "pointer"
          }}
          onClick={editProfile}>
            Edit
          </a>
        </span>
      </div>
      <div className="d-block box-shadow p-4 mt-3 mb-3">
        <table className="table profile-data">
          <tbody>
            <tr>
              {profilePic && (
                <img
                  src={profilePic}
                  alt={profilePic}
                  style={{ width: "100px", height: "100px" }}
                />
              )}
              <td colSpan="3">
                {editStatus && (
                  <>
                    <label htmlFor="img">Select Profile image:</label>
                    <input
                      onChange={handleImage}
                      type="file"
                      id="img"
                      name="img"
                      accept="image/*"
                    />
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="w-15">First Name</td>
              <td className="w-10">
                <i className="bi bi-dash"></i>
              </td>
              {!editStatus && (
                <>
                  <td className="w-75">{userData.data.first_name}</td>
                  {/* <td className="w-75">{formValues[0].first_name}</td> */}
                </>
              )}
              {editStatus && (
                <>
                  <td className="w-75">
                    <input
                      type="text"
                      id="first_name"
                      className="form-control mb-1"
                      placeholder="First Name*"
                      defaultValue={formValues[0].first_name}
                      onChange={handleChange}
                      maxLength="12"
                      required
                    />
                  </td>
                </>
              )}
            </tr>

            <tr>
              <td className="w-15">Last Name</td>
              <td className="w-10">
                <i className="bi bi-dash"></i>
              </td>
              {!editStatus && (
                <>
                  <td className="w-75">{userData.data.last_name}</td>
                </>
              )}
              {editStatus && (
                <>
                  <td className="w-75">
                    <input
                      type="text"
                      id="last_name"
                      className="form-control mb-3"
                      placeholder="Last Name*"
                      defaultValue={formValues[0].last_name}
                      onChange={handleChange}
                      maxLength="12"
                      required
                    />
                  </td>
                </>
              )}
            </tr>
            <tr>
              <td className="w-15">Email</td>
              <td className="w-10">
                <i className="bi bi-dash"></i>
              </td>
              {!editStatus && (
                <>
                  <td className="w-75">{userData.data.email}</td>
                </>
              )}
              {editStatus && (
                <>
                  <td className="w-75">
                    <input
                      type="email"
                      id="email"
                      className="form-control mb-3"
                      placeholder="Email Address*"
                      value={formValues[0].email}
                      onChange={handleChange}
                      maxLength="40"
                      required
                    />
                    <span>{emailExist}</span>
                  </td>
                </>
              )}
            </tr>
            <tr>
              <td className="w-15">Phone</td>
              <td className="w-10">
                <i className="bi bi-dash"></i>
              </td>
              {!editStatus && (
                <>
                  <td className="w-75">{userData.data.phone}</td>
                </>
              )}
              {editStatus && (
                <>
                  <td className="w-75">
                    <input
                      type="number"
                      id="phone"
                      className="form-control mb-3"
                      defaultValue={formValues[0].phone}
                      onChange={handleChange}
                      placeholder="phone"
                      maxLength="7"
                      required
                    />
                  </td>
                </>
              )}
            </tr>
            {/* phone */}
            <tr>
              <td className="w-15">City</td>
              <td className="w-10">
                <i className="bi bi-dash"></i>
              </td>
              {!editStatus && (
                <>
                  <td className="w-75">{userData.data.city}</td>
                </>
              )}
              {editStatus && (
                <>
                  <td className="w-75">
                    <input
                      type="text"
                      id="city"
                      className="form-control mb-3"
                      defaultValue={formValues[0].city}
                      onChange={handleChange}
                      placeholder="City"
                      maxLength="15"
                      required
                    />
                  </td>
                </>
              )}
            </tr>
            {/*pin */}
            <tr>
              <td className="w-15">Pin Code</td>
              <td className="w-10">
                <i className="bi bi-dash"></i>
              </td>
              {!editStatus && (
                <>
                  <td className="w-75">{userData.data.pin_code}</td>
                </>
              )}
              {editStatus && (
                <>
                  <td className="w-75">
                    <input
                      type="number"
                      id="pin_code"
                      className="form-control mb-3"
                      defaultValue={formValues[0].pin_code}
                      onChange={handleChange}
                      maxLength="6"
                      placeholder="Pin code"
                      required
                    />
                  </td>
                </>
              )}
            </tr>
            <tr>
              <td className="w-15">Gender</td>
              <td className="w-10">
                <i className="bi bi-dash"></i>
              </td>
              {editStatus && (
                <>
                  {/* <ButtonGroup>
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant={radioValue === radio.value ? 'btn btn-theme' : null}
            name="radio"
            value={radio.value}
            checked={radioValue === radio.value}
            onChange={(e) => setRadioValue(e.currentTarget.value)}
          >
            {radio.name }
          </ToggleButton>
        ))}
      </ButtonGroup> */}
                  <td>
                    <ButtonGroup aria-label="Basic example" className="mb-2 ">
                      <Button
                        onClick={showActiveClass}
                        value="Male"
                        variant="light"
                        className={genderStatus == "1" ? "maleActiveclass" : ""}
                        onChange={(e) => setRadioValue(e.currentTarget.value)}
                      >
                        Male
                      </Button>
                      <Button
                        onClick={showActiveClass}
                        value="Female"
                        variant="light"
                        className={
                          genderStatus == "0" ? "femaleActiveClass" : ""
                        }
                        onChange={(e) => setRadioValue(e.currentTarget.value)}
                      >
                        Female
                      </Button>
                      <Button
                        onClick={showActiveClass}
                        value="Other"
                        variant="light"
                        className={genderStatus == "2" ? "maleActiveclass" : ""}
                        onChange={(e) => setRadioValue(e.currentTarget.value)}
                      >
                        Other
                      </Button>
                    </ButtonGroup>
                  </td>
                </>
              )}
              {!editStatus && (
                <td className="w-75">
                  {userData.data.gender == "1"
                    ? "Male"
                    : userData.data.gender == "2"
                    ? "Other"
                    : userData.data.gender == "0"
                    ? "Female"
                    : ""}
                </td>
              )}
            </tr>
          </tbody>
        </table>
        {editStatus && (
          <div className="col-12 col-xl-12">
            <input
              type="submit"
              name="submit"
              id="submit"
              value="Update Profile"
              onClick={uploadData}
              className="btn btn-theme fw-semibold w-100 mb-3"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
