import React, { useEffect,useState} from 'react'
import { API_URL, IMG_URL } from "../../constant";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Step3 from './Step3'
import Categories from '../../hooks/CategoriesFetch'
import FetchExistingServices from '../../hooks/FetchExistingServices'
import SubCategory from '../../hooks/FetchAmenities'
import { getProviderServicesAction } from "../../redux/actions/action";
import { useSelector} from 'react-redux';
import _ from 'lodash';
import isEqual from 'lodash/isEqual';
// import Categories from '../../hooks/CategoriesFetch'
import { storeAmenitiesForSelectedServiceAction } from "../../redux/actions/action";
import { useDispatch} from 'react-redux'
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { storeAccordionAllAmenitiesAction } from "../../redux/actions/action";

const Step2 = () => {

    const [ allCat, setAllCat] = useState();
    const navigate = useNavigate(); 
    const dispatch = useDispatch();
    const selectedAmenitiesReducer = useSelector((state) => state.storeAccordionAllAmenitiesReducer);
    const [open, setOpen] = useState(false);
    const [allAmenitiesCollection, setallAmenitiesCollection] = useState([])
    const [ selectedData, setSelectedData] = useState([])
    const [ selectedID, setSelectedID] = useState([])
    const [ checkedStatus, setCheckedStatus] = useState([])

    const [openStep3, setopenStep3] = useState(false)
    const [hideStep2, sethideStep2] = useState(true)
    const url = API_URL+'search/main-category-name'
    const [targetId, setTargetId] = useState()
    const [data] = Categories(url);
    const [amenitiesData]= SubCategory(targetId)
    const providerServices = useSelector((state) => state.getProviderServicesAction);
    const mainCategories = useSelector((state) => state.storeMainCategoriesReducer);
    const [existingAPIServiceData] = FetchExistingServices();
const [remainingCategories, setremainingCategories] = useState([])
    useEffect(() => {
        // console.log("am step2", amenitiesData);
        // setAllCat(props.data)
        // console.log("existing", existingAPIServiceData)
        // console.log("main cat", mainCategories)
        // console.log("provider step2", providerServices);
        // console.log(mainCategories.length)
        // console.log(providerServices.length);
        if(mainCategories.length >0 )
        {
            checking();
        }
        // checking();
    },[mainCategories,providerServices])
    
    
useEffect(() => {
    // console.log(11,remainingCategories)
},[remainingCategories])
    function checking() {
        let providerdata = [...providerServices];
        let allmaincatData = mainCategories
        // console.log("propsallmain", allmaincatData)
        var diffItems = function (firstAr, secAr) {
            return firstAr.filter(function (fArItm) {
                return secAr.filter(function (sArItm) {
                    return fArItm.id === sArItm.main_category_id;
                }).length === 0;
            });
        };
            const remanining = diffItems(allmaincatData, providerdata)
            // console.log(remanining)
            setremainingCategories(remanining);
        
    }

  

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
    //   console.log(event, reason)
        setOpen(false);
      };
    const snackbarBoxStyling = {
        bottom: "250px",
        left: "800px",
        top: "-200px",
      };
    const nextStep = () => {

        if(allAmenitiesCollection.length > 0)
        {
            navigate("/step3");
        } else {
            setOpen(true)
            // alert("Select atleast one service")
        }
        // navigate("/step3");

        // setopenStep3(true)
        // sethideStep2(false)
        // console.log(selectedData)
    }

    // async function getSearchingCategories() {
    //     await axios({
    //          method: "POST",
    //          url: API_URL + 'search/main-category-name',
    //      }).then((response) => {
    //          if (response.data.success === true) {
    //             setAllCat(response.data.data)
    //             //console.log(response.data.data)
    //          }
    //      })
    //  }

     const handleMainCategories = (e) => {
        const amenitiesAPIURL = API_URL+'service/sub-categories/'
        // let fullData=[]
        // console.log("id", e.target.id)
        // console.log(2,e.target.value)

        let idData = []
        let ind = idData.findIndex((f) => f == e.target.id)
        if(ind >=0)
        {
            idData.splice(ind, 1)
        } else {
            idData.push(e.target.id)
        }



        console.log("idData", idData)
        // if(e.target.checked === true)
        // {
            // let ind = checkedStatus.findIndex((f) => f.mainId == e.target.id)
            // if(ind >=0)
            // {
            //     checkedStatus.splice(ind, 1)
            // } else {
            //     const len = checkedStatus.length
            //     setCheckedStatus(check => [...checkedStatus,
            //         {
            //             id: len >0 ? len+1 : 1,
            //             mainId: e.target.id,
            //             checked: e.target.checked
            //         }])
            // }
           
        // } 
        // else {
        //     for(let key in checkedStatus)
        //     {
        //         if(checkedStatus[key].mainId == e.target.id)
        //         {
        //             checkedStatus[key].checked = e.target.checked
        //         }
        //     }
            
        // }


         if(e.target.checked === true)
        {
            let remaingDumm = [...remainingCategories]
            remaingDumm.map((rem,index) => {
            if(rem.id == e.target.id)
            {
                remaingDumm[index].status = true;
                setremainingCategories(remaingDumm)
            } 
        })
    } else {
        
        let remaingDumm = [...remainingCategories]
        remaingDumm.map((rem,index) => {
        if(rem.id == e.target.id)
        {
            remaingDumm[index].status = false;
            setremainingCategories(remaingDumm)
        } 
    })
        
    }
        setSelectedData(e.target.id)
        //working props
        fetch(amenitiesAPIURL+e.target.id, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
            },
          })
            .then((res) => res.json())
            .then((data) => {
        // console.log("fetch am data", data.data))
         //d.push([id]:data.data),
        //   console.log("data",data.data)
          let apidata=data.data
                let count=0;
                let dummy=[...allAmenitiesCollection]
                dummy.map((d) => {
                    if(d.main_category_id == e.target.id)
                    count +=1;
                })
                let ind = null
            
                for(let i=1; i<=2 ; i++){
                   ind= dummy.findIndex((f) => f.main_category_id == e.target.id)
                //    console.log("ind", ind)

                    if(ind >=0)
                    {
                      dummy.splice(ind, 1)
                      setallAmenitiesCollection(dummy)
                    }
                }

          if(count ==0)
          {
                for(let d in apidata)
                {
                 if(apidata[d].status ==1)
                // console.log(apidata[d])
                setallAmenitiesCollection(all => [...all, 
              {
                  id:apidata[d].id,
                image: apidata[d].image,
                status: apidata[d].status,
                premium: apidata[d].premium,
                sub_category_name: apidata[d].sub_category_name,
                main_category_id: apidata[d].main_category_id,
                active: false
              }
              
              ])
      
                } 
          }

            }
          )
        //  localStorage.setItem('amenitiesSelectedService', JSON.stringify(data.data)))

        //  dispatch(storeAmenitiesForSelectedServiceAction(data.data)))

        // setSelectedData(oldSelectedData => [...oldSelectedData, e.target.value ]);
        // setSelectedID(oldSelectedID => [...oldSelectedID, e.target.id ]);
//         pp.push( {id:e.target.id, name: e.target.value})
// console.log(pp)
    const arrayvalue = [...selectedData];
    let index = arrayvalue.findIndex(i => i.id == e.target.id)
    // console.log(index)
    if(index >= 0) {
        arrayvalue.splice(index, 1)
        setSelectedData(arrayvalue)
        // console.log(223,arrayvalue)
    } else {
        setTargetId(e.target.id)
    arrayvalue.push({id:e.target.id, name: e.target.value})
    // console.log(333,arrayvalue)
          localStorage.setItem('selectedServicesForAdd', JSON.stringify(arrayvalue))

    setSelectedData(arrayvalue);
    }
//console.log(selectedData)
     }
 
     useEffect(() => {
        // console.log(223,allAmenitiesCollection)
dispatch(storeAccordionAllAmenitiesAction(allAmenitiesCollection))
// console.log("st", checkedStatus)
     },[allAmenitiesCollection])

  return (
    <>
    {/* { hideStep2 && ( */}
    <section className="p-0">
            <div className="container-fluid">
                <div className="row clearfix">
                    <div className="col-6 p-0">
                        <div className="sp-bg" style={{background: `url('/images/sp-bg-2.jpg')`, backgroundTop:  "top", backgroundCenter:  "center",
                        backgroundRepeat: 'no-repeat', backgroundSize: "cover" }}>
                            <div className="sp-bg-content-box">
                                <h1>What Kind of Services do you offer?</h1>
                            </div>
                        </div>
                    </div>
                  
                    <div className="col-6 p-0">
                        <div className="sp-content">
                            <div className="float-end">
                                <a  className="btn btn-transparent btn-back me-1">Help</a>
                                {/* <a  className="btn btn-transparent btn-back">Save & Continue</a> */}
                                
                            </div>
                            <div className="clearfix"></div>
                            <div className="sp-content-box">
                            <Snackbar
        style={snackbarBoxStyling}
        open={open}
        autoHideDuration={900}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Select atleast one service
        </Alert>
        </Snackbar>
  
  
                                {
                                    remainingCategories.length>0 ?
                                    remainingCategories.map((item,index) => {
                                        return(

                                             <div className="sp-checkbox petdaycare" 
                                              key={item.id}>
                                    <input type="checkbox" 
                                    id={item.id}
                                    defaultValue={item.main_category_name} 
                                    onClick={handleMainCategories}
                                    // defaultChecked={item.status}
                                 
                                    />
           
                                    <div >
                                        <img  src={IMG_URL+item.image} /> {item.main_category_name}
                                    </div>
                                </div>

                                        )
                                    }) : <div>All Categories are assigned</div>
                                }
                                <div className="align-items-center mt-3">
                                    <a onClick={ () => navigate(-1)} 
                                    className="btn btn-transparent btn-back btn-lg">
                                        <i className="bi bi-arrow-left-short"></i> Back</a>
                                  
                                    <a onClick={ nextStep} 
                                    className="btn btn-theme btn-lg">Next 
                                    <i className="bi bi-arrow-right-short"></i></a>
                                     
                                  
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </section>
    {/* )} */}
   
   {/* {
    openStep3 && (
        <Step3 selectedData = { selectedData}
        amenitiesData={amenitiesData}
        />
    )
   } */}

   
   </>
  )
}

export default Step2