import React, { useEffect, useState } from "react";
import ProviderImages from "../components/ProvidersDetails/ProviderImages";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { API_URL, IMG_URL } from "../constant";
import * as geolib from "geolib";
import WorkingHoursModal from "../components/UI Components/WorkingHoursModal";
import { DynamicStar } from "react-dynamic-star";
import { LightBox } from "react-lightbox-pack"; // <--- Importing LightBox Pack
import "react-lightbox-pack/dist/index.css";
import imagesData from "../data/images.json";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";
import GoogleMap from "../components/ProvidersDetails/GoogleMap";
import images from "../data/images.json";

const ProviderDetails = () => {
  const location = useLocation();
  const [providerData, setProviderData] = useState([]);
  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  const [chromedata, setChromeData] = useState([]);
  const [kms, setKms] = useState();
  const [meters, setMeters] = useState();
  const [workingHours, setWorkingHours] = useState([]);
  const [providerReviews, setProviderReviews] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [sIndex, setSIndex] = useState(0);
  const [firstImage, setFirstImage] = useState([{}]);
  const [amenities, setAmenities] = useState();
  const [providerGallery, setProviderGallery] = useState([{}]);
  const [providerGalleryExcept1, setProviderGalleryExcept1] = useState([{}]);
  const [updatedLocationURL, setUpdatedURL] = useState(
    "https://maps.google.com/maps?q=28.631081,77.218307&z=15&output=embed"
  );
  const imgs = [
    {
      id: 1,
      image: "upload/user/R3P16772288871421025056.jpg",
    },
    {
      id: 2,
      image: "upload/user/R3P1678791145167019943.png",
    },
    {
      id: 3,
      image: "upload/user/R3P16787911461799055051.png",
    },
    {
      id: 4,
      image: "upload/user/R3P1678791146385658736.png",
    },
  ];

  const sendData = () => {
    // console.log(providerData[0].latitude)
    setUpdatedURL(
      `https://maps.google.com/maps?q=${providerData.latitude},${providerData.longitude}&z=15&output=embed`
    );
  };

  // Handler
  const lightBoxHandler = (state, sIndex) => {
    // console.log(state)
    // console.log(sIndex)
    setToggle(state);
    setSIndex(sIndex);
  };
  const lightBoxHandlerone = (state, sIndex) => {
    // console.log(state)
    // console.log(sIndex)
    setToggle(state);
    setSIndex(sIndex);
  };

  //   useEffect(() => {
  // // console.log(providerGallery)
  //   },[providerGallery])

  const getProviderDetails = () => {
    axios({
      method: "post",
      url: API_URL + "search/show/",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      if (response.data.success === true) {
        // console.log(response);
      } else if (response.data.success === false)
        console.log(response.data.msg);
    });
  };

  useEffect(() => {
    sendData();

  console.log(amenities)
  },[providerData])

  function handleImages(images) {
    let temp = [];
    images.map((item, index) => {
      temp = [...temp, { id: index, image: IMG_URL + item }];
    });
    setProviderGallery(temp);
    // console.log(temp)
  }
  useEffect(() => {
    let lat = localStorage.getItem("lat");
    // console.log(location.state.id)
    let long = localStorage.getItem("long");
    axios({
      method: "GET",
      url: API_URL + "search/show/" + location.state.id,
    }).then((response) => {
      if (response.data.success === true) {
        // console.log(response.data.provider[0]);
        // console.log(response.data.provider);

        handleImages(response.data.provider[0].gallery_images);
        // setProviderGallery(response.data.provider[0].gallery_images)
        providerGallery.map((item, index) => {
          index === 0 ? setFirstImage(item) : setProviderGalleryExcept1(item);
        });

        // setProviderDataPP(response.data.provider)

        //  console.log(response.data.provider[1].amenities[0].sub_category_id);
        setProviderReviews(response.data.provider[0].provider_rating);
        setWorkingHours(response.data.provider[0].provider_working_hours);
        setProviderData(response.data.provider[0]);
        const dis = geolib.getDistance(
          { latitude: lat, longitude: long },
          {
            latitude: Number(response.data.provider[0].latitude),
            longitude: Number(response.data.provider[0].longitude),
          }
        );
        // console.log(response.data.provider[1].amenities)
        // console.log(response.data.provider[1].amenities[1])

        setAmenities(response.data.provider[1].amenities);
        setMeters(geolib.convertDistance(dis, "m"));
        setKms(geolib.convertDistance(dis, "km"));
        // console.log(meters)
        // console.log(providerGalleryExcept1)
        // console.log(providerGallery);
      }
    });
  }, []);
  return (

          <>
            <section className="bg-fff8f6 py-3">
              <div className="container">
                <div className="row clearfix">
                  <div className="col-12">
                    <div className="head">
                      <img src={providerData.logo ? 
                    providerData.logo : '/images/pawsite-log.jpg'  
                    } alt="Image goes here" />
                      <div className="details">
                        <h5>
                          {providerData.store_name}
                          <span>
                            <i className="fa fa-star"></i>{" "}
                            {providerData.provider_rating_avg_rating} ·{" "}
                            {providerData.provider_rating_count} reviews
                          </span>
                        </h5>
                        <ul>
                          <li>
                            <i className="fa fa-map-marker"></i> {providerData.address}
                          </li>
                          <li>
                            <i className="fa fa-map-pin"></i>{" "}
                            {meters < 999
                              ? meters + " meters away"
                              : kms + " kms away"}
                          </li>
                          <li>
                            <i className="fa fa-clock">
                              8:00am to 9:00pm{" "}
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#viewWorkingHoursModals"
                              >
                                View Hours
                              </a>
                              <WorkingHoursModal
                                key={workingHours.id}
                                hours={workingHours}
                              />
                            </i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <ul
                      className="grid justified-gallery clearfix"
                      id="animated-thumbnails-gallery"
                    >
                      <li
                        style={{
                          maxHeight: "80vh",
                          width: "100%",
                          maxWidth: "50vw",
                          objectFit: "cover",
                          height: "345px",
                        }}
                      >
                        {providerGallery.map((mainImg, index) => {
                          if (mainImg.id == 0) {
                            return (
                                <img
                                  key={mainImg.id}
                                  src={mainImg.image}
                                  alt={mainImg}
                                  style={{
                                    maxHeight: "80vh",
                                    maxWidth: "50vw",
                                    height: "99%",
                                    width: "100%",
                                  }}
                                  onClick={() => {
                                    lightBoxHandler(true, index);
                                  }}
                                />
                            );
                          }
                        })}
                        <LightBox
                          style={{ height: "20px" }}
                          state={toggle}
                          event={lightBoxHandler}
                          data={providerGallery}
                          imageWidth="80vw"
                          imageHeight="80vh"
                          thumbnailHeight={50}
                          thumbnailWidth={50}
                          setImageIndex={setSIndex}
                          imageIndex={sIndex}
                        />
                      </li>
                      <li>
                        <div>
                          {providerGallery.map((mainImg, index) => {
                            if (mainImg.id > 0 && mainImg.id < 3) {
                              return (
                                <a 
                                key={mainImg.id}
                                className="gallery-item jg-entry jg-entry-visible">
                                  <img
                                    src={mainImg.image}
                                    key={mainImg.id}
                                    style={{
                                      maxHeight: "80vh",
                                      maxWidth: "50vw",
                                    }}
                                    onClick={() => {
                                      lightBoxHandlerone(true, index);
                                    }}
                                  />
                                </a>
                              );
                            }

                            <LightBox
                              style={{ height: "20px" }}
                              state={toggle}
                              event={lightBoxHandlerone}
                              data={providerGallery}
                              imageWidth="60vw"
                              imageHeight="70vh"
                              thumbnailHeight={50}
                              thumbnailWidth={50}
                              setImageIndex={setSIndex}
                              imageIndex={sIndex}
                            />;
                          })}
                        </div>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="col-12">
                <a className="float-end"
                style={{cursor: "pointer"}}
                onClick={lightBoxHandler}>
                  View all Images <i className="fa fa-long-arrow-right"></i>
                </a>
              </div> */}
                </div>
              </div>
            </section>

            <section>
              <div className="container-fluid gx-0">
                <ul
                  className="nav details-tab nav-pills nav-fill"
                  id="scrollSection"
                >
                  <li className="nav-item">
                    <a className="nav-link active" href="#scrollspyAbout">
                      About Service
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#scrollspyLocation">
                      Location
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#scrollspyReviews">
                      Reviews & Ratings
                    </a>
                  </li>
                </ul>
              </div>
              <div className="container mt-5">
                <div className="row clearfix">
                  <div className="col-12 col-xl-8">
                    <div
                      data-bs-spy="scroll"
                      data-bs-target="#scrollSection"
                      data-bs-offset="0"
                      className="scrollspy-example"
                      tabIndex="0"
                    >
                      <div name="section-title" id="scrollspyAbout">
                        About the Pawsitive Service
                      </div>
                      <p className="section-text">{providerData.about_us}</p>
                      <div className="heading">Essential Amenities</div>
                      <ul className="ea clearfix">
                        { amenities && amenities.map((am) => {
                          if (am.sub_categories.premium != 1)
                            return (
                              <li id={am.sub_categories.id}>
                                <img
                                  src={IMG_URL + am.sub_categories.image}
                                  alt={am.sub_categories.sub_category_name}
                                />
                                <p>{am.sub_categories.sub_category_name}</p>
                              </li>
                            );
                        })}
                      </ul>
                      <div className="text-center">
                        <a href="#" className="text-center">
                          View More Services{" "}
                          <i className="fa fa-long-arrow-right"></i>
                        </a>
                      </div>
                      <div className="heading">Premium Services</div>
                      <ul className="ea clearfix">
                        {amenities && amenities.map((am) => {
                          if (am.sub_categories.premium == 1)
                            return (
                              <li key={am.id}>
                                <img
                                  src={IMG_URL + am.sub_categories.image}
                                  alt={am.sub_categories.sub_category_name}
                                />
                                <p>{am.sub_categories.sub_category_name}</p>
                              </li>
                            );
                        })}
                      </ul>
                      <div className="section-title" id="scrollspyLocation">
                        Location
                      </div>

                      <iframe
                        src={updatedLocationURL}
                        width="100%"
                        height="320"
                        style={{ border: "0" }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>

                      <div className="section-title mt-3" id="scrollspyReviews">
                        Reviews & Ratings
                        <span>
                          <i className="fa fa-star"></i>{" "}
                          {providerData.provider_rating_avg_rating} ·{" "}
                          {providerData.provider_rating_count}
                        </span>
                      </div>
                      <ul className="reviews-grid">
                        {providerReviews.map((item, index) => {
                          return (
                            <li key={item.id}>
                              <img
                                src={item.users.image}
                                alt="Image goes here"
                              />
                              <div className="desc">
                                <h6>
                                  {item.users.first_name} {item.users.last_name}
                                  <DynamicStar
                                    width={15}
                                    height={15}
                                    rating={item.rating}
                                    emptyStarColor={5 - item.rating}
                                  />
                                </h6>
                                <p>{item.text}</p>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                      <div className="text-center">
                        <a href="#">View all</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-4 border-start">
                    <div className="booking-form card">
                      <div className="card-header">
                        Pet Hosting
                        <span className="float-end">
                          <i className="fa fa-inr"></i> 699
                        </span>
                      </div>
                      <div className="card-body">
                        <form method="post" action="" role="form">
                          <div className="mb-3">
                            <label>I'm Looking For</label>
                            <select
                              className="form-select mt-1"
                              name="boarding"
                              id="boarding"
                            >
                              <option value="Boarding">Boarding</option>
                            </select>
                          </div>
                          <div className="mb-3">
                            <label>Pet Size</label>
                            <select
                              className="form-select mt-1"
                              name="petSize"
                              id="petSize"
                            >
                              <option value="Pet Size">Pet Size</option>
                            </select>
                          </div>
                          <div className="mb-3">
                            <label>For These Days</label>
                            <div className="row clearfix">
                              <div className="col-6">
                                <input
                                  type="date"
                                  name="pickUp"
                                  id="pickUp"
                                  className="form-control"
                                  placeholder="Pick Up"
                                />
                              </div>
                              <div className="col-6">
                                <input
                                  type="date"
                                  name="dropOff"
                                  id="dropOff"
                                  className="form-control"
                                  placeholder="Drop Off"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label>Book Premium Services</label>
                            <select
                              className="form-select mt-1"
                              name="premiumServices"
                              id="premiumServices"
                            >
                              <option value="Premium Services">
                                Premium Services
                              </option>
                            </select>
                          </div>
                          <input
                            type="submit"
                            id="submit"
                            name="submit"
                            value="Book Now"
                            className="btn btn-theme w-100"
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
  )   
};

export default ProviderDetails;
