import React, { useState, useEffect } from "react";
import GoogleMap from "../ProvidersDetails/GoogleMap";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import GooglePlacesAutoComplete from "../SearchPageDetails/GooglePlacesAutoComplete";
import { API_URL, IMG_URL } from "../../constant";
import axios from "axios";
import GoogleMapReact from "google-map-react";
import { getProviderServicesAction } from "../../redux/actions/action";
import { useDispatch } from "react-redux";
import ProviderSideMenu from "./ProviderSideMenu";
// import Profile from "../components/ProviderDashboard/Profile";
import Map from "../GoogleMap/Map";
import { storeAutoAddressLatLongAction } from "../../redux/actions/action";
import { useSelector } from "react-redux";
import LoadingSpinner from "./LoadingSpinner";
import Modal from "react-bootstrap/Modal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { storeMainCategoriesAction } from "../../redux/actions/action";
import { storingUserData } from "../../redux/actions/action";

const Profile = (props) => {
  const dispatch = useDispatch();
  const providerServices = useSelector((state) => state.getProviderServicesAction);
  const mainCategories = useSelector((state) => state.storeMainCategoriesReducer);
  const url = API_URL+'search/main-category-name'
  const userDataRedux = useSelector((state) => state.storeUserDataReducer.data)
console.log("userDataRedux", userDataRedux)
  const userdata = JSON.parse(window.localStorage.getItem("paw5-login-data"));
  const [location, setLocation] = useState([]);
  const [editTimeStatus, seteditTimeStatus] = useState(false);
  // console.log(props.userData.data.providers.store_name)
  const [cordinates, setCordinates] = useState();
  const [updatedLocationURL, setUpdatedURL] = useState(
    `https://maps.google.com/maps?q=${userdata.data.providers.latitude},${userdata.data.providers.longitude}&z=15&output=embed`
  );
  //weekdays open close timing variables
  const [sundayOpenTime, setsundayOpenTime] = useState("");
  const [sundayCloseTime, setsundayCloseTime] = useState("");
  const [mondayOpenTime, setmondayOpenTime] = useState("");
  const [mondayCloseTime, setmondayCloseTime] = useState("");
  const [tuesdayOpenTime, settuesdayOpenTime] = useState("");
  const [tuesdayCloseTime, settuesdayCloseTime] = useState("");
  const [wednesdayOpenTime, setwednesdayOpenTime] = useState("");
  const [wednesdayCloseTime, setwednesdayCloseTime] = useState("");
  const [thursdayOpenTime, setthursdayOpenTime] = useState("");
  const [thursdayCloseTime, setthursdayCloseTime] = useState("");
  const [fridayOpenTime, setfridayOpenTime] = useState("");
  const [fridayCloseTime, setfridayCloseTime] = useState("");
  const [saturdayOpenTime, setsaturdayOpenTime] = useState("");
  const [saturdayCloseTime, setsaturdayCloseTime] = useState("");
  //checkbox variables
  const [sundayOpenClose, setSundayOpenClose] = useState("0");
  const [mondayOpenClose, setMondayOpenClose] = useState("0");
  const [tuesdayOpenClose, setTuesdayOpenClose] = useState("0");
  const [wednesdayOpenClose, setWednesdayOpenClose] = useState("0");
  const [thursdayOpenClose, setThursdayOpenClose] = useState("0");
  const [fridayOpenClose, setFridayOpenClose] = useState("0");
  const [saturdayOpenClose, setSaturdayOpenClose] = useState("0");
  const [timings, setTimings] = useState(0);
  const [editStatus, setEditStatus] = useState(false);
  const [formValues, setFormValues] = useState([{}]);
  const [existingServices, setExistingServies] = useState([]);

  const [format, setFormat] = "h:mm a";
  const now = moment().hour(0).minute(0);

  const [showErrorMessage, setShowErrorMessage] = useState(false)
  //loading spinners
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const [address, setAddress] = useState("");

  const autoAddressLatLong = useSelector(
    (state) => state.storeAutoAddressLatLongReducer
  );


  const fetchCategories = (url) => {
      
    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => 
// console.log("hooks data", data)
dispatch(storeMainCategoriesAction(data.data))
      );

  // return [data];
};
useEffect(() => {
  fetchCategories(url)
  // dispatch(storeMainCategoriesAction(data))
},[])

  const snackbarBoxStyling = {
    bottom: "250px",
    left: "800px",
    top: "-200px",
  };

  //   useEffect(()=> {
  // console.log("autoAddressLatLong", autoAddressLatLong)
  //   },[])

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const uploadData = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setShow(true);

    let sunday = [];
    let monday = [];
    let tuesday = [];
    let wednesday = [];
    let thursday = [];
    let friday = [];
    let saturday = [];
    if (sundayOpenTime) {
      // setTimings( {...timings, ["open_time"] : sundayOpenTime,
      // ["close_time"] : sundayCloseTime,
      // ["time_diss"]: sundayOpenClose})
      sunday = { ...sunday, ["open_time"]: sundayOpenTime };
    } else {
      sunday = {
        ...sunday,
        ["open_time"]:
          userdata.data.providers.provider_working_hours[0].open_time,
      };
    }
    if (sundayCloseTime) {
      // setTimings( {...timings, ["open_time"] : sundayOpenTime,
      // ["close_time"] : sundayCloseTime,
      // ["time_diss"]: sundayOpenClose})
      sunday = { ...sunday, ["close_time"]: sundayCloseTime };
    } else {
      sunday = {
        ...sunday,
        ["close_time"]:
          userdata.data.providers.provider_working_hours[0].close_time,
      };
    }

    if (isSundayOpenClose) {
      // setTimings( {...timings, ["open_time"] : sundayOpenTime,
      // ["close_time"] : sundayCloseTime,
      // ["time_diss"]: sundayOpenClose})
      sunday = { ...sunday, ["time_diss"]: sundayOpenClose };
    } else {
      sunday = {
        ...sunday,
        ["time_diss"]:
          userdata.data.providers.provider_working_hours[0].time_diss,
      };
    }

    if (sundayOpenClose) {
      // setTimings( {...timings, ["open_time"] : sundayOpenTime,
      // ["close_time"] : sundayCloseTime,
      // ["time_diss"]: sundayOpenClose})
      sunday = { ...sunday, ["time_diss"]: sundayOpenClose };
    }

    if (mondayOpenTime) {
      monday = { ...monday, ["open_time"]: mondayOpenTime };
    } else {
      monday = {
        ...monday,
        ["open_time"]:
          userdata.data.providers.provider_working_hours[1].open_time,
      };
    }
    if (mondayCloseTime) {
      // setTimings( {...timings, ["open_time"] : mondayOpenTime,
      // ["close_time"] : mondayCloseTime,
      // ["time_diss"]: mondayOpenClose})
      monday = { ...monday, ["close_time"]: mondayCloseTime };
    } else {
      monday = {
        ...monday,
        ["close_time"]:
          userdata.data.providers.provider_working_hours[1].close_time,
      };
    }

    if (isMondayOpenClose) {
      // setTimings( {...timings, ["open_time"] : mondayOpenTime,
      // ["close_time"] : mondayCloseTime,
      // ["time_diss"]: mondayOpenClose})
      monday = { ...monday, ["time_diss"]: mondayOpenClose };
    } else {
      monday = {
        ...monday,
        ["time_diss"]:
          userdata.data.providers.provider_working_hours[1].time_diss,
      };
    }

    if (mondayOpenClose) {
      monday = { ...monday, ["time_diss"]: mondayOpenClose };
    }

    if (tuesdayOpenTime) {
      tuesday = { ...tuesday, ["open_time"]: tuesdayOpenTime };
    } else {
      tuesday = {
        ...tuesday,
        ["open_time"]:
          userdata.data.providers.provider_working_hours[2].open_time,
      };
    }
    if (tuesdayCloseTime) {
      // setTimings( {...timings, ["open_time"] : tuesdayOpenTime,
      // ["close_time"] : tuesdayCloseTime,
      // ["time_diss"]: tuesdayOpenClose})
      tuesday = { ...tuesday, ["close_time"]: tuesdayCloseTime };
    } else {
      tuesday = {
        ...tuesday,
        ["close_time"]:
          userdata.data.providers.provider_working_hours[2].close_time,
      };
    }

    if (isTuesdayOpenClose) {
      // setTimings( {...timings, ["open_time"] : tuesdayOpenTime,
      // ["close_time"] : tuesdayCloseTime,
      // ["time_diss"]: tuesdayOpenClose})
      tuesday = { ...tuesday, ["time_diss"]: tuesdayOpenClose };
    } else {
      tuesday = {
        ...tuesday,
        ["time_diss"]:
          userdata.data.providers.provider_working_hours[2].time_diss,
      };
    }

    if (wednesdayOpenTime) {
      wednesday = { ...wednesday, ["open_time"]: wednesdayOpenTime };
    } else {
      wednesday = {
        ...wednesday,
        ["open_time"]:
          userdata.data.providers.provider_working_hours[2].open_time,
      };
    }
    if (wednesdayCloseTime) {
      // setTimings( {...timings, ["open_time"] : wednesdayOpenTime,
      // ["close_time"] : wednesdayCloseTime,
      // ["time_diss"]: wednesdayOpenClose})
      wednesday = { ...wednesday, ["close_time"]: wednesdayCloseTime };
    } else {
      wednesday = {
        ...wednesday,
        ["close_time"]:
          userdata.data.providers.provider_working_hours[2].close_time,
      };
    }

    if (isWednesdayOpenClose) {
      // setTimings( {...timings, ["open_time"] : wednesdayOpenTime,
      // ["close_time"] : wednesdayCloseTime,
      // ["time_diss"]: wednesdayOpenClose})
      wednesday = { ...wednesday, ["time_diss"]: wednesdayOpenClose };
    } else {
      wednesday = {
        ...wednesday,
        ["time_diss"]:
          userdata.data.providers.provider_working_hours[2].time_diss,
      };
    }

    if (thursdayOpenTime) {
      thursday = { ...thursday, ["open_time"]: thursdayOpenTime };
    } else {
      thursday = {
        ...thursday,
        ["open_time"]:
          userdata.data.providers.provider_working_hours[2].open_time,
      };
    }
    if (thursdayCloseTime) {
      // setTimings( {...timings, ["open_time"] : thursdayOpenTime,
      // ["close_time"] : thursdayCloseTime,
      // ["time_diss"]: thursdayOpenClose})
      thursday = { ...thursday, ["close_time"]: thursdayCloseTime };
    } else {
      thursday = {
        ...thursday,
        ["close_time"]:
          userdata.data.providers.provider_working_hours[2].close_time,
      };
    }

    if (isThursdayOpenClose) {
      // setTimings( {...timings, ["open_time"] : thursdayOpenTime,
      // ["close_time"] : thursdayCloseTime,
      // ["time_diss"]: thursdayOpenClose})
      thursday = { ...thursday, ["time_diss"]: wednesdayOpenClose };
    } else {
      thursday = {
        ...thursday,
        ["time_diss"]:
          userdata.data.providers.provider_working_hours[2].time_diss,
      };
    }

    if (fridayOpenTime) {
      friday = { ...friday, ["open_time"]: fridayOpenTime };
    } else {
      friday = {
        ...friday,
        ["open_time"]:
          userdata.data.providers.provider_working_hours[2].open_time,
      };
    }
    if (fridayCloseTime) {
      // setTimings( {...timings, ["open_time"] : fridayOpenTime,
      // ["close_time"] : fridayCloseTime,
      // ["time_diss"]: fridayOpenClose})
      friday = { ...friday, ["close_time"]: fridayCloseTime };
    } else {
      friday = {
        ...friday,
        ["close_time"]:
          userdata.data.providers.provider_working_hours[2].close_time,
      };
    }

    if (isFridayOpenClose) {
      // setTimings( {...timings, ["open_time"] : fridayOpenTime,
      // ["close_time"] : fridayCloseTime,
      // ["time_diss"]: fridayOpenClose})
      friday = { ...friday, ["time_diss"]: wednesdayOpenClose };
    } else {
      friday = {
        ...friday,
        ["time_diss"]:
          userdata.data.providers.provider_working_hours[2].time_diss,
      };
    }

    if (saturdayOpenTime) {
      saturday = { ...saturday, ["open_time"]: saturdayOpenTime };
    } else {
      saturday = {
        ...saturday,
        ["open_time"]:
          userdata.data.providers.provider_working_hours[2].open_time,
      };
    }
    if (saturdayCloseTime) {
      // setTimings( {...timings, ["open_time"] : saturdayOpenTime,
      // ["close_time"] : saturdayCloseTime,
      // ["time_diss"]: saturdayOpenClose})
      saturday = { ...saturday, ["close_time"]: saturdayCloseTime };
    } else {
      saturday = {
        ...saturday,
        ["close_time"]:
          userdata.data.providers.provider_working_hours[2].close_time,
      };
    }

    if (isSaturdayOpenClose) {
      // setTimings( {...timings, ["open_time"] : saturdayOpenTime,
      // ["close_time"] : saturdayCloseTime,
      // ["time_diss"]: saturdayOpenClose})
      saturday = { ...saturday, ["time_diss"]: wednesdayOpenClose };
    } else {
      saturday = {
        ...saturday,
        ["time_diss"]:
          userdata.data.providers.provider_working_hours[2].time_diss,
      };
    }

    // console.log({ "sunday": sunday})
    // console.log({ "monday": monday})
    // console.log({ "tuesday": tuesday})
    // console.log({ "wednesday": wednesday})
    // console.log({ "friday": thursday})
    // console.log({ "friday": friday})
    // console.log({ "saturday": saturday})

    //console.log({"sunday": timings})
    // console.log("autoAddressLatLong", autoAddressLatLong);
    // console.log("cordinates", cordinates);

    // console.log(userdata.data.providers.provider_working_hours[0].open_time);
    // console.log(
    //   formValues.storename
    //     ? formValues.storename
    //     : userdata.data.providers.store_name,
    //   formValues.aboutus
    //     ? formValues.aboutus
    //     : userdata.data.providers.about_us,
    //   formValues.email ? formValues.email : userdata.data.email,
    //   formValues.phone ? formValues.phone : userdata.data.phone,
    //   address ? address : userdata.data.providers.address,
    //   cordinates ? cordinates.lat : userdata.data.providers.latitude,
    //   cordinates ? cordinates.lng : userdata.data.providers.longitude,
    //   {
    //     sunday: sunday,

    //     monday: monday,
    //     tuesday: tuesday,
    //     wednesday: wednesday,
    //     thursday: thursday,
    //     friday: friday,
    //     saturday: saturday,
    //   }
    // );

    axios({
      method: "post",
      url: API_URL + "user/provider-update",

      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userdata.token,
      },
      data: {
        store_name: formValues.storename
          ? formValues.storename
          : userdata.data.providers.store_name,
        about_us: formValues.aboutus
          ? formValues.aboutus
          : userdata.data.providers.about_us,
        phone: formValues.phone ? formValues.phone : userdata.data.phone,
        address: address ? address : userdata.data.providers.address,
        // latitude: cordinates
        //   ? cordinates.lat
        //   : userdata.data.providers.latitude,
        // longitude: cordinates
        //   ? cordinates.lng
        //   : userdata.data.providers.longitude,
        latitude: cordinates
          ? cordinates.lat.toString()
          : userdata.data.providers.latitude,
        longitude: cordinates
          ? cordinates.lng
          : userdata.data.providers.longitude,
        sunday: sunday
          ? sunday
          : userdata.data.providers.provider_working_hours[0],
        monday: monday
          ? monday
          : userdata.data.providers.provider_working_hours[1],
        tuesday: tuesday
          ? tuesday
          : userdata.data.providers.provider_working_hours[2],
        wednesday: wednesday
          ? wednesday
          : userdata.data.providers.provider_working_hours[3],
        thursday: thursday
          ? thursday
          : userdata.data.providers.provider_working_hours[4],
        friday: friday
          ? friday
          : userdata.data.providers.provider_working_hours[5],
        saturday: saturday
          ? saturday
          : userdata.data.providers.provider_working_hours[6],
      },
    })
      .then((response) => {
        console.log(response);

        if (response.data.success === true) {
          setIsLoading(false);
          setShow(false);
          setOpen(true);

          console.log(1,response);
          // localStorage.setItem('paw5-login-data', JSON.stringify(response.data))
          // alert("Profile is successfully updated");
          localStorage.setItem(
            "paw5-login-data",
            JSON.stringify(response.data)
          );
          dispatch(storingUserData(response.data))
          setEditStatus(!editStatus);
        } else if (response.data.success === false)
        if (response.status >= 400) {
          setShowErrorMessage(true)
          throw new Error("Server responds with error!")
      }
      })
      .catch((error) => {
        console.log(error.response.data.errors);
      });
  };

  useEffect(() => {
    // console.log("aa", JSON.parse(localStorage.getItem("profle-latlng")))
    setCordinates( JSON.parse(localStorage.getItem("profle-latlng")));
  },[])

//   useEffect(() => {
// console.log("cordinates1", cordinates)
//   },[cordinates])

  //google auto places functions starts here
  const sendData = (cord) => {
    setCordinates(cord);
    // console.log("cord profile", cord);
    localStorage.setItem("profle-latlng", JSON.stringify(cord));

    // dispatch(storeAutoAddressLatLongAction(cord))
    setUpdatedURL(
      `https://maps.google.com/maps?q=${cord.lat},${cord.lng}&z=15&output=embed`
    );
  };

  const sendData1 = (add) => {
    setAddress(add);
    // console.log("add", add);
  };
  //google auto places functions ends here

  const editProfile = () => {
    // console.log(!editStatus);
    setEditStatus(!editStatus);
  };
  const handleChange = (e) => {
    e.preventDefault();
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
  };

  const editTime = () => {
    seteditTimeStatus(!editTimeStatus);
  };

  const isSundayOpenClose = (e) => {
    // console.log(e.target.checked);
    setSundayOpenClose(e.target.checked === true ? "1" : "0");
  };

  const isMondayOpenClose = (e) => {
    // console.log(e.target.checked);
    setMondayOpenClose(e.target.checked === true ? "1" : "0");
  };
  const isTuesdayOpenClose = (e) => {
    // console.log(e.target.checked);
    setTuesdayOpenClose(e.target.checked === true ? "1" : "0");
  };

  const isWednesdayOpenClose = (e) => {
    // console.log(e.target.checked)
    setWednesdayOpenClose(e.target.checked === true ? "1" : "0");
  };
  const isThursdayOpenClose = (e) => {
    // console.log(e.target.checked)
    setThursdayOpenClose(e.target.checked === true ? "1" : "0");
  };
  const isFridayOpenClose = (e) => {
    // console.log(e.target.checked)
    setFridayOpenClose(e.target.checked === true ? "1" : "0");
  };
  const isSaturdayOpenClose = (e) => {
    // console.log(e.target.checked)
    setSaturdayOpenClose(e.target.checked === true ? "1" : "0");
  };

  function getSundayOpenTime(value) {
    // console.log(value.format('hh:mm') );
    console.log(value.format("hh:mm"));
    setsundayOpenTime(value.format("hh:mm"));
  }
  function getSundayCloseTime(value) {
    // console.log(value && value.format(format));
    setsundayCloseTime(value.format("hh:mm"));
  }
  function getMondayOpenTime(value) {
    // console.log(value && value.format(format));
    setmondayOpenTime(value.format("hh:mm"));
  }
  function getMondayCloseTime(value) {
    // console.log(value && value.format(format));
    setmondayCloseTime(value.format("hh:mm"));
  }
  function getTuesdayOpenTime(value) {
    // console.log(value && value.format(format));
    settuesdayOpenTime(value.format("hh:mm"));
  }
  function getTuesdayCloseTime(value) {
    // console.log(value && value.format(format));
    settuesdayCloseTime(value.format("hh:mm"));
  }
  function getWednesdayOpenTime(value) {
    // console.log(value && value.format(format));
    setwednesdayOpenTime(value.format("hh:mm"));
  }
  function getWednesdayCloseTime(value) {
    // console.log(value && value.format('hh:mm'));
    setwednesdayCloseTime(value.format("hh:mm"));
  }
  function getThursdayOpenTime(value) {
    // console.log(value && value.format('hh:mm'));
    setthursdayOpenTime(value.format("hh:mm"));
  }
  function getThursdayCloseTime(value) {
    // console.log(value && value.format('hh:mm'));
    setthursdayCloseTime(value.format("hh:mm"));
  }
  function getFridayOpenTime(value) {
    // console.log(value && value.format('hh:mm'));
    setfridayOpenTime(value.format("hh:mm"));
  }
  function getFridayCloseTime(value) {
    // console.log(value && value.format('hh:mm'));
    setfridayCloseTime(value.format("hh:mm"));
  }
  function getSaturdayOpenTime(value) {
    // console.log(value && value.format('hh:mm'));
    setsaturdayOpenTime(value.format("hh:mm"));
  }
  function getSaturdayCloseTime(value) {
    // console.log(value && value.format('hh:mm'));
    setsaturdayCloseTime(value.format("hh:mm"));
  }
  function tConvert(time) {
    // Check correct time format and split into components
    if (time) {
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join("");
    }

    // return adjusted time or original string
  }



  // const sendData = (cord) => {
  //   setCordinates(cord)
  //   // console.log(cord)
  // }

  // const sendData1 = (add) => {
  //   setAddress(add)
  //   // console.log(add)
  // }

  useEffect(() => {
    fetch(API_URL + "user/provider/service/index", {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        "Authorization": "Bearer " + userdata.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
         dispatch(getProviderServicesAction(data.data))
      })
      .catch((error) => {

        console.log(error)
      });
    // console.log(data.data))
    // console.log(data.data[0].main_categories.main_category_name))
    // setExistingServies(data.data))
  }, []);

  return (
    <>
      <section>
        <div className="container">
          <div className="row clearfix">
            <ProviderSideMenu />
            <div className="col-12 col-md-9 col-lg-8 col-xl-8">
              <div className="dashboard-title">
                My Profile
                <span>
                  <a onClick={editProfile} style={{ color: "#cc4678" }}>
                    Edit{" "}
                  </a>
                </span>
              </div>
              <div className="d-block box-shadow p-4 mt-3 mb-3">
                <table className="table profile-data">
                  <tbody>
                    <tr>
                      <td className="w-25">Store Name</td>
                      <td className="w-10">
                        <i className="bi bi-dash"></i>
                      </td>
                      {!editStatus && (
                        <td>{userDataRedux.data.providers?.store_name}</td>
                      )}

                      {editStatus && (
                        <td className="w-75">
                          <input
                            type="text"
                            id="storename"
                            className="form-control mb-1"
                            placeholder="Store Name*"
                            value={formValues[0].store_name}
                            defaultValue={userDataRedux.data.providers.store_name}
                            onChange={handleChange}
                            maxLength="20"
                            required
                          />
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td className="w-25">Store Email</td>
                      <td className="w-10">
                        <i className="bi bi-dash"></i>
                      </td>
                      {!editStatus && (
                        <td>
                          {formValues[0].email
                            ? formValues[0].email
                            : userDataRedux.data.email}
                        </td>
                      )}
                      {editStatus && (
                        <td className="w-75">
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control mb-3"
                            placeholder="Email*"
                            value={formValues[0].email}
                            defaultValue={userDataRedux.data.email}
                            disabled
                            onChange={handleChange}
                          />
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td className="w-25">Store Phone</td>
                      <td className="w-10">
                        <i className="bi bi-dash"></i>
                      </td>
                      {!editStatus && (
                        <td>
                          {formValues[0].phone
                            ? formValues[0].phone
                            : userDataRedux.data.phone}
                        </td>
                      )}
                      {editStatus && (
                        <td className="w-75">
                          <input
                            type="tel"
                            id="phone"
                            className="form-control mb-3"
                            value={formValues[0].phone}
                            defaultValue={userDataRedux.data.phone}
                            onChange={handleChange}
                            placeholder="Phone"
                            maxLength="10"
                            required
                          />
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td className="w-25">Address</td>
                      <td className="w-10">
                        <i className="bi bi-dash"></i>
                      </td>
                      {/* {
                        console.log(userDataRedux.data.providers.address)
                      } */}
                      {!editStatus && (
                        <td>
                          {formValues[0].address
                            ? formValues[0].address
                            : userDataRedux.data.providers.address}
                        </td>
                      )}
                      {editStatus && (
                        <td className="w-75">
                          <input
                            type="tel"
                            id="address"
                            className="form-control mb-3"
                            value={formValues[0].address}
                            defaultValue={userDataRedux.data.providers.address}
                            onChange={handleChange}
                            placeholder="address"
                            maxLength="10"
                            required
                          />
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td className="w-25">About Us</td>
                      <td className="w-10">
                        <i className="bi bi-dash"></i>
                      </td>
                      {!editStatus && (
                        <td>{userdata.data.providers.about_us}</td>
                      )}
                      {editStatus && (
                        <td className="w-75">
                          <textarea
                            type="text"
                            id="aboutus"
                            className="form-control mb-1"
                            placeholder="About Us*"
                            value={formValues[0].aboutus}
                            onChange={handleChange}
                            defaultValue={userDataRedux.data.providers.about_us}
                            maxLength="50"
                            required
                            rows="4"
                            cols="50"
                          />
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td className="w-25">Operation Timings</td>
                      <td className="w-10">
                        <i className="bi bi-dash"></i>
                      </td>
                      <td>
                        {/* {
                  userdata.data.providers.provider_working_hours[1].open_time !==  ''?
                 'sds' : '00: 00'
                  } */}
                        {userDataRedux.data.providers.provider_working_hours[0]
                          .open_time
                          ? tConvert(
                            userDataRedux.data.providers.provider_working_hours[1]
                                .open_time
                            ) +
                            " to " +
                            tConvert(
                              userDataRedux.data.providers.provider_working_hours[1]
                                .close_time
                            )
                          : "00: 00"}
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#operationtimings"
                          className="btn btn-transparent btn-sm float-end"
                        >
                          {editStatus && (
                            <i className="bi bi-pencil-fill">Edit</i>
                          )}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="form-title mb-2">Your Address</div>
                <div>
                  {editStatus && (
                    <GooglePlacesAutoComplete
                      sendData={sendData}
                      sendData1={sendData1}
                    />
                  )}
                </div>
                <div className="custom-icon" style={{ top: "4px" }}>
                  <i className="bi bi-geo-alt-fill"></i>
                </div>
                <div className="row clearfix">
                  <div className="col-12">
                    <div className="row">
                      <div
                        className="col-lg-12 pl-n2"
                          id="map"
                      >
                        {/* <div id="map-canvas"></div> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <iframe
            src={updatedLocationURL}
            width="100%"
            height="240"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe> */}

                {/* <Map
                  google={cordinates}
                  center={{
                    lat: autoAddressLatLong
                      ? autoAddressLatLong.lat
                      : userdata.data.providers.latitude,
                    lng: autoAddressLatLong
                      ? autoAddressLatLong.lng
                      : userdata.data.providers.latitude,
                  }}
                  height="200px"
                  zoom={15}
                /> */}
                {/* <p>{console.log("inlin", cordinates)}</p>
                <p>{console.log("inlin", typeof(userdata.data.providers.longitude))}</p> */}

                  <Map
                  google={cordinates}
                  center={{
                    lat: autoAddressLatLong
                    ? autoAddressLatLong.lat
                    : userDataRedux.data.providers.latitude,
                    lng:autoAddressLatLong
                    ? autoAddressLatLong.lng
                    : userDataRedux.data.providers.latitude,
                  }}
                  height="200px"
                  zoom={15}
                />
              </div>

              {isLoading && (
                <Modal show={show} onHide={handleClose}>
                  <Modal.Body>
                    {" "}
                    <LoadingSpinner />{" "}
                  </Modal.Body>
                </Modal>
              )}

              {editStatus && (
                <div className="col-lg-12 update-profile">
                  <input
                    style={{ margin: "0 auto" }}
                    type="submit"
                    name="submit"
                    id="submit"
                    value="Update Profile"
                    onClick={uploadData}
                    className="btn btn-theme fw-semibold w-50 mb-3"
                  />
                </div>
              )}

              <Snackbar
                style={snackbarBoxStyling}
                open={open}
                autoHideDuration={6000}
                onClose={handleCloseSnackBar}
              >
                <Alert
                  onClose={handleCloseSnackBar}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Profile is Updated
                </Alert>
              </Snackbar>
            </div>
          </div>
        </div>
      </section>

      {/* Modal */}

      <div
        className="modal fade"
        id="operationtimings"
        tabIndex="-1"
        aria-labelledby="operationtimings"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content " style={{ width: "120%" }}>
            <div className="modal-body">
              <form method="post" action="" id="loginForm">
                <table className="table table-borderless align-middle">
                  <thead>
                    <tr>
                      <th>Open</th>
                      <th>Close</th>
                      <th>Closed</th>
                    </tr>
                    <tr>
                      <th style={{color: "rgba(204,70,120,1)"}}onClick={editTime}>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!editTimeStatus &&
                      userDataRedux.data.providers.provider_working_hours.map(
                        (hours, index) => {
                          return (
                            <tr key={hours.id}>
                              <td>{hours.week}</td>
                              <td>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    name="sundayopentime"
                                    id="sundayopentime"
                                    className="form-control"
                                    style={{ width: "50px" }}
                                    placeholder="--:--"
                                    value={
                                      hours.open_time
                                        ? tConvert(hours.open_time).slice(
                                            0,
                                            tConvert(hours.open_time).length - 2
                                          )
                                        : "00:00"
                                    }
                                    disabled
                                  />
                                  <select className="form-select">
                                    <option defaultValue="00:00" value="am">
                                      {hours.open_time
                                        ? tConvert(hours.open_time).slice(
                                            tConvert(hours.open_time).length -
                                              2,
                                            tConvert(hours.open_time).length
                                          )
                                        : "00:00"}
                                    </option>
                                    {/* <option value="am">AM</option>
                                  <option value="pm">PM</option> */}
                                  </select>
                                </div>
                              </td>
                              <td>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    name="sundayclosetime"
                                    id="sundayclosetime"
                                    className="form-control"
                                    style={{ width: "50px" }}
                                    placeholder="--:--"
                                    value={
                                      hours.open_time
                                        ? tConvert(hours.close_time).slice(
                                            0,
                                            tConvert(hours.close_time).length -
                                              2
                                          )
                                        : "00:00"
                                    }
                                    disabled
                                  />
                                  <select className="form-select">
                                    <option defaultValue="00:00" value="">
                                      {hours.open_time
                                        ? tConvert(hours.close_time).slice(
                                            tConvert(hours.close_time).length -
                                              2,
                                            tConvert(hours.close_time).length
                                          )
                                        : "00:00"}
                                    </option>
                                    {/* <option value="am">AM</option>
                                  <option value="pm">PM</option> */}
                                  </select>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}

                    {editTimeStatus && (
                      <>
                        <tr>
                          <td>Sunday</td>
                          <td>
                            <div className="input-group">
                              <TimePicker
                                id="sundayopentime"
                                class="form-control"
                                placeholder={
                                  userdata
                                    ? tConvert(
                                      userDataRedux.data.providers
                                          .provider_working_hours[0].open_time
                                      )
                                    : "--:--"
                                }
                                showSecond={false}
                                onChange={getSundayOpenTime}
                                use12Hours
                              />
                            </div>
                          </td>
                          <td>
                            <div className="input-group">
                              <TimePicker
                                id="sundayclosetime"
                                class="form-control"
                                placeholder={
                                  userDataRedux
                                    ? tConvert(
                                      userDataRedux.data.providers
                                          .provider_working_hours[0].close_time
                                      )
                                    : "--:--"
                                }
                                showSecond={false}
                                onChange={getSundayCloseTime}
                                use12Hours
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <label class="customcheck">
                              <input
                                type="checkbox"
                                name="sunday"
                                id="sunday"
                                onClick={isSundayOpenClose}
                                defaultChecked={
                                  userDataRedux.data.providers
                                          .provider_working_hours[0]
                                          .time_diss == 1
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>Monday</td>
                          <td>
                            <div className="input-group">
                              <TimePicker
                                id="mondayclosetime"
                                class="form-control"
                                placeholder={
                                  userDataRedux
                                    ? tConvert(
                                      userDataRedux.data.providers
                                          .provider_working_hours[1].open_time
                                      )
                                    : "--:--"
                                }
                                showSecond={false}
                                onChange={getMondayOpenTime}
                                use12Hours
                              />
                            </div>
                          </td>
                          <td>
                            <div className="input-group">
                              <TimePicker
                                id="mondayclosetime"
                                class="form-control"
                                placeholder={
                                  userDataRedux
                                    ? tConvert(
                                      userDataRedux.data.providers
                                          .provider_working_hours[1].close_time
                                      )
                                    : "--:--"
                                }
                                showSecond={false}
                                onChange={getMondayCloseTime}
                                use12Hours
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <label className="customcheck">
                              <input
                                type="checkbox"
                                name="monday"
                                id="monday"
                                onClick={isMondayOpenClose}
                                defaultChecked={
                                  userDataRedux.data.providers
                                          .provider_working_hours[1]
                                          .time_diss == 1
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>Tuesday</td>
                          <td>
                            <div className="input-group">
                              <TimePicker
                                id="tuesdayclosetime"
                                class="form-control"
                                placeholder={
                                  userDataRedux
                                    ? tConvert(
                                      userDataRedux.data.providers
                                          .provider_working_hours[2].open_time
                                      )
                                    : "--:--"
                                }
                                showSecond={false}
                                onChange={getTuesdayOpenTime}
                                use12Hours
                              />
                            </div>
                          </td>
                          <td>
                            <div className="input-group">
                              <TimePicker
                                id="tuesdayclosetime"
                                class="form-control"
                                placeholder={
                                  userDataRedux
                                    ? tConvert(
                                      userDataRedux.data.providers
                                          .provider_working_hours[2].close_time
                                      )
                                    : "--:--"
                                }
                                showSecond={false}
                                onChange={getTuesdayCloseTime}
                                use12Hours
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <label className="customcheck">
                              <input
                                type="checkbox"
                                name="tuesday"
                                id="tuesday"
                                onClick={isTuesdayOpenClose}
                                defaultChecked={
                                  userDataRedux.data.providers
                                          .provider_working_hours[2]
                                          .time_diss == 1
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>Wednesday</td>
                          <td>
                            <div className="input-group">
                              <TimePicker
                                id="wednesdayclosetime"
                                class="form-control"
                                placeholder={
                                  userDataRedux
                                    ? tConvert(
                                      userDataRedux.data.providers
                                          .provider_working_hours[3].open_time
                                      )
                                    : "--:--"
                                }
                                showSecond={false}
                                onChange={getWednesdayOpenTime}
                                use12Hours
                              />
                            </div>
                          </td>
                          <td>
                            <div className="input-group">
                              <TimePicker
                                id="wednesdayclosetime"
                                class="form-control"
                                placeholder={
                                  userDataRedux
                                    ? tConvert(
                                      userDataRedux.data.providers
                                          .provider_working_hours[3].close_time
                                      )
                                    : "--:--"
                                }
                                showSecond={false}
                                onChange={getWednesdayCloseTime}
                                use12Hours
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <label className="customcheck">
                              <input
                                type="checkbox"
                                name="wednesday"
                                id="wednesday"
                                onClick={isWednesdayOpenClose}
                                defaultChecked={
                                  userDataRedux.data.providers
                                          .provider_working_hours[3]
                                          .time_diss == 1
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>Thursday</td>
                          <td>
                            <div className="input-group">
                              <TimePicker
                                id="thursdayclosetime"
                                class="form-control"
                                placeholder={
                                  userDataRedux
                                    ? tConvert(
                                      userDataRedux.data.providers
                                          .provider_working_hours[4].open_time
                                      )
                                    : "--:--"
                                }
                                showSecond={false}
                                onChange={getThursdayOpenTime}
                                use12Hours
                              />
                            </div>
                          </td>
                          <td>
                            <div className="input-group">
                              <TimePicker
                                id="thursdayclosetime"
                                class="form-control"
                                placeholder={
                                  userDataRedux
                                    ? tConvert(
                                      userDataRedux.data.providers
                                          .provider_working_hours[4].close_time
                                      )
                                    : "--:--"
                                }
                                showSecond={false}
                                onChange={getThursdayCloseTime}
                                use12Hours
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <label className="customcheck">
                              <input
                                type="checkbox"
                                name="thursday"
                                id="thursday"
                                onClick={isThursdayOpenClose}
                                defaultChecked={
                                  userDataRedux.data.providers
                                          .provider_working_hours[4]
                                          .time_diss == 1
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>Friday</td>
                          <td>
                            <div className="input-group">
                              <TimePicker
                                id="fridayclosetime"
                                class="form-control"
                                placeholder={
                                  userDataRedux
                                    ? tConvert(
                                      userDataRedux.data.providers
                                          .provider_working_hours[5].open_time
                                      )
                                    : "--:--"
                                }
                                showSecond={false}
                                onChange={getFridayOpenTime}
                                use12Hours
                              />
                            </div>
                          </td>
                          <td>
                            <div className="input-group">
                              <TimePicker
                                id="fridayclosetime"
                                class="form-control"
                                placeholder={
                                  userDataRedux
                                    ? tConvert(
                                      userDataRedux.data.providers
                                          .provider_working_hours[5].close_time
                                      )
                                    : "--:--"
                                }
                                showSecond={false}
                                onChange={getFridayCloseTime}
                                use12Hours
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <label className="customcheck">
                              <input
                                type="checkbox"
                                name="friday"
                                id="friday"
                                onClick={isFridayOpenClose}
                                defaultChecked={
                                  userDataRedux.data.providers
                                          .provider_working_hours[5]
                                          .time_diss == 1
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>Saturday</td>
                          <td>
                            <div className="input-group">
                              <TimePicker
                                id="saturdayclosetime"
                                class="form-control"
                                placeholder={
                                  userDataRedux
                                    ? tConvert(
                                      userDataRedux.data.providers
                                          .provider_working_hours[6].open_time
                                      )
                                    : "--:--"
                                }
                                showSecond={false}
                                onChange={getSaturdayOpenTime}
                                use12Hours
                              />
                            </div>
                          </td>
                          <td>
                            <div className="input-group">
                              <TimePicker
                                id="saturdayclosetime"
                                class="form-control"
                                placeholder={
                                  userDataRedux
                                    ? tConvert(
                                      userDataRedux.data.providers
                                          .provider_working_hours[6].close_time
                                      )
                                    : "--:--"
                                }
                                showSecond={false}
                                onChange={getSaturdayCloseTime}
                                use12Hours
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <label className="customcheck">
                              <input
                                type="checkbox"
                                name="saturday"
                                id="saturday"
                                onClick={isSaturdayOpenClose}
                                defaultChecked={
                                  userDataRedux.data.providers
                                          .provider_working_hours[6]
                                          .time_diss == 1
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
