import React, {useEffect, useState, memo} from 'react'
// import { createSlice, createAsyncThunk } from '@reduxjs/tookit';
import { API_URL, IMG_URL } from "../../constant";
import BannerSearchBox from './BannerSearchBox'
import { useSelector } from "react-redux";
import Carousel from 'react-bootstrap/Carousel';
import Fade from 'react-bootstrap/Fade'
import { searchBannerDropdownDisplayStatus } from "../../redux/actions/action";
import { useDispatch } from "react-redux";
import { searchNavBarDropdownDisplayStatus } from "../../redux/actions/action";


const Banner = () => {
  const dispatch = useDispatch();

  const images = useSelector((state) => state.banners)
  const searchBarDisplayStatus = useSelector((state) => state.searchNavBarDropdownDisplayStatus)
  // console.log(searchBarDisplayStatus)
  // const searchBannerBarDisplayStatus = useSelector((state) => state.searchBannerDropdownDisplayStatus)

// console.log("sds", searchBannerBarDisplayStatus)
  // useEffect(() => {
  //   if(searchBarDisplayStatus === 'none'){
  //     dispatch(searchBarDisplayStatus(''))
  //   }
  // },[searchBarDisplayStatus])


  const img = JSON.parse(window.localStorage.getItem("banners"))

  const [ BannerImgs, setBannerImgs] = useState([

  ]);
  function callBanner() {
 
       fetch(API_URL+'banner/view',{
      method: 'GET',
      headers: {
        // 'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    }).then((response) => response.json())
    .then((data) => {
      // console.log(data)
      setBannerImgs(data.data)
    });
   
     }


const changeSearchBarDropDownDisplayStatus = () => {


 if(searchBarDisplayStatus === '' ){
      dispatch(searchNavBarDropdownDisplayStatus('none'))
    }

//     if(searchBannerBarDisplayStatus === 'none'){
//       dispatch(searchNavBarDropdownDisplayStatus(''))
//     }
}
  return (
   
     <Carousel fade interval={4000} onClick={changeSearchBarDropDownDisplayStatus}>
         {
    img && img.map((item) => {
      return(
  
      <Carousel.Item key={item.id}>
        
        <img
          className="d-block w-100"
          style={{height: "640px"}}
          src={IMG_URL+item.image}
          alt="First slide"
        />
          <BannerSearchBox />

      </Carousel.Item>

     )
    })
   } 
         </Carousel>

  )
}

export default memo(Banner);