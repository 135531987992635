import React, { useEffect,useState} from 'react'
import * as geolib from "geolib";
import axios from "axios";
import { API_URL, IMG_URL } from "../constant";

const searchResult = async (mainId, subId, lat, long) => {
 const finalData =[]
  // console.log(id);
  let dd=[]
  await axios({
    url: API_URL + "search",
    method: "POST",
    headers: { "content-Type": "application/json/x-www-form-urlencoded" },
    data: { 
      main_category_id: mainId, 
      sub_category_id: subId
  },
  })
    .then((response) => {
          // console.log(response.data.ProviderService.length);
          console.log(response.data.ProviderService);

          if(response.data.ProviderService.length !== 0)
          {
              dd =  convertLatLongToKM(response.data.ProviderService, lat, long)[0];
              console.log(22)
            console.log(dd)
            // finalData = dd
          } else {
              alert("No Data Found")
          }
    })
    .catch((error) => {
      console.log(error);
    });
    return dd;
};

const convertLatLongToKM = (data, lat, long) =>  {
  // const convertedDistance =0
  // const completeData =[]

  const kmDistance = [];
  let newSearchData = [...data];
  for (let i in data) {
    const dis = geolib.getDistance(
      { latitude: lat, longitude: long },
      {
        latitude: Number(data[i].users.providers.latitude),
        longitude: Number(data[i].users.providers.longitude),
      }
    );
    newSearchData[i].kms = geolib.convertDistance(dis, "km");
  }

  // convertedDistance = kmDistance.sort();
  let sortedataTemp = [];
  sortedataTemp = newSearchData.sort(function (a, b) {
    return a.kms - b.kms;
  });

  // console.log("hooks")
  // console.log(sortedataTemp)
  // completeData = sortedataTemp
  localStorage.setItem("searchedData", JSON.stringify(sortedataTemp));
  // navigate("/search");
  return [sortedataTemp]
}
export default searchResult;