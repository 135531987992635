import React from 'react'

const LatestBlogs = () => {
  return (
    <section className="bg-fff8f6">
            <div className="container">
                <div className="row clearfix">
                    <div className="col-12">
                        <h3>Our Latest Blogs</h3>
                    </div>
                </div>
                <div className="row mt-3 clearfix">
                    <div className="col-12 col-lg-4 col-xl-4">
                        <div className="blog card">
                            <img src="images/img-3.jpg" className="card-img-top" alt="..."/>
                            <div className="card-body">
                                <h5 className="card-title">How to learn Everything about Dog Care</h5>
                                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content... <a >Read more</a></p>
                                <p className="card-text">14-Oct-2022</p>
                            </div>
                          </div>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-4">
                        <div className="blog card">
                            <img src="images/img-3.jpg" className="card-img-top" alt="..."/>
                            <div className="card-body">
                                <h5 className="card-title">How to learn Everything about Dog Care</h5>
                                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content... <a >Read more</a></p>
                                <p className="card-text">14-Oct-2022</p>
                            </div>
                          </div>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-4">
                        <div className="blog card">
                            <img src="images/img-3.jpg" className="card-img-top" alt="..."/>
                            <div className="card-body">
                                <h5 className="card-title">How to learn Everything about Dog Care</h5>
                                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content... <a >Read more</a></p>
                                <p className="card-text">14-Oct-2022</p>
                            </div>
                          </div>
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="col-12 text-center">
                        <a  className="btn btn-link">View all</a>
                    </div>
                </div>
            </div>
        </section>

  )
}

export default LatestBlogs