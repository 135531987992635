import React, { useEffect, useState } from "react";
import { API_URL, IMG_URL } from "../../constant";
import { useNavigate } from "react-router-dom";
import { useSelector} from 'react-redux';
import { useDispatch} from 'react-redux'
import {editService} from '../../redux/actions'
import { getSubCategories } from "../../redux/actions/action";
import _ from 'lodash';
import isEqual from 'lodash/isEqual';
import SnackbarUI from "../UI Components/SnackbarUI"
import { useLocation } from 'react-router-dom'
import ProviderSideMenu from "./ProviderSideMenu";
import LoadingSpinner from './LoadingSpinner'
import Modal from 'react-bootstrap/Modal';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const ServiceEdit = (props) => {
  const userdata = JSON.parse(window.localStorage.getItem("paw5-login-data"))
  const dispatch = useDispatch();
  // const reduxData = useSelector((state) =>state )
  // const serviceData = useSelector((state) =>state )
  const allsubCategories = useSelector((state) => state.getSubCategories)
  const editserviceDataStore = useSelector((state) => state.getEditDataReducer)
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [shareCapacityStatus, setshareCapacityStatus] = useState(false)
  const [petSizesStatus, setpetSizesStatus] = useState(false)
  const [priceStatus, setpriceStatus] = useState(false)
  const [amenityStatus, setamenityStatus] = useState(false)
  const [imageStatus, setimageStatus] = useState(false)

  const [shareCapacity, setShareCapacity] = useState(0);
  const [petSizes, setPetSizes] = useState([]);
  const [selectedAminities, setselectedAminities] = useState([]);
const [ amenities, setEmenities]= useState([])
const [ convertedImages, setConvertedImages]= useState([])
const [ apiImages, setAPIImages]= useState([])
const [newAmenities, setNewAmenities] =useState([])
const [allAmenitiesData, setAllAmenitiesData] =useState([])
const [userAllAmenities, setUserAllAmenities] = useState([])
  const  [prices, setPrices] = useState([])
  const  [newCatPrices, setnewCatPrices] = useState([])
const [amenitiesAPIPrices,setamenitiesAPIPrices] = useState([])
const [deleteAmenities, setDeleteAmenities] = useState([])
  const allPetSizesList = ['1-5 kg', '5-10 kg', '10-20 kg', '20+ kg']
  const [open, setOpen] = useState(false);
const [invalidPriceStatus, setinvalidPriceStatus] = useState(false)
let data = 0;

const snackbarBoxStyling = {
  bottom: "250px",
  left: "800px",
  top: "-200px",
};

const handleCloseSnackBar = (event, reason) => {
  if (reason === "clickaway") {
    return;
  }

  setOpen(false);
};
const navigate = useNavigate();
// useEffect(() => {
//   // console.log('location', location)
//   console.log(1, location.state)
// },[])





useEffect(() => {
// console.log("edit", editserviceDataStore[0].id)
// console.log("all", allsubCategories)
// allsubCategories.map((tt) => console.log(tt) )
  // console.log(allsubCategories)
  // if(props.providerServiceData) {
  if(allsubCategories){
    collectEmenities();
  }
  // collectEmenities();
  // }
  // if(editserviceDataStore) {
  //   if(allsubCategories){
  //     collectEmenities();
  //   }
    
  //   }
  },[allsubCategories,editserviceDataStore])
  
  
  function collectEmenities() {  
    // let addedAmenities=props.providerServiceData[0].provider_sub_service;
    let addedAmenities=editserviceDataStore[0].provider_sub_service;
// console.log("collectemfunction", editserviceDataStore[0].provider_sub_service)
// console.log("added", addedAmenities)

    // let mainId = props.providerServiceData[0].main_category_id
    let mainId = location.state.data.data.main_category_id

    let anotherTemp=[];
  // console.log("all", allsubCategories);
  // console.log("added", addedAmenities)

  allsubCategories.map((t) => {
    addedAmenities.filter((d) => {
      if(t.sub_category_name === d.sub_categories.sub_category_name)
      {
  // console.log("t",t)
  // console.log("d",d)
        if(anotherTemp.length ==0) {
                anotherTemp.push({
                  id: t.id,
                  selectedStatus: 1,
                  main_category_id: mainId,
                  image: t.image,
                  sub_category_name: t.sub_category_name,
                  price: d.price,
                  premium: t.premium,
                  provider_service_id: d.id
                })
              } else {
                 let ind = anotherTemp.findIndex((fin) => 
          fin.sub_category_name === d.sub_categories.sub_category_name)
          if(ind < 0){
          anotherTemp.push({
                     id: t.id,
                  selectedStatus: 1,
                  main_category_id: mainId,
                  image: t.image,
                  sub_category_name: t.sub_category_name,
                  price: d.price,
                  premium: t.premium,
                  provider_service_id: d.id
          })
        }
      }
    } 
  })
  })
  console.log("allsubCategoriesa", allsubCategories)
  
  allsubCategories.map((group) => {
    // console.log(111)
    // console.log("g", group)
    const objExist = anotherTemp.find((config) => 
    isEqual(group.sub_category_name, config.sub_category_name));
    if (!objExist) {
      anotherTemp.push({
        id: group.id,
     selectedStatus: 0,
     main_category_id: mainId,
     image: group.image,
     sub_category_name: group.sub_category_name,
     premium: group.premium,
     provider_service_id: group.id
  })
    }
  });
  // console.log("anoth", anotherTemp)
  setEmenities(anotherTemp);
  fetchRemainingAmenities(anotherTemp)
  }
 
//   useEffect(() => {
// console.log("am", amenities)
// console.log("amapi",amenitiesAPIPrices )
//   },[])

  function fetchRemainingAmenities(data) {
    const nonMatchingGroups = []
  
    let findNonMatching = allsubCategories.map((group) => {
      const objExist = data.find((config) => 
      isEqual(group.sub_category_name, config.sub_category_name));
      if (!objExist) {
        nonMatchingGroups.push(group);
      }
    });
    // console.log(nonMatchingGroups)
    
  }
  
  


const changesInPrices = (e) => {
let copyingAmenities=[]

console.log(e.target.id)
//pushing prices for those selectedstatus 1
// console.log("amenities", amenities)



  amenities.forEach((am) => {
    if(am.selectedStatus === 1)
    {
      setpriceStatus(false)
      copyingAmenities.push(am)
    } 
  })
// console.log("newprice", newprice)
//  console.log("copyingAmenities", copyingAmenities)

 const newPrices=[]

  //putting new value in new object copied

  for (const p in copyingAmenities) {
     if(copyingAmenities[p].id == e.target.id)
     {
      // newPrices.push(copyingAmenities[p] )
      // copyingAmenities[p].price=e.target.value
      copyingAmenities[p].price=Math.abs(e.target.value)
     } 
  }
  console.log("newPrices", copyingAmenities)
  setnewCatPrices(copyingAmenities)
  let newaddedPrice=[]

//   //putting new prices in new object named newaddedprice
// console.log("len", copyingAmenities.length)
//   for(let i=0; i< copyingAmenities.length; i++)
//   {
//     console.log("id", typeof(copyingAmenities[i].id))
//     console.log(typeof(e.target.id))
// console.log(copyingAmenities[i])
// console.log(e.target.id)
// console.log("selectedstatus", copyingAmenities[i].selectedStatus)
//     if(copyingAmenities[i].id === Number(e.target.id))
//      { 
//       newaddedPrice.push({
//         id: copyingAmenities[i].id,
//         price: e.target.value
//       })
//      }
//   }
  // console.log("newaddedPrice", newaddedPrice)

//   //setting state for api value
  // for(const p in newaddedPrice){
  //   // console.log([newaddedPrice[p].id] +" :"+ newaddedPrice[p].price)
  //   setnewCatPrices({...newCatPrices, 
  //     [newaddedPrice[p].id]: newaddedPrice[p].price})
  // }



 
//   // setnewCatPrices(newaddedPrice)
// for(const p in copyingAmenities){
//   let indexvalue = copyingAmenities.findIndex((i) => 
//   i.id == e.target.id
//   )
//   if(indexvalue >=0 )
//   {
//     copyingAmenities.splice(indexvalue, 1)
//   }
// }
// // console.log(copyingAmenities)

// // console.log("copying", copyingAmenities)
// // console.log("newprice", newprice)
// // newprice.forEach((item) => {
// //   console.log(item.price)
// //   // newpriceforAPI=   {
// //   //   [item.id]: item.price
// //   // }
// //  })

  let pp = [];
//   // let prices = [];

 
//   //this was adding  old plus new values in the object

  // for (const p in copyingAmenities) {
  //   if(copyingAmenities[p].sub_category_id !== null)
  //   {
  //   // console.log(amenities[p])
  //       // prices = {
  //       //   ...prices,
  //       //   [amenities[p].sub_categories.id]: amenities[p].price,
  //       // };

  //     pp = {
  //         ...pp,
  //         [copyingAmenities[p].sub_category_id ]: 
  //         {
  //           [copyingAmenities[p].id]:
  //           copyingAmenities[p].price
  //         }          
  //       };
  //     }
  //   }
  //   console.log("pp", pp)
    setPrices(pp)
// let newcatprices=[]
//     for(const newp in newprice)
//     {
//       newcatprices = {
//         ...newcatprices, [newprice[newp].id]:
//         newprice[newp].price
//       }
//     }
//     console.log(newcatprices)
//     setnewCatPrices(newcatprices)
// for (const n in newprice)
// {
//   console.log(newprice[n].price)
// newpriceforAPI=   {
//   [newprice[n].id]: newprice[n].price
//     }
// }   

  //  console.log(newpriceforAPI)
  //  setPrices(pp)

}



  const handlePetSizes = (e) => { 
    setpetSizesStatus(false)
    let dummy=[...petSizes]
    dummy.forEach((item) => {
      if(item.value === e.target.value)
      {
        if(item.status === 1){
          item.status=0
        } else {
          item.status=1
        }
       
      } 
    })
    // console.log(dummy)
    setPetSizes(dummy)
// dummy.map((i) => console.log(i.value))

  //   setPetSizes([...petSizes, { id: e.target.id }]);
  //   let indexValue = 0;
  //   indexValue = petSizes.findIndex((i) => i.id == e.target.id);
  //   console.log(indexValue)
  //   if (indexValue >= 0) {
  //     let temp = [...petSizes];
  //     temp.splice(indexValue, 1);
  //     setPetSizes(temp);
  //   } else if (indexValue == -1) {

  //     //         const arrayvalue = [...petSizes];
  //     // arrayvalue.push({id:e.target.id, name: e.target.value})
  //     setPetSizes([...petSizes, { id: e.target.id }]);
  // };
}


  function checkSizeValidation(e) {
    let dummy = e.target.files;
    // console.log(dummy)
    for (let i = 0; i < dummy.length; i++) {
      data += dummy[i].size / 1024 / 1024;
      if (data >= 20) {
        alert("exceededl limit");
      } else if (data <= 20) {
        handleImage(dummy);
      }
    }
  }
  const removeImage = (e) => {
    // console.log(e.target.id)
    // console.log(convertedImages)

    e.preventDefault();
    let indexvalue = 0;
    indexvalue = convertedImages.findIndex((i) => i.id == e.target.id);
      //  console.log(indexvalue)
    if (indexvalue >= 0) {
      let dummy = [...convertedImages];
      dummy.splice(indexvalue, 1);
      // console.log(dummy)
      setConvertedImages(dummy);
    }
  };

  const removeAPIImage = (e) => {
console.log(1,location.state.data.data[0].id)
    let imgSrc = document.getElementById(e.target.id).previousSibling.src;
    // console.log(imgSrc)
    // console.log(props.providerServiceData[0].id)
let newImagePath=imgSrc.replace("https://paw5.digiinteracts.in/", "")
// console.log(newImagePath)
    fetch(API_URL+'user/provider/service/delete-image', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': 'Bearer '+ userdata.token,
      },
      body: JSON.stringify({id: location.state.data.data[0].id, gallery_image:newImagePath }),
    }).then((res) => res.json())
    .then((data) => {
    if(data == 1)
    {
      let imgg = [...apiImages]
      let ind = imgg.findIndex(i => i == e.target.id )
      imgg.splice(ind, 1)
      setAPIImages(imgg)
      alert("Image is deleted")
    }
  }
      )
    .catch((err) => console.log(err))
  }
 

  const saveDetails = async (e) => {
    e.preventDefault();
// console.log(deleteAmenities)
// console.log("amenities", amenities)

let imagestatuslocal = false
let capacitystatuslocal = false
let petsizestatuslocal = false
let amenitystatuslocal = false
let pricestatuslocal = false

let copyingAmenities=[]
setIsLoading(true)
setShow(true);
amenities.forEach((am) => {
  if(am.selectedStatus === 1)
  {
    copyingAmenities.push(am)
  } 
})
// console.log("newprice", newprice)
console.log("copyingAmenities", copyingAmenities)
const gallery=[]
if(convertedImages.length>0)
{
    for (const img in convertedImages){
      gallery.push(convertedImages[img].code)
    }
  } else if(apiImages.length ==0 
    && convertedImages.length ==0)
    {
      imagestatuslocal= true
      setimageStatus(true)

    // alert("image is empty")
  }

    let psize=[]
    let petsizestatusCount=0
    let countpetsize = petSizes.length
    for(let d in petSizes)
    {
     
    if(petSizes[d].status == 1){
      petsizestatusCount +=1
      psize = {
        ...psize, [petSizes[d].value]: petSizes[d].value
      }
    } 
    console.log("petsizestatusCount", petsizestatusCount)
    }
if (petsizestatusCount ===0 ){
  petsizestatuslocal= true
  setpetSizesStatus(true)
      // alert("petsize cannot be empty")
      
    }
// console.log("newCatPrices", newCatPrices)
// console.log("copyingAmenities", copyingAmenities)

let apiPrices=[]
let lengthprice = newCatPrices.length
let pricecount=0
if(newCatPrices.length !== 0){
  newCatPrices.map((i) => {
    if(i.price !== 0){
      console.log("i", i)
    apiPrices = {
      ...apiPrices, 
      [i.id]: i.price
    } 
  } else { 
    setpriceStatus(true)
    pricestatuslocal= true
    // alert("price is empty")
  }
  })
} 

if(copyingAmenities.length>0) {
  copyingAmenities.map((i) => {
    if(i.price !== undefined){
    apiPrices = {
      ...apiPrices, 
      [i.id]: i.price
    } 
  } else {
    setpriceStatus(true)
    pricestatuslocal = true
    // alert("price is empty")
  }
  })
} 

if(copyingAmenities.length ==0 && newCatPrices.length ==0 )
{
  setamenityStatus(true)
  amenitystatuslocal=true
  // alert("One amenity must be selected")
}



// else {
//   setpriceStatus(true)
//   alert("One amenity must be selected")
// }

    // console.log("prices", apiPrices)

// let pp=[]
// const old = []
// amenities.map((a) => {
//   if(a.selectedStatus === 1)
//   {
//     old.push(a)
//   }
// })

// console.log(shareCapacityStatus,petSizesStatus, priceStatus, amenityStatus, imageStatus )
// const newprices = []
// newCatPrices.map((n) => {
//   if(n.selectedStatus === 1)
//   {
//     newprices.push(n)
//   }
// })
// old.forEach((a) => {

//     newCatPrices.map((n) => {
//       if(n.id === a.id)
//       {
//         let ind = old.findIndex((f) => f.id === n.id)
//         old.splice(ind, 1)
//       }
//     })
  
// })

// // console.log("newprices", newprices)
// let pp = []
// for(const p in old)
// {
//   console.log(old[p].sub_category_id)
//   if(old[p].sub_category_id !== null)
//   {
//   pp = {
//     ...pp, 
//     [old[p].sub_category_id] :
//     {
//       [old[p].id]:
//       old[p].price
//     }
//   }
//   }
// }
// console.log("pp", pp)
// let newpriceforAPI=[]
// for (const n in newCatPrices)
// {
//   console.log(newCatPrices[n].price)
// newpriceforAPI=   {...newpriceforAPI,
//   [newCatPrices[n].id]: newCatPrices[n].price
//     }
// }   
// console.log("deleteAmenities", deleteAmenities)


const obj=[{
      id: location.state.data.data[0].id,
    main_category_id:location.state.data.data[0].main_category_id,
    price: apiPrices,
    share_capacity: shareCapacity,
    pet_size: psize,
    gallery_images: gallery.length>=0 ? gallery : '',
    del_sub: deleteAmenities,
    status: 1
}]
console.log(obj)

// console.log(deleteAmenities)
//     let pricesNotChanged = [];
//     for (const p in amenitiesAPIPrices) {
//       if(amenitiesAPIPrices[p].sub_category_id !== null)
//       {
//         pricesNotChanged = {
//             ...pricesNotChanged,
//             [amenitiesAPIPrices[p].sub_category_id ]: 
//             {
//               [amenitiesAPIPrices[p].id]:
//               amenitiesAPIPrices[p].price
//             }          
//           };
//         }
//       }
// console.log("pricesNotChanged", pricesNotChanged)
    // let oldprices = [];
    // let pp=[]
    // prices.length>=0 ?   pp = [...prices]:   pp = [];
   

    // for (const p in amenities) {
    // // console.log(amenities[p])
    //     // oldprices = {
    //     //   ...oldprices,
    //     //   [amenities[p].sub_categories.id]: amenities[p].price,
    //     // };

    //   pp = {
    //       ...pp,
    //       [amenities[p].id]: {
    //         ...oldprices, 
    //         [amenities[p].id]: amenities[p].price
    //       },
    //     };
    // }
    // setPrices(...prices, oldprices)

//      console.log( props.providerServiceData.id)
//      console.log(props.providerServiceData.main_category_id)

      // console.log(pp)
      // console.log("prices changed", prices)

    //  console.log( shareCapacity)

    //  console.log( "wihstatus", petSizes)
// // //    console.log(amenities)
//  console.log(gallery, apiImages)

// console.log("newpriceadded", newCatPrices)
// console.log("convt", convertedImages)
// convertedImages.length>0 ? console.log("data") : console.log("nodata");

if(!shareCapacityStatus && !imagestatuslocal && !petsizestatuslocal &&
  !amenitystatuslocal &&  !pricestatuslocal)
  {
 
    await fetch(API_URL+'user/provider/service/update', {
      method: 'POST',
      headers: { 'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer '+ userdata.token,
    },
      body: JSON.stringify({
        id: location.state.data.data[0].id,
        main_category_id: location.state.data.data[0].main_category_id,
        price: apiPrices,
        share_capacity: shareCapacity,
        pet_size: psize,
        gallery_images: gallery.length>=0 ? gallery : '',
        del_sub: deleteAmenities,
        status: 1
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setShow(false);
        setIsLoading(false)
        // dispatch(getSubCategories([]))
    
        // alert("Changes are saved")
        navigate(-1)
      })
      .catch((err) => console.log(err));
    
  } else {
    setShow(false);
    setIsLoading(false)
    // alert("it has error")
    setOpen(true)
  }
 

  }


  const handleImage = (dd) => {
    const temparray = [...convertedImages];
    setimageStatus(false)
    // console.log(temparray)
    for (let i = 0; i < dd.length; i++) {
      // setProfilePic(URL.createObjectURL(dd[i]));
      const selectedImage = dd;
      let reader = new FileReader();
      reader.readAsDataURL(selectedImage[i]);
      reader.onload = (e) => {
        // console.log(e.target.result)
        temparray.push({
          id: i,
          code:e.target.result});
          // console.log(temparray)
          setConvertedImages(temparray);
      };
    }
  };

  // function collectEmenities() { 
  //   let allAme=props.allData;
  //   let anotherTemp=[];
  //   let userSelectedData=props.providerServiceData.provider_sub_service
  //   // for (const [key, value] of Object.entries())
  //   // {
  //   //   temp.push(value.sub_categories)
  //   // }
  //   allAme.map((d) => {
  //     userSelectedData.filter((t) => {
  //       // console.log(t.sub_categories.sub_category_name)
  //       // console.log(d.sub_category_name)
  //       if(t.sub_categories.sub_category_name === d.sub_category_name){
  //         // console.log(t.sub_categories.sub_category_name)
  //         // console.log(d.sub_category_name)
  //         if(anotherTemp.length ==0) {
  //         anotherTemp.push({

  //           //match>>mark 1
  //           //notmatched>>mark 0
  //           //put all fields also
  //           id: d.id,
  //           status: 1,
  //           main_category_id: d.main_category_id,
  //           image: d.image,
  //           sub_category_name: d.sub_category_name,
  //           premium: d.premium,
  //         })
          
  //       }
  //       else {
          
  //         let ind = anotherTemp.findIndex((fin) => 
  //         fin.sub_category_name === d.sub_category_name)

  //         if(ind < 0){
  //           // console.log("first condition")
  //           // console.log(ind)

  //         // console.log(t.sub_categories.sub_category_name)
  //         // console.log(d.sub_category_name)
  //         anotherTemp.push({

  //           //match>>mark 1
  //           //notmatched>>mark 0
  //           //put all fields also
  //           id: d.id,
  //           status: 1,
  //           main_category_id: d.main_category_id,
  //           image: d.image,
  //           sub_category_name: d.sub_category_name,
  //           premium: d.premium,
           
  //         })
  //         // console.log(anotherTemp)

  //       }
  //       }
  //     }
  //       else {
  //         if(anotherTemp.length ==0) {
  //         //   console.log(t.sub_categories.sub_category_name)
  //         // console.log(d.sub_category_name)
  //         console.log(0)
  //           // anotherTemp.push({
  
  //           //   //match>>mark 1
  //           //   //notmatched>>mark 0
  //           //   //put all fields also
  //           //   id: d.id,
  //           //   status: 1,
  //           //   main_category_id: d.main_category_id,
  //           //   image: d.image,
  //           //   sub_category_name: d.sub_category_name,
  //           //   premium: d.premium,
  //           // })
  //         }
  //         else {
  //           console.log(anotherTemp)

  //           let ind = anotherTemp.findIndex((fin) => 
  
  //           fin.sub_category_name !== d.sub_category_name)
  //           console.log(t.sub_categories.sub_category_name)
  //           console.log(d.sub_category_name)
  //           console.log(ind)
  //           if(ind < 0){
  //             anotherTemp.push({
  //               //match>>mark 1
  //               //notmatched>>mark 0
  //               //put all fields also
  //               id: d.id,
  //               status: 0,
  //               main_category_id: d.main_category_id,
  //               image: d.image,
  //               sub_category_name: d.sub_category_name,
  //               premium: d.premium,
               
  //             })
  //           }
  //           // console.log(ind)

            

  //         } 
  //       }
  //     })
  //   })
  //   // console.log(anotherTemp)
  //   setselectedAminities(anotherTemp)
  // }


  function collectPetSizes() { 
    let temp=[]
    let anothertemp=[]
    // console.log("peta", petSizes)
   let d= location.state.data.data[0].pet_size
    for (const [key, value] of Object.entries(d))
    {
      temp.push(value)
    }
   

    allPetSizesList.map((group) => {
      // console.log(111)
      const objExist = temp.find((config) => 
      isEqual(group, config));
      if (!objExist) {
        anothertemp.push({
          value: group,
        status: 0
    })
      }
    });
// console.log("anoth", anothertemp)


    
    // allPetSizesList.map((all,index) => {
    //   temp.filter((t) => 
    //   {
    //     console.log("t", t)
    //     console.log("all", all)
    //     // if(t !== all)
    //     // {
    //     //   // anothertemp([...anothertemp, {
    //     //   //   id:index,
    //     //   //   value: all,
    //     //   //   status: 0
    //     //   // }])
    //     //   anothertemp.push( {
    //     //     value: all,
    //     //     status: 0})
    //     // }
    //   })
    //   })
      temp.map((item) => {
        anothertemp.push({value: item, status: 1})
      })
    // console.log("pettemp", anothertemp)
    setPetSizes(anothertemp)
  }

useEffect(() => {
  // console.log(props);

    collectPetSizes();

// console.log("props", props.providerServiceData)
  // setEmenities(props.providerServiceData.provider_sub_service)
  // setPetSizes(props.providerServiceData.pet_size)


  // commented the active one
  console.log(2, location.state.data.data[0].share_capacity)
  setShareCapacity(location.state.data.data[0].share_capacity)
  setAPIImages(location.state.data.data[0].gallery_images)
  setamenitiesAPIPrices(location.state.data.data[0].provider_sub_service)

  // setShareCapacity(props.providerServiceData[0].share_capacity)
  // setAPIImages(props.providerServiceData[0].gallery_images)
  // setamenitiesAPIPrices(providerServiceData[0].provider_sub_service)

 // commented the active one
  // setPetSizes(petSizesProps)
//   fetch(API_URL+'service/sub-categories/'+props.providerServiceData.main_category_id,{
//     method: 'POST',
//     headers: {
//         'Accept': 'application/json',
//         'Authorization': 'Bearer '+ userdata.token,
//     },
// }).then((res) => res.json())
// .then((data) =>
// {
// if(data.success === true){
// // setAllAmenitiesData(data)
// // console.log(data)
// let b=data.data
// setAllAmenitiesData(b)
// // console.log(amenities[0].sub_categories.id)
// // console.log(b[0])
// // let ind=0
  
// setNewAmenities(b)

//   // Iterate the obj2 using for..in
// //   for(let i=0; i<=b.length; i++)
// //   {
// //     for(let j=0; j< amenities.length; j++)
// //     {
// // //       console.log(amenities[0].sub_categories.id)
// // // console.log(b[0].id)
// // if(b[j].id !==  amenities[j].sub_categories.id)
// // {
// //   ind=b.findIndex((i) => i.id === 
// //   amenities[j].sub_categories.id)
// //   console.log(ind)
// //   // console.log(b)
// //   b.splice(ind, 1)
// //     // console.log(amenities[j].sub_categories.id)
// // //indexvalue = base64Image.findIndex((i) => i.id == e.target.id);

// // // }
// // setNewAmenities(b)
// // console.log(b)
// // }

      
// //   }

// // // console.log(data.data[0].sub_category_name)
// // }
// }
// }
// )

},[])


  const getAminitiesNames = (e) => {
    console.log(2,e.target.id)
    console.log("val",e.target.value)

    let del = [...deleteAmenities]
    
    let tempamenities = [...amenities];
    for (let key in tempamenities) {
      setamenityStatus(false)
      // console.log(1, typeof(tempamenities[key]))

      if( tempamenities[key].id == Number(e.target.id)){
        if(tempamenities[key].selectedStatus == 1)
        { 
          del.push(Number(e.target.value))
          tempamenities[key].selectedStatus = 0

        } else {
          tempamenities[key].selectedStatus = 1
        }
      }
    }
        // console.log("del", del)
        setDeleteAmenities(del)

    console.log("tempamenities", tempamenities)
         setEmenities(tempamenities);

if(prices){
  const copyingAmenities=[...amenitiesAPIPrices]
for(const key in copyingAmenities)
{

  let indexv = copyingAmenities.findIndex((i) => i.sub_category_id == e.target.id)
  // console.log("ind", indexv)
  if(indexv >=0 )
  {
    copyingAmenities.splice(indexv, 1)
  }
}
// console.log("copyingAmenities", copyingAmenities)
setamenitiesAPIPrices(copyingAmenities)
} 
// console.log("ame", amenitiesAPIPrices)


    // console.log(tempamenities)

     
    // indexvalue = amenities.findIndex((i) =>
    //  i.id == e.target.id);
    // if (e.target.checked === false) {
    //   if (indexvalue >= 0) {
    //     let tempamenities = [...amenities];
    //     for (key in tempamenities) {
    //       tempamenities[key] == e.target.id ?
    //       tempamenities[key].selectedStatus = 0
    //     // tempamenities.splice(indexvalue, 1);
    //     // setEmenities(tempamenities);
    //   }

    // if (e.target.checked === true) {
    //   if (indexvalue == -1) {
    //     const arrayvalue = [...amenities];
    //     arrayvalue.push({
    //       id: e.target.id,
    //       name: e.target.value,
    //     });
    //     // setEmenities(arrayvalue);
    //     //console.log(arrayvalue)
    //   }
    // }
  }

  const shareCapacityHandler = (e) => {
    if(e.target.value == 0)
    {
      setshareCapacityStatus(true)
    } else {
      setshareCapacityStatus(false)
      setShareCapacity(Math.abs(e.target.value))
    }
  }

  const getPremiumAminitiesNames = (e) => {
    let tempamenities = [...amenities];
    // console.log("val",e.target.value)
    // console.log(2,e.target.id)

    let del = [...deleteAmenities]
    for (let key in tempamenities) {
      setamenityStatus(false)
      if( tempamenities[key].id == Number(e.target.id)){

        if(tempamenities[key].selectedStatus == 1)
        {
          let ind= tempamenities.findIndex((f) => f.id == e.target.id)

          del.push(Number(e.target.value))
          tempamenities[key].selectedStatus = 0
        } else {
          tempamenities[key].selectedStatus = 1
        }
      }
      // console.log("tempamenities", tempamenities)
      setEmenities(tempamenities);
      setDeleteAmenities(del)

      // if(prices){
      //   const copyingAmenities=[...amenitiesAPIPrices]
      // for(const key in copyingAmenities)
      // {
      
      //   let indexv = copyingAmenities.findIndex((i) => i.sub_category_id == e.target.id)
      //   console.log("ind", indexv)
      //   if(indexv >=0 )
      //   {
      //     copyingAmenities.splice(indexv, 1)
      //   }
      // }
      // console.log("copyingAmenities", copyingAmenities)
      // setamenitiesAPIPrices(copyingAmenities)
      // } 
      // console.log("del", del)

    }
    // indexvalue = amenities.findIndex((i) => i.id == e.target.id);
    // if (e.target.checked === false) {
    //   if (indexvalue >= 0) {
    //     let tempamenities = [...amenities];
    //     tempamenities.splice(indexvalue, 1);
    //     setEmenities(tempamenities);
    //   }
    // }
    // if (e.target.checked === true) {
    //   if (indexvalue == -1) {
    //     const arrayvalue = [...amenities];
    //     arrayvalue.push({
    //       id: e.target.id,
    //       name: e.target.value,
    //     });
    //     setEmenities(arrayvalue);
    //     //console.log(arrayvalue)
    //   }
    // }
  }

 
  return (
<>
<section>
        <div className="container">
          <div className="row clearfix">
          <ProviderSideMenu/>
          <div className="col-12 col-md-9 col-lg-8 col-xl-8">
    <form
      method="post"
      action=""
      id="pdcform"
      // onSubmit={handleServicesSaveInfo}
    >
      <div className="dashboard-title">
        My Services
        <span>
          <a style={{ cursor: "pointer" }} onClick={() => navigate("/step1")}>Add Services</a>
        </span>
      </div>
      <div className="form-title mb-2 mt-2">Share Capacity</div>
      <div className="form-group position-relative mb-3">
        <input
          type="number"
          className="form-control"
          id="sharecapacity"
          placeholder="How many dogs can you care for?"
          defaultValue={location.state.data.data[0].share_capacity}
          onChange={shareCapacityHandler}
        />
        {
          shareCapacityStatus &&
        
        <p style={{color: "red"}}>Share Capacity cannot be empty</p>
      }
        <div className="custom-icon">
          <i className="fa fa-paw"></i>
        </div>
      </div>
      {isLoading && (
                <Modal show={show} onHide={handleClose}>
                  <Modal.Body>
                    {" "}
                    <LoadingSpinner />{" "}
                  </Modal.Body>
                </Modal>
              )}
               <Snackbar
                style={snackbarBoxStyling}
                open={open}
                autoHideDuration={6000}
                onClose={handleCloseSnackBar}
              >
                <Alert
                  onClose={handleCloseSnackBar}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Service is Updated
                </Alert>
              </Snackbar>
      <div className="form-title mb-3 mt-3">Accepted Pet Size</div>
      <div className="row clearfix">


      {
        petSizes?.map((sizes,index) => {
          return (
            <div key={index} className="col-4">
            <label className="customcheck">
            <input
              type="checkbox"
              name={sizes.value}
              id={index}
              onChange={handlePetSizes}
              value={sizes.value}
              checked={sizes.status === 1 }
            />
            <span className="checkmark"> 
            </span>
            {sizes.value}
          </label>
          </div>
          )
        })
      }
      {
          petSizesStatus &&
        
        <p style={{color: "red"}}>Pet size must  be selected</p>
      }
       
      </div>
      <div className="form-title mb-2 mt-2">Set Amenities</div>
      <div className="amenities-box">
        {
          console.log("amenities",amenities )
        }
        <div className="form-subtitle mb-2">Essential Amenities</div>
        <ul className="amenities-select">
          {amenities?.map((sub, index) => {
            if(sub.premium == 0)
            return (
            <li key={sub.id}>
              <div className="amenities-checkbox petdaycare">
                <input
                  type="checkbox"
                  id={sub.id }
                  value={sub.provider_service_id}
                  onClick={getAminitiesNames}
                  defaultChecked={sub.selectedStatus == 1 ? sub.sub_category_name : ''}
                />
                <div>
                  <img src={IMG_URL + sub.image} />
                  {sub.sub_category_name}
                </div>
              </div>
            </li>
            )
          })}
        </ul>
        
        <div className="form-subtitle mb-2">Premium Amenities</div>
        <ul className="amenities-select">
        {amenities?.map((sub, index) => {
            if(sub.premium == 1)
            return (
            <li key={sub.id}>
              <div className="amenities-checkbox petdaycare">
                <input
                  type="checkbox"
                  id={sub.id}
                  value={sub.provider_service_id}
                  defaultChecked={sub.selectedStatus == 1 ? sub.sub_category_name : ''}
                  onClick={getPremiumAminitiesNames}
                />
                <div>
                  <img src={IMG_URL + sub.image} />
                  {sub.sub_category_name}
                </div>
              </div>
            </li>
            )
          })}
        </ul>
      </div>
      {
  amenityStatus && (
    <p style={{color: "red"}}>One amenity must be selected </p>
  )
}

      <div className="form-title mb-2">Set Price</div>
      <ul className="set-price">
      {amenities.map((p, index) => {
        if(p.selectedStatus == 1 )
      return (
        
        <li key={p.id}>{p.sub_category_name}
          <span>
            <i className="fa fa-inr"></i>
            <input
            id={p.id}
              type="number"
              defaultValue={p.price}
              onChange={changesInPrices}
              min="0"
              required
            />
          </span>
          {
  priceStatus && (
    <p style={{color: "red"}}>Price is not entered</p>
  )
}
          {
            invalidPriceStatus &&
          <span>Invalid Price</span>

          }
        </li>
 )
})}
      </ul>
      
      <div style={{ display: "flex" }}>
      {apiImages?.map((img, index) => 
        {
      return (
      <div className=" position-relative" 
        style={{ width: "20%", height: "20%",  }}
        key={img}>
        <img src={ IMG_URL+img} 
          style={{display: "inline",width: "100px", height: "100px",    
          }}  
        id={`img${img}`}
          />
                   

                  <i className="bi bi-x "
                  id={img} 
                  style={{position: 'absolute', marginTop: "0px", right: "10px",
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "rgb(226,108,71)",
                  fontSize: "23px",
                  cursor: "pointer"
                }}
                onClick={removeAPIImage}

                      >
          </i>
            </div>
             )
            })}
      </div>


      <div className="form-title mb-2">Add Pictures</div>

            {/* working images upload */}
      <div style={{ display: "flex" }}>
      {convertedImages.map((img, index) => {
      return (
      <div className=" position-relative" 
        style={{ width: "20%", height: "20%",  }}
        key={img.id}>
        <img src={img.code ? img.code : IMG_URL+img} 
          style={{display: "inline",width: "100px", height: "100px",    
          }}  
         
          />
                  <i className="bi bi-x "
                  id={img.id} 
                  style={{position: 'absolute', marginTop: "0px", right: "10px",
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "rgb(226,108,71)",
                  fontSize: "23px",
                  cursor: "pointer"
                }}
                onClick={removeImage}      >
          </i>
            </div>
             )
            })}
      </div>

      <ul className="amenities-select">
        <li>
          <div className="file-upload">
            {/* <input type="file" id="image" multiple
            /> */}
            <div>
              <i className="bi bi-plus-circle-fill">
                <input
                  type="file"
                  id="img"
                  name="img"
                  accept="image/jpg"
                  onChange={checkSizeValidation}
                  multiple
                />
              </i>
              Image
            </div>
          </div>
        </li>
      </ul>
{
  imageStatus && (
    <p style={{color: "red"}}>Image is not selected</p>
  )
}

<Snackbar
        style={snackbarBoxStyling}
        open={open}
        autoHideDuration={900}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="error"
          sx={{ width: "100%" }}
        >
          The form has errors
        </Alert>
        </Snackbar>
      <div className="text-center">
        <input
          type="submit"
          name="submit"
          id="submit"
          className="btn btn-theme"
          value="SAVE"
          onClick={saveDetails}
        />
      </div>
    </form>
    </div>
</div>
</div>
</section>
    </>
  );
};

export default ServiceEdit;
