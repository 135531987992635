import React, { useState, useEffect} from 'react'
import GoogleMapReact from 'google-map-react';
import Services from '../components/HomePageComponents/UIComponents/Services'
import UsersFeedback from '../components/HomePageComponents/UsersFeedback'
import FAQs from '../components/HomePageComponents/FAQs'
import NewsLetters from '../components/HomePageComponents/NewsLetters'
import LatestBlogs from '../components/HomePageComponents/LatestBlogs'
import Footer from '../components/Footer'
import Banner from '../components/HomePageComponents/Banner'
const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Home = () => {

    const [lat, setLat] = useState(0);
    const [long, setLong] = useState(0);
  

    // function computeDistance([prevLat, prevLong], [lat, long]) {
    //   const prevLatInRad = toRad(prevLat);
    //   const prevLongInRad = toRad(prevLong);
    //   const latInRad = toRad(lat);
    //   const longInRad = toRad(long);
    
    //   return (
    //     // In kilometers
    //     6377.830272 *
    //     Math.acos(
    //       Math.sin(prevLatInRad) * Math.sin(latInRad) +
    //         Math.cos(prevLatInRad) * Math.cos(latInRad) * Math.cos(longInRad - prevLongInRad),
    //     )
    //   );
    // }
    
    // function toRad(angle) {
    //   return (angle * Math.PI) / 180;
    // }

  //   useEffect(() => {
  //     navigator.geolocation.getCurrentPosition(function(position) {
  //       console.log(position.coords.latitude);
  //       console.log(position.coords.longitude);

  //       setLat(position.coords.latitude)
  //       setLong(position.coords.longitude)
  //       console.log(computeDistance([lat,long], [28.7164396, 77.1141469]));
  
  //   })
  // },[])

  useEffect(() => {
    window.scrollTo(0, 0)

  },[])
  
  return (
    <>
    
          <Banner />
    <Services />
<UsersFeedback />
<FAQs />
<LatestBlogs />
<NewsLetters />
<Footer />


    </>
  )
}

export default Home