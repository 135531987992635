import React from 'react'

const Footer = () => {
  return (
    <footer>
    <div className="container">
        <div className="row clearfix">
            <div className="col-12 col-lg-3 col-xl-3">
                <img src="images/logo.png" className="img-fluid mb-3" alt="Image goes here" title="Paw Five" style={{height: "30px"}} />
                <p className="ft-text w-75">This is our website This is our website This is our website This is our website </p>
            </div>
            <div className="col-6 col-lg-4 col-xl-4">
                <div className="ft-title mb-3">Recent Posts</div>
                <ul className="ft-links clearfix">
                    <li><a href="#">How to care dogs - 10 Oct 2022</a></li>
                    <li><a href="#">How to care dogs - 10 Oct 2022</a></li>
                    <li><a href="#">How to care dogs - 10 Oct 2022</a></li>
                    <li><a href="#">How to care dogs - 10 Oct 2022</a></li>
                </ul>
            </div>
            <div className="col-6 col-lg-3 col-xl-3">
                <div className="ft-title mb-3">Important Links</div>
                <ul className="ft-links clearfix">
                    <li><a href="#">Help</a></li>
                    <li><a href="#">Contact Us</a></li>
                    <li><a href="#">Privacy Policy</a></li>
                    <li><a href="#">Terms & Conditions</a></li>
                </ul>
            </div>
            <div className="col-12 col-lg-2 col-xl-2">
                <div className="ft-title mb-3">Follow Us</div>
                <ul className="slinks clearfix">
                    <li><a href="#"><img src="images/facebook.png" className="img-fluid" style={{height: "16px"}} alt="Image goes here" /></a></li>
                    <li><a href="#"><img src="images/instagram.png" className="img-fluid" style={{height: "16px"}} alt="Image goes here" /></a></li>
                    <li><a href="#"><img src="images/twitter.png" className="img-fluid" style={{height: "16px"}} alt="Image goes here" /></a></li>
                </ul>
            </div>
        </div>
        <hr />
        <div className="row clearfix">
            <div className="col-12 col-lg-6 col-xl-6 text-start">
                <p className="label text-start">©PawFive. 2022</p>
            </div>
            <div className="col-12 col-lg-6 col-xl-6 text-end">
                <p className="label text-end">Designed & Developed by : <a href="https://digiinteracts.com/" target="_blank">Digiinteracts Pvt. Ltd.</a></p>
            </div>
        </div>
    </div>
</footer>
  )
}

export default Footer