import { fullName } from "./reducers/reducer";
import { profileImage, logout, 
  banners, searchNavBarDropdownDisplayStatus,
  searchBannerDropdownDisplayStatus,
  getProviderServicesAction,getSubCategories,
  getEditDataReducer,storeMainCategoriesReducer,
  storeAmenitiesForSelectedServiceReducer,
  storeAutoAddressLatLongReducer,
  storeLoginStatusReducer,storeUserDataReducer,
  storeAccordionAllAmenitiesReducer,storeAccordionMainCatIDSReducer,
  storeAccordioncheckedStatusReducer
} from "./reducers/reducer";
// import { logout } from "./reducers/reducer";
// import { banners } from "./reducers/reducer";

import {combineReducers} from 'redux'
export default combineReducers({
  fullName,profileImage,logout,banners, 
  searchNavBarDropdownDisplayStatus,
  searchBannerDropdownDisplayStatus,getProviderServicesAction,
  getSubCategories,getEditDataReducer,storeMainCategoriesReducer,
  storeAmenitiesForSelectedServiceReducer, storeAutoAddressLatLongReducer,
  storeLoginStatusReducer,storeUserDataReducer,storeAccordionAllAmenitiesReducer,
  storeAccordionMainCatIDSReducer,storeAccordioncheckedStatusReducer
})