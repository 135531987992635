import React, { useState} from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';

const GooglePlacesAutoComplete = (props) => {


  const [address, setAddress] = useState();
  const [ coordinates, setCoordinates] = useState({
    lat: null,
    long: null
  })

 
  const handleSearchAddress = async value => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);

    // const ll=[]
    // console.log("latLng", latLng)
//     if(results >=0 )
//     {
// ll = await getLatLng(results[0])

//     }
    // console.log("ll", ll)
    setAddress(value)
    setCoordinates(latLng)
    // onClick();
    // console.log(value)


    props.sendData1(value)
    props.sendData(latLng)
  }

  // const onClick = () => {
  //   // props.sendData(coordinates)
    
  // }

  return (
    <PlacesAutocomplete
        value={address }
        onChange={setAddress}
        onSelect={handleSearchAddress}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="position-relative">
            <input
              {...getInputProps({
                name:"city", id:"city",
                placeholder:"Apartment/Road/Area(Optional)",
                className: "form-control mb-3",
              })}
            />
            <div className="autocomplete-dropdown-container ">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fff', cursor: 'pointer', color: 'red' }
                  : { backgroundColor: '#fff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description.substring(0,50)}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
  )
}

export default GooglePlacesAutoComplete

// /* global google */
// import React, { Component } from "react";
// import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
// import PlacesAutocomplete from "react-places-autocomplete";

// class LocationSearchInput extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { address: "" };
//     this.placesService = new window.google.maps.places.PlacesService(
//       document.createElement("div")
//     );
//     this.handleChange = this.handleChange.bind(this);
//     this.handleSelect = this.handleSelect.bind(this);
//   }

//   handleChange = address => {
//     this.setState({ address });
//   };

//   handleSelect = (address, placeId) => {
//     this.setState({ address });

//     const request = {
//       placeId: placeId,
//       fields: ["name", "geometry"]
//     };
//     this.placesService.getDetails(request, (place, status) => {
//       if (status == google.maps.places.PlacesServiceStatus.OK) {
//         this.props.onPlaceChanged(place);
//       }
//     });
//   };

//   render() {
//     return (
//       <PlacesAutocomplete
//         value={this.state.address}
//         onChange={this.handleChange}
//         onSelect={this.handleSelect}
//       >
//         {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
//           <div className="autocomplete-container">
//             <input
//               {...getInputProps({
//                 placeholder: "Search Places ...",
//                 className: "location-search-input"
//               })}
//             />
//             <div className="autocomplete-dropdown-container">
//               {loading && <div>Loading...</div>}
//               {suggestions.map(suggestion => {
//                 const className = suggestion.active
//                   ? "suggestion-item--active"
//                   : "suggestion-item";
//                 // inline style for demonstration purpose
//                 const style = suggestion.active
//                   ? { backgroundColor: "#fafafa", cursor: "pointer" }
//                   : { backgroundColor: "#ffffff", cursor: "pointer" };
//                 return (
//                   <div
//                     {...getSuggestionItemProps(suggestion, {
//                       className,
//                       style
//                     })}
//                   >
//                     <span>{suggestion.description}</span>
//                   </div>
//                 );
//               })}
//             </div>
//           </div>
//         )}
//       </PlacesAutocomplete>
//     );
//   }
// }

// class MapContainer extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { places: [] };
//     this.showPlace = this.showPlace.bind(this);
//     this.mapRef = React.createRef();
//   }

//   showPlace(place) {
//     this.setState(prevState => ({
//       places: [...prevState.places, place]
//     }));
//     this.mapRef.current.map.setCenter(place.geometry.location);
//   }

//   render() {
//     return (
//       <div className="map-container">
//         <LocationSearchInput onPlaceChanged={this.showPlace} />
//         <Map
//           ref={this.mapRef}
//           google={this.props.google}
//           className={"map"}
//           zoom={4}
//           initialCenter={this.props.center}
//         >
//           {this.state.places.map((place, i) => {
//             return <Marker key={i} position={place.geometry.location} />;
//           })}
//         </Map>
//       </div>
//     );
//   }
// }

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyBknQhZHMDyt-JrrR0Q8YbCT1USfwD9XOI",
//   libraries: ["places"]
// })(MapContainer);
