import React, {useEffect, useState, useReducer} from 'react'
import { INITIAL_STATE, searchedDataReducer} from "../../reducers/search";
import SideBarRandomResult from './SideBarRandomResult'
import SquareBoxCenterName from './SquareBoxCenterName';
import GooglePlacesAutoComplete from './GooglePlacesAutoComplete';
import * as geolib from 'geolib';
import { useNavigate } from "react-router-dom";
import WorkingHoursModal from "../UI Components/WorkingHoursModal"
import { DynamicStar } from 'react-dynamic-star';
import GoogleMap from '../ProvidersDetails/GoogleMap'
import { API_URL,IMG_URL } from "../../constant";


const SearchResult = () => {
     const local = JSON.parse(window.localStorage.getItem("searchedData"));
    const [bannerSearchedData, setbannerSearchedData] = useState(local)
    const [state, dispatch] = useReducer(searchedDataReducer, INITIAL_STATE)
    const [ searchedData, setSearchedData] = useState([])
    const [pawassuredFlag, setpawassuredFlag ] = useState(false)
    const [distanceFlag, setDistanceFlag] = useState(false)
    const [ lat, setLat] = useState(0)
    const [ long, setLong] = useState(0)
    const navigate = useNavigate();
    const [ workingHours, setWorkingHours] = useState([])
    const [ amenities, setAmenities] = useState([]);

    const ratingsValue=[1,2,3,4,5]
    const AnyReactComponent = ({ text }) => <div>{text}</div>;

    const changeDistanceList = () => {
        // console.log(distanceFlag)
        setDistanceFlag(!distanceFlag);
        if(distanceFlag){
            nearUserLocations();
        } else {
            farUserLocations();
        }
        
    }



    function showworkingHours() {
        alert(1)
    }
    function showProviderDetails(id) {
        console.log(id)
        navigate('/providerDetails', { state: { id: id } });
    }
    // navigate('/componentB',{state: { id: 1 }});


    function nearUserLocations() {
       let sortedataTemp= ( bannerSearchedData.sort(function(a, b) {
            if(a.kms > b.kms){
                return -1;
            }
            if(a.kms < b.kms){
                return 1;
            }
            return 0;
        }))
        // console.log(sortedataTemp)
    }

    function farUserLocations() {
        let sortedataTemp= ( bannerSearchedData.sort(function(a, b) {
            if(a.kms < b.kms){
                return -1;
            }
            if(a.kms > b.kms){
                return 1;
            }
            return 0;
        }))
        // console.log(sortedataTemp)
    }

   

    const changePawassuredList = () => {
        setpawassuredFlag(!pawassuredFlag);
        if(pawassuredFlag){
            showPassuredonTop();
        } else {
            showPassuredonDown();
        }
    }


    function showPassuredonTop(){
        let newdata= bannerSearchedData.sort(function(a, b) {
            if(a.users.providers.assured < b.users.providers.assured) {
                return -1;
            } 
            if( a.users.providers.assured>b.users.providers.assured)
            {
                return 1;
            }

                return 0;
        })
        setSearchedData(newdata)
    }

    function showPassuredonDown() {
        let newdata= searchedData.sort(function(a, b) {
            if(a.users.providers.assured > b.users.providers.assured) {
                return -1;
            } 
            if( a.users.providers.assured<b.users.providers.assured)
            {
                return 1;
            }

                return 0;
        })
        setSearchedData(newdata)
    }

    function gatherAmenities() {
        let joinAmenities=[]
        console.log(bannerSearchedData[0].provider_sub_service)
        let dummy =bannerSearchedData[0].provider_sub_service
        dummy.map((item) => {
            // console.log(item.sub_categories.sub_category_name)
            joinAmenities.push(item.sub_categories.sub_category_name)
        })
        setAmenities(joinAmenities)
        // console.log(joinAmenities.join())
    }
    useEffect(() => {
        let data = JSON.parse(localStorage.getItem('searchedData'));
        setbannerSearchedData(data)
        setWorkingHours(data)
        gatherAmenities();
        setTimeout(() => {
            setSearchedData(data)
        }, 100);
        let lat = JSON.parse(localStorage.getItem('lat'));
        let long = JSON.parse(localStorage.getItem('long'));
        setLat(lat)
        setLong(long)
    },[])

    useEffect(() => {
        // setbannerSearchedData()
    },[])

    const sendnewSearchedDataToSearchResult = (d) => {
        // console.log(d)
    }

    const getRatings = () => {
        alert(1)
    }

  return (
    <>
    <section>
    
    <div className="container">
        <form method="post" action="" id="searchForm">
            <div className="row clearfix">
                <div className="col-12 col-xl-5">
                    <div className="input-icon">
                    <GooglePlacesAutoComplete 
                   
                    />
                        {/* <input type="text" name="city" id="city" className="form-control mb-3" placeholder="City/Area + Pin Code" /> */}
                        <span><i className="fa fa-search"></i></span>
                    </div>
                </div>
                <div className="col-12 col-xl-5">
                    <div className="input-icon">
                        <input type="text" name="calendar" id="calendar" className="form-control mb-3" placeholder="Check In / Checkout Calendar" />
                        <span><i className="fa fa-calendar"></i></span>
                    </div>
                </div>
                <div className="col-12 col-xl-2">
                    <input type="submit" name="submit" id="submit" className="btn btn-theme w-100 mb-3" value="Search" />
                </div>
            </div>
        </form>
        <div className="row clearfix">
          <SquareBoxCenterName 
          sendnewSearchedDataToSearchResult={sendnewSearchedDataToSearchResult}
          />
        </div>
        <div className="row mt-4 mb-4 clearfix">
            <div className="col-12 col-xl-9">
                <h3 className="search-result">{bannerSearchedData.length} pet days centres near you</h3>
            </div>
            <div className="col-12 col-xl-3">
                <h3 className="search-result">Other Related Centers</h3>
            </div>
        </div>
        <div className="row clearfix">
            <div className="col-12 col-xl-9">
                <div className="d-flex">
                    <div className="filterCheckbox">
                        <label>
                           <input type="checkbox" value="pawsured" 
                           onClick={changePawassuredList}/><span>Pawsured</span>
                        </label>
                     </div>
                     <div className="filterCheckbox">
                        <label>
                           <input 
                           onClick={changeDistanceList}
                           type="checkbox" value="distance" /><span>Distance</span>
                        </label>
                     </div>
                     <select className="form-select w-25 ms-2" name="ratings" id="ratings"
                     >
                        <option >Ratings</option>
                        {
                            ratingsValue.map((rat) => {
                                return (
                                <option key={rat}  value={rat}>{rat}</option>
                                )
                            })
                        }
                        {/* <option defaultValue="1" >1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option> */}
                     </select>
                </div>
                <ul className="search-result clearfix">
                  { bannerSearchedData.map((item, index) => {
                    return (
                        <li key={index}  >
                        <div 
                        className="search-items" 
                        >
                            <img src="images/pawsitive-logo.jpg" alt="Image goes here" 
                            onClick={ () => showProviderDetails(item.users.providers.id)}
                            />
                            <div className="info" >
                                <h4 onClick={ () => showProviderDetails(item.users.providers.id)}>{item.users.providers.store_name}
                                 {/* <span>Rs.{item.price}</span> */}
                                 </h4>
                                <div className="reviews">
                                <DynamicStar 
                          width= {15}
                          height= {15}
                          rating={item.users.providers.provider_rating} emptyStarColor={5-item.users.providers.provider_rating} />
                                    ({item.users.providers.provider_rating_count})
                                </div>
                                <div className="row clearfix">
                                    <div className="col-12 col-lg-9 col-xl-9">
                                        <ul className="other-info">
                                            <li><i className="fa fa-map-marker"></i> {item.users.city}</li>
                                            <li><i className="fa fa-map-signs"></i> {(item.kms/1000).toFixed(2)}+kms away</li>
                                            <li><i className="fa fa-clock"></i> 
                                            <a href="#" data-bs-toggle="modal" 
                        data-bs-target="#viewWorkingHoursModals"
                        >View Hours</a>
                        {
                            workingHours.map((hours, index) => {
                                return (
                                    <WorkingHoursModal 
                                    key={hours.id}
                                    hours={hours.users.providers.provider_working_hours}/>
                                )
                            })
                        }
                        </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-lg-3 col-xl-3 align-text-bottom">
                                        <div className="amenities">Amenities:

                                         <span>{amenities.join()}</span></div>
                                    </div>
                                </div>                                        
                            </div>
                        </div>
                    </li>)
                  })}  
                     

                </ul>
                {/* <nav className="shadow-none" aria-label="Page navigation">
                            <ul className="pagination justify-content-center border-0 pagination-lg">
                              <li className="page-item disabled">
                                <a className="page-link" href="#" aria-disabled="true"><i className="fa fa-angle-left"></i></a>
                              </li>
                              <li className="page-item"><a className="page-link active" aria-current="page" href="#">1</a></li>
                              <li className="page-item"><a className="page-link" href="#">2</a></li>
                              <li className="page-item"><a className="page-link" href="#">3</a></li>
                              <li className="page-item">
                                <a className="page-link" href="#"><i className="fa fa-angle-right"></i></a>
                              </li>
                            </ul>
                        </nav> */}
                        </div>
                        {/* <GoogleMap 
       style={{height: "400px", width: "400px", position: "relative"}}
// lat={coordinates.lat} long={coordinates.lng}
>
<AnyReactComponent
            lat={lat}
            lng={long}
            text="My Marker"
          />

</GoogleMap> */}
            <div className="col-12 col-xl-3 border-start">
            <SideBarRandomResult />

            
            </div>
        </div>
    </div>

</section>

</>
  )
}

export default SearchResult