import React,{useState} from 'react'
import { API_URL } from "../../constant";
import axios from "axios";
import {useGoogleLogin} from '@react-oauth/google';
import jwt_decode from "jwt-decode";

// import InputBox from '../UI Components/InputBox'
// import InputBoxData from '../UI Components/InputBoxData'
export const RightSide = () => {
  
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [pass, setPass] = useState()
    const [conPass, setConPass] = useState()
    const [oldPass, setOldPass] = useState()
    const [passwordNotMatchedStatus, setPasswordNotMatchedStatus] = useState(false)
//  const inputBoxNames = InputBoxData;
const [formValues, setFormValues] = useState({});
const [signupMsg, setsignupMsg] = useState("")
const [ CheckboxNotChecked,setCheckboxNotChecked] = useState("");
const [passwordError, setPasswordErr] = useState("");

const [isSubscribed, setIsSubscribed] = useState(false);
const [ emailExist, setEmailExist] = useState([])
const msgStyle = {
    color: "red",
    backgroundColor: "Transparent",
    margin: "10px"
}




  const passwordValidation = () => {
    // setPasswordMatchedStatus(false)
    // setPasswordNotMatchedStatus(false)
    let pass  = formValues.password;
    let conPass = formValues.confirmPassword
    const passwordInputValue = pass.trim();

    const uppercaseRegExp   = /(?=.*?[A-Z])/;
    const lowercaseRegExp   = /(?=.*?[a-z])/;
    const digitsRegExp      = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp   = /.{6,}/;

    const passwordLength =      pass.length;
console.log(passwordLength)
console.log(conPass.length)

    const uppercasePassword =   uppercaseRegExp.test(passwordInputValue);
    const lowercasePassword =   lowercaseRegExp.test(passwordInputValue);
    const digitsPassword =      digitsRegExp.test(passwordInputValue);
    const specialCharPassword = specialCharRegExp.test(passwordInputValue);
    const minLengthPassword =   minLengthRegExp.test(passwordInputValue);
    let errMsg ="";

    if(passwordLength === conPass.length){
     if(pass === conPass)
     {
    if(passwordLength===0){
            errMsg="Password is empty";
    }else if(!uppercasePassword){
            errMsg="At least one Uppercase";
    }else if(!digitsPassword){
            errMsg="At least one digit";
    }else if(!specialCharPassword){
            errMsg="At least one Special Characters";
    }else if(!minLengthPassword){
            errMsg="At least minumum 6 characters";
    }else{
        errMsg="";
    }
} else {
    setPasswordNotMatchedStatus(true)
}
} else {
        setPasswordNotMatchedStatus("Password is not Matched")
    }
   
     setPasswordErr(errMsg);

  }
  const passwordStoring = (e) => {
    // console.log(e.target.value)
  }
const handleCheckedBoxEvent = (e) => {
    // console.log(e.target.checked)
    setCheckboxNotChecked("")
    setIsSubscribed(e.target.checked)
}

const handleChange = (e) => {
    // console.log(formValues)
    setFormValues({ ...formValues, [e.target.id]: e.target.value });

  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formValues.confirmPassword.length)
    passwordValidation();
    isSubscribed ? 
    axios({
        method: "post",
        url: API_URL + "user/sign_up",
        
        headers: {
            'Accept': 'application/json',
          },
          data: {
            first_name: formValues.firstName,
            last_name: formValues.lastName,
          email: formValues.email,
          phone: formValues.phone,
          city: formValues.city,
          pin_code: formValues.pinCode,
          password: formValues.password
        },
      }).then((response) => {
        console.log(response)
        if (response.data.success === true) {
          //console.log(response.data.msg);
          setsignupMsg(response.data.mesg)
          setCheckboxNotChecked("")
        } else if (response.data.success === false)
        setsignupMsg(response.data.msg)
         //console.log(response.data.msg);
         setCheckboxNotChecked("")

      }).catch(error => {
        setEmailExist(error.response.data.errors.email)
            console.log(error.response.data.errors.email)
            setCheckboxNotChecked("")
      }) :       setCheckboxNotChecked("⛔️ Checkbox is NOT checked")

  };
  const googleLogin = useGoogleLogin({
    onSuccess: async respose => {
        try {
            const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
                headers: {
                    "Authorization": `Bearer ${respose.access_token}`
                }
            })

            console.log(res.data)
            const decoded_user= res.data
            axios({
              method: "post",
              url: API_URL + "user/socialite",
              data: {
                first_name: decoded_user.given_name,
                last_name: decoded_user.family_name,
                email: decoded_user.email,
                socialite_id: decoded_user.sub,
                address: 'ss'
              },
            }).then((response) => {
              if (response.data.success === true) {
                setIsLoggedIn(true);
                // navigate("/dashboard");
                console.log(response);
              } else if (response.data.success === false) alert(response.data.msg);
            });
        } catch (err) {
            console.log(err)
            alert(err)

        }

    }
});
  return (
    <>
    <div className="col-12 col-lg-6 col-xl-6">
    <div className="d-block box-shadow">
        <div className="signup-body">
            <div className="row gx-3 mt-2 clearfix">
                <div className="col-12 col-lg-6 col-xl-6">
                    <a href="#" className="fb-btn mb-3">
                      <i className="fa fa-facebook">
                        </i> Continue with Facebook</a>
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                    <button href="#" className="g-btn mb-3"
                    style={{width: "293px", height: "42px"}}
                    onClick={googleLogin}>
                      <i className="fa fa-google">
                        </i> Continue with Google</button>
                </div>
            </div>
        </div>
        <div className="divider"></div>
        <div className="signup-body clearfix">
            <form 
            onSubmit={handleSubmit} 
            id="signup" role="form">
                <legend>Personal Details</legend>
                <div className="row clearfix">
                    <div className="col-12 col-xl-6">
                        <div className="row gx-1 clearfix">
                            <div className="col-4">
                                <select className="form-select" id="inputGroupSelect02">
                                    <option defaultValue="Mr.">Mr.</option>
                                    <option value="Mrs.">Mrs.</option>
                                    <option value="Ms.">Ms.</option>
                                </select>
                            </div>
                            <div className="col-8">
                                <input type="text" id="firstName"  
                                className="form-control mb-3" 
                                placeholder="First Name*" 
                                value={formValues.fname }
                                onChange={handleChange}
                                maxLength="15"
                                required />
                            </div>
                        </div>                                            
                    </div>
                    {/* {
                        inputBoxNames.map((item) => {
                            return (
                                <div className="col-12 col-xl-6" key={item.id}>
                            <InputBox 
                           placeholder={item.placeholder}
                           name={item.name}
                           id={item.id}
                           type={item.type}
                            />
                              </div>
                            )
                        })
                    } */}
                                
                  
                    <div className="col-12 col-xl-6">
                        <input type="text" id="lastName" 
                        className="form-control mb-3" 
                        placeholder="Last Name*"
                        value={formValues.lname}
                        onChange={handleChange}
                        maxLength="15"
                        required />
                    </div>
                    <div className="col-12 col-xl-6">
                        <input type="email" id="email" 
                         className="form-control mb-3" 
                         placeholder="Email Address*"
                         value={formValues.email }
                         onChange={handleChange}
                         onClick={(e) => setEmailExist("")}
                         maxLength="50"
                         required />
                        <span style={msgStyle}>{emailExist}</span>

                    </div>
                    <div className="col-12 col-xl-6">
                        <input type="tel" id="phone" 
                         className="form-control mb-3" 
                         placeholder="Phone Number*" 
                         value={formValues.phone }
                         onChange={handleChange}
                         maxLength="10"
                         required />
                    </div>
                    <div className="col-12 col-xl-6">
                        <input type="password" id="password" 
                         className="form-control mb-3" 
                         placeholder="Password*" 
                         value={formValues.pass }
                         onChange={handleChange}
                         maxLength="10"
                         required />
                         
                    </div>
                    
                    <div className="col-12 col-xl-6">
                        <input type="password" id="confirmPassword" 
                         className="form-control mb-3" 
                         placeholder="Confirm Password*"
                         value={formValues.cPass }
                         onChange={handleChange}
                         onClick={(e) => setPasswordNotMatchedStatus("")}
                         maxLength="10"
                          required />
                          <p style ={{color: "red"}}>{passwordError}</p>
                          { passwordNotMatchedStatus && 
              <span style ={{color: "red"}}>Password is not Matched</span>
            }
                    </div>
                    <div className="col-12 col-xl-6">
                        <input type="text" id="city"  
                        className="form-control mb-3"
                        value={formValues.city }
                        onChange={handleChange}
                        maxLength="15"
                         placeholder="City" required />
                    </div>
                    <div className="col-12 col-xl-6">
                        <input type="number" id="pinCode"  
                        className="form-control mb-3" 
                        value={formValues.pin }
                        onChange={handleChange}
                        maxLength="7"
                        placeholder="Pin Code" required />
                    </div>
                    <div className="col-12 col-xl-12">
                        <div className="form-check mb-3">
                            <input 
                             onChange={handleCheckedBoxEvent}
                             name="terms"
                             value={isSubscribed}
                            className="form-check-input" type="checkbox"  id="agreeTerms" />
                            <label className="form-check-label" htmlFor="agreeTerms">
                                By registering with us you agree to our 
                                <a href="#">Privacy Policy</a> and <a href="#">Terms & Conditions</a>
                            </label>
                            <label style={{
                                color: "red",
                                backgroundColor: "Transparent",
                                margin: "10px"
                            }}>{signupMsg}</label>


                            <label>{CheckboxNotChecked}</label>
                        </div>
                    </div>
                    <div className="col-12 col-xl-12">
                        <input  
                        type="submit" name="submit" id="submit" value="Create Account" className="btn btn-theme fw-semibold w-100 mb-3" />
                    </div>
                    <div className="col-12 col-xl-12 text-center">
                        <p>Already a User? 
                            <a href="#" data-bs-toggle="modal" data-bs-target="#login">
                                Sign in</a></p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</>
  )
}
export default RightSide;
