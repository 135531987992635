import React, { useState, useReducer,useEffect } from 'react'
import { INITIAL_STATE, searchedDataReducer} from "../../reducers/search";
import { API_URL,IMG_URL } from "../../constant";
import characterSearch from '../../hooks/CharacterSearch';
import axios from "axios";

const SquareBoxCenterName = () => {
  const [state, dispatch] = useReducer(searchedDataReducer, INITIAL_STATE)
  const [categories, setCategories] = useState([])
  const [userLongitude, setUserLongitude] = useState();
  const [userLatitude, setUserLatitude] = useState();
  const [newSearchedData, setNewSearchedData] = useState([])
  useEffect(() => {
    setCategories(JSON.parse(window.localStorage.getItem('categories')));

    // console.log(categories)
  },[])
  

  useEffect(() => {
    let lat =    JSON.parse(window.localStorage.getItem("lat"));
    setUserLongitude(lat)
    let long = JSON.parse(window.localStorage.getItem("long"))
    setUserLatitude(long)
    
      },[])

      function sendnewSearchedDataToSearchResult() {
       return  newSearchedData
      }

      useEffect(() => {
        sendnewSearchedDataToSearchResult();
      },[newSearchedData])

      const searchResult = async (mainId) => {
        // console.log(id);
        await axios({
          url: API_URL + "search",
          method: "POST",
          headers: { "content-Type": "application/json/x-www-form-urlencoded" },
          data: { 
            main_category_id: mainId
        },
        })
          .then((response) => {
            
            let len = response.data.ProviderService.length;
            console.log(response.data.ProviderService.length)
              //   console.log(response.data.ProviderService.length);
              //   console.log(response.data.ProviderService);
              // console.log(response)
              if(response.data.success == true){
                // console.log(response.data)
                localStorage.setItem("searchedData", JSON.stringify(response.data.ProviderService));
                setNewSearchedData(response.data.ProviderService)
              } 
      //           if(response.data.ProviderService.length !== 0)
      //           {
      // localStorage.setItem("searchedData", JSON.stringify(response.data.ProviderService));
      // setNewSearchedData(response.data.ProviderService)
      //           } else {
      //               alert("No Data Found")
      //           }
          })
          .catch((error) => {
            console.log(error);
          });
      };
    

  const searchByCatName = async (event) => {
    event.preventDefault();
    searchResult(event.target.id);

  };

  return (
   
            <div className="col-12">
                <ul className="services clearfix">
                  { categories.map((center) => (
                     <li key={center.id}    onClick={searchByCatName}>
                       <a >
                           <img  src={IMG_URL+center.image} 
                            id={center.id}
                           alt={center.main_category_name}/>
                           <p  id={center.id}
                           onClick={searchByCatName}
                           >{center.main_category_name}</p>
                       </a>
                   </li>
                     ))}
               </ul>
            </div>
  )
}

export default SquareBoxCenterName