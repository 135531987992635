import React from 'react'
// import Carousel from 'react-bootstrap/Carousel';


const UsersFeedback = () => {
  return (
    <>
   <section className="bg-fff8f6">
            <div className="container">
                <div className="row clearfix">
                    <div className="col-12"><h3>Some of Our Happy Pawfive Users</h3></div>
                    <div className="col-12">
                        <div id="testimonials" className="carousel slide w-80 mx-auto" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="client-box">
                                        <div className="row clearfix">
                                            <div className="col-12 col-lg-4 col-xl-4">
                                                <div className="outer-circle">
                                                    <img src="images/test-img.jpg" alt="Image goes here" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-7 col-xl-7 offset-lg-1">
                                                <div className="test-content">
                                                    <div className="name">Akash Gupta</div>
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since</p>
                                                </div>
                                            </div>
                                        </div>                                       
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="client-box">
                                        <div className="row clearfix">
                                            <div className="col-12 col-lg-4 col-xl-4">
                                                <div className="outer-circle">
                                                    <img src="images/test-img.jpg" alt="Image goes here" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-7 col-xl-7 offset-lg-1">
                                                <div className="test-content">
                                                    <div className="name">Akash Gupta</div>
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since</p>
                                                </div>
                                            </div>
                                        </div>                                       
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="client-box">
                                        <div className="row clearfix">
                                            <div className="col-12 col-lg-4 col-xl-4">
                                                <div className="outer-circle">
                                                    <img src="images/test-img.jpg" alt="Image goes here" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-7 col-xl-7 offset-lg-1">
                                                <div className="test-content">
                                                    <div className="name">Akash Gupta</div>
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since</p>
                                                </div>
                                            </div>
                                        </div>                                       
                                    </div>
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" 
                           
                            data-bs-target="#testimonials" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" 
                            
                            type="button" data-bs-target="#testimonials" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>
  )
}

export default UsersFeedback