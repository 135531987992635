import React from 'react'

const Help = () => {
  return (
    <div className="col-12 col-md-9 col-lg-8 col-xl-8">
    <div className="dashboard-title mb-2">Help</div>
    <div className="d-block box-shadow p-3 clearfix">
        <form method="post" action="" id="manage-password" role="form">
            <div className="row mb-3 clearfix">
                <div className="col-12">
                    <textarea className="form-control" name="help" id="help" placeholder="What's your Query..." rows="12"></textarea>
                </div>
            </div>
            <input type="submit" id="submit" name="sudmit" className="btn btn-theme float-end" value="submit" />
        </form>
    </div>
</div>
  )
}

export default Help