import React, { useEffect,useState} from 'react'
import { API_URL,IMG_URL } from "../constant";
import { useDispatch} from 'react-redux'
import { storeAmenitiesForSelectedServiceAction } from "../redux/actions/action";

const amenitiesAPIURL = API_URL+'service/sub-categories/'


const FetchAmenities = (id) => {
  const [data, setData] = useState([]);
  const [amenitiesData, setAmenitiesData] = useState([])
  const dispatch = useDispatch();

// useEffect(() => {
//   setAmenitiesData({...amenitiesData, data})
//   // console.log(data)
// },[data])

useEffect(() => {
  // console.log("ame")
  // console.log(amenitiesData)
  // console.log(amenitiesData[1])
  // console.log(amenitiesData[2])
  // console.log(amenitiesData[3])

},[amenitiesData])

useEffect(() => {
  // if(d){
  //    d = [...amenitiesData]
  // }
  // console.log(id)
 
  fetch(amenitiesAPIURL+id, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((data) => 
// console.log("hoks am data", data.data))
 //d.push([id]:data.data),
 dispatch(storeAmenitiesForSelectedServiceAction(data.data)))
//setData(data.data),
// {
// if(amenitiesData.length ===0){
//   setAmenitiesData({[id]: data.data})
//   console.log("data", data)

// }else {
// setAmenitiesData({...amenitiesData, [id]: data.data})

// }
// }

    // );
}, [id]);
return [amenitiesData];
};

// useEffect(() => {
//   fetch(amenitiesAPIURL+id, {
//     method: 'POST',
//     headers: {
//       'Accept': 'application/json',
//     },
//   })
//     .then((res) => res.json())
//     .then((data) => 
//     // setData(data.data)
//       //console.log(data.data)
//       setData(data.data)
//     );
//     return [data];

// },[id])

//}
        export default FetchAmenities;
