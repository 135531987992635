import React, { useState} from "react";
import { API_URL, IMG_URL } from "../../constant";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ManagePassword = () => {
    const userData = JSON.parse(window.localStorage.getItem("paw5-login-data"));
    const [pass, setPass] = useState()
    const [conPass, setConPass] = useState()
    const [oldPass, setOldPass] = useState()
    const [passwordNotMatchedStatus, setPasswordNotMatchedStatus] = useState(false)
    const [passwordMatchedStatus, setPasswordMatchedStatus] = useState(false)
    const [passwordError, setPasswordErr] = useState("");
    const navigate = useNavigate();

  const changePasswordHandler = (e) => {
    e.preventDefault();
    passwordValidation();
console.log(userData.token)
console.log(oldPass)
console.log(pass)

   
  };

  const sendPassword = () => {
    axios({
        method: "post",
        url: API_URL + "user/change_password",
        headers: {
          Accept: "application/json",
          Authorization:
          "Bearer " + userData.token,
        },
        data: {
          old_password: oldPass,
           new_password: pass,
        },
      }).then((response) => {
        console.log(response);
        if (response.data.success == true) {
          alert("Password is changed ");
                  navigate("/profile");

        }
      });
  }

  const passwordValidation = () => {
    // setPasswordMatchedStatus(false)
    // setPasswordNotMatchedStatus(false)

    const passwordInputValue = pass.trim();

    const uppercaseRegExp   = /(?=.*?[A-Z])/;
    const lowercaseRegExp   = /(?=.*?[a-z])/;
    const digitsRegExp      = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp   = /.{6,}/;

    const passwordLength =      pass.length;

    const uppercasePassword =   uppercaseRegExp.test(passwordInputValue);
    const lowercasePassword =   lowercaseRegExp.test(passwordInputValue);
    const digitsPassword =      digitsRegExp.test(passwordInputValue);
    const specialCharPassword = specialCharRegExp.test(passwordInputValue);
    const minLengthPassword =   minLengthRegExp.test(passwordInputValue);
    let errMsg ="";

    if(passwordLength === conPass.length){
     if(pass === conPass)
     {
    if(passwordLength===0){
            errMsg="Password is empty";
    }else if(!uppercasePassword){
            errMsg="At least one Uppercase";
    }else if(!lowercasePassword){
            errMsg="At least one Lowercase";
    }else if(!digitsPassword){
            errMsg="At least one digit";
    }else if(!specialCharPassword){
            errMsg="At least one Special Characters";
    }else if(!minLengthPassword){
            errMsg="At least minumum 6 characters";
    }else{
        errMsg="";
    }
} else {
    setPasswordNotMatchedStatus(true)
}
} else {
        setPasswordNotMatchedStatus(true)
    }
    if(errMsg == "" && passwordNotMatchedStatus == false)
    {
        sendPassword();
    }
     setPasswordErr(errMsg);

  }
  const passwordStoring = (e) => {
    console.log(e.target.value)
  }
  return (
    <div className="col-12 col-md-9 col-lg-8 col-xl-8">
      <div className="dashboard-title mb-2">Manage Password</div>
      <div className="d-block box-shadow p-3 clearfix">
        <form  id="manage-password" role="form">
          <div className="row mb-3 clearfix">
            <div className="col-12 col-lg-4">
              <input
                type="password"
                name="curretPassword"
                id="currentPassword"
                className="form-control"
                placeholder="Current Password"
                onChange={ (e) => setOldPass(e.target.value)}

              />
            </div>
            <div className="col-12 col-lg-4">
              <input
                type="password"
                name="newPassword"
                id="newPassword"
                className="form-control"
                placeholder="New Password"
                onChange={ (e) => {setPasswordNotMatchedStatus(false); setPasswordErr(); setPass(e.target.value)}}
              />
            </div>
            <div className="col-12 col-lg-4">
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                className="form-control"
                placeholder="Confirm Password"
                onChange={(e) => {setPasswordNotMatchedStatus(false);setPasswordErr();
                     setConPass(e.target.value)}}
              />
            <p style ={{color: "red"}}>{passwordError}</p>
              { passwordNotMatchedStatus && 
              <span style ={{color: "red"}}>Password is not Matched</span>
            }
              {/* {
                passwordMatchedStatus && 
                <span style ={{color: "green"}}>Password is Matched</span>
              } */}
              

            </div>
          </div>
          <input
            type="submit"
            id="submit"
            name="sudmit"
            className="btn btn-theme float-end"
            onClick={ changePasswordHandler}
            value="Change Password"
          />
        </form>
      </div>
    </div>
  );
};

export default ManagePassword;
