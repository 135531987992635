import React from 'react'

const MyOrders = () => {
  return (
    <>
    <div className="col-12 col-md-9 col-lg-8 col-xl-8">
                        <div className="dashboard-title">My Orders</div>
                        <div className="d-block box-shadow p-4 mt-3 mb-3">
                            <ul className="nav nav-pills nav-justified mb-3" id="orders-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                  <button className="nav-link active" id="pills-upcoming" data-bs-toggle="pill" data-bs-target="#upcoming" type="button" role="tab" aria-controls="pills-upcoming" aria-selected="true">Upcoming</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                  <button className="nav-link" id="pills-completed" data-bs-toggle="pill" data-bs-target="#completed" type="button" role="tab" aria-controls="pills-completed" aria-selected="false">Completed</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="upcoming" role="tabpanel" aria-labelledby="pills-upcoming" tabIndex="0">
                                    <table cellSpacing="0" cellPadding="0" className="table orders align-middle table-borderless">
                                        <thead>
                                            <tr>
                                                <th>Service Provider</th>
                                                <th>Booking Date</th>
                                                <th>Amount</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <img src="images/pawsitive-logo.jpg" alt="Image goes here" /> Pawsitive Service
                                                </td>
                                                <td>4/12/2022</td>
                                                <td><i className="bi bi-currency-rupee"></i> 699</td>
                                                <td><a href="#" data-bs-toggle="modal" data-bs-target="#orderdetails" title="View Details"><i className="bi bi-eye-fill"></i></a> <a href="#" title="Send Message"><i className="bi bi-send-check-fill"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img src="images/pawsitive-logo.jpg" alt="Image goes here" /> Pawsitive Service
                                                </td>
                                                <td>4/12/2022</td>
                                                <td><i className="bi bi-currency-rupee"></i> 699</td>
                                                <td><a href="#" data-bs-toggle="modal" data-bs-target="#orderdetails" title="View Details"><i className="bi bi-eye-fill"></i></a> <a href="#" title="Send Message"><i className="bi bi-send-check-fill"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img src="images/pawsitive-logo.jpg" alt="Image goes here" /> Pawsitive Service
                                                </td>
                                                <td>4/12/2022</td>
                                                <td><i className="bi bi-currency-rupee"></i> 699</td>
                                                <td><a href="#" data-bs-toggle="modal" data-bs-target="#orderdetails" title="View Details"><i className="bi bi-eye-fill"></i></a> <a href="#" title="Send Message"><i className="bi bi-send-check-fill"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img src="images/pawsitive-logo.jpg" alt="Image goes here" /> Pawsitive Service
                                                </td>
                                                <td>4/12/2022</td>
                                                <td><i className="bi bi-currency-rupee"></i> 699</td>
                                                <td><a href="#" data-bs-toggle="modal" data-bs-target="#orderdetails" title="View Details"><i className="bi bi-eye-fill"></i></a> <a href="#" title="Send Message"><i className="bi bi-send-check-fill"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img src="images/pawsitive-logo.jpg" alt="Image goes here" /> Pawsitive Service
                                                </td>
                                                <td>4/12/2022</td>
                                                <td><i className="bi bi-currency-rupee"></i> 699</td>
                                                <td><a href="#" data-bs-toggle="modal" data-bs-target="#orderdetails" title="View Details"><i className="bi bi-eye-fill"></i></a> <a href="#" title="Send Message"><i className="bi bi-send-check-fill"></i></a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="tab-pane fade" id="completed" role="tabpanel" aria-labelledby="pills-completed" tabIndex="0">
                                    <table cellSpacing="0" cellPadding="0" className="table orders align-middle table-borderless">
                                        <thead>
                                            <tr>
                                                <th>Service Provider</th>
                                                <th>Booking Date</th>
                                                <th>Amount</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <img src="images/pawsitive-logo.jpg" alt="Image goes here" /> Pawsitive Service
                                                </td>
                                                <td>4/12/2022</td>
                                                <td><i className="bi bi-currency-rupee"></i> 699</td>
                                                <td><a href="#" data-bs-toggle="modal" data-bs-target="#orderdetails" title="View Details"><i className="bi bi-eye-fill"></i></a> <a href="#" title="Send Message"><i className="bi bi-send-check-fill"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img src="images/pawsitive-logo.jpg" alt="Image goes here" /> Pawsitive Service
                                                </td>
                                                <td>4/12/2022</td>
                                                <td><i className="bi bi-currency-rupee"></i> 699</td>
                                                <td><a href="#" data-bs-toggle="modal" data-bs-target="#orderdetails" title="View Details"><i className="bi bi-eye-fill"></i></a> <a href="#" title="Send Message"><i className="bi bi-send-check-fill"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img src="images/pawsitive-logo.jpg" alt="Image goes here" /> Pawsitive Service
                                                </td>
                                                <td>4/12/2022</td>
                                                <td><i className="bi bi-currency-rupee"></i> 699</td>
                                                <td><a href="#" data-bs-toggle="modal" data-bs-target="#orderdetails" title="View Details"><i className="bi bi-eye-fill"></i></a> <a href="#" title="Send Message"><i className="bi bi-send-check-fill"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img src="images/pawsitive-logo.jpg" alt="Image goes here" /> Pawsitive Service
                                                </td>
                                                <td>4/12/2022</td>
                                                <td><i className="bi bi-currency-rupee"></i> 699</td>
                                                <td><a href="#" data-bs-toggle="modal" data-bs-target="#orderdetails" title="View Details"><i className="bi bi-eye-fill"></i></a> <a href="#" title="Send Message"><i className="bi bi-send-check-fill"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img src="images/pawsitive-logo.jpg" alt="Image goes here" /> Pawsitive Service
                                                </td>
                                                <td>4/12/2022</td>
                                                <td><i className="bi bi-currency-rupee"></i> 699</td>
                                                <td><a href="#" data-bs-toggle="modal" data-bs-target="#orderdetails" title="View Details"><i className="bi bi-eye-fill"></i></a> <a href="#" title="Send Message"><i className="bi bi-send-check-fill"></i></a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
  )
}

export default MyOrders