import React from 'react'

const SideBarRandomResult = () => {
  return (
    <>

        <ul className="related-centres">
            <li>
                <img src="images/img-4.jpg" alt="Image goes here" />
                <div className="info">
                    <h4><a href="#">4 Leg Care</a></h4>
                    <div className="review">
                        <i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star-o"></i>
                        (12k+)
                    </div>
                </div>
            </li>
            <li>
                <img src="images/img-4.jpg" alt="Image goes here" />
                <div className="info">
                    <h4><a href="#">4 Leg Care</a></h4>
                    <div className="review">
                        <i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star-o"></i>
                        (12k+)
                    </div>
                </div>
            </li>
            <li>
                <img src="images/img-4.jpg" alt="Image goes here" />
                <div className="info">
                    <h4><a href="#">4 Leg Care</a></h4>
                    <div className="review">
                        <i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star-o"></i>
                        (12k+)
                    </div>
                </div>
            </li>
            <li>
                <img src="images/img-4.jpg" alt="Image goes here" />
                <div className="info">
                    <h4><a href="#">4 Leg Care</a></h4>
                    <div className="review">
                        <i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star-o"></i>
                        (12k+)
                    </div>
                </div>
            </li>
            <li>
                <img src="images/img-4.jpg" alt="Image goes here" />
                <div className="info">
                    <h4><a href="#">4 Leg Care</a></h4>
                    <div className="review">
                        <i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star-o"></i>
                        (12k+)
                    </div>
                </div>
            </li>
        </ul>

</>
  )
}

export default SideBarRandomResult