import React, {useEffect, useState} from "react";
import { useSelector} from 'react-redux';
import { useDispatch} from 'react-redux'
import { API_URL,IMG_URL } from "../../constant";
import { profileImage } from "../../redux/actions/action";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";
import { storingUserData } from "../../redux/actions/action";

const ProviderSideMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [ userData, setuserData] = useState(JSON.parse(window.localStorage.getItem("paw5-login-data")))
  const [ openServicesComponent, setOpenServicesComponent] = useState(false)
  const [ isProfileComponentOpen, setisProfileComponentOpen] = useState(true)
  const [profileLogo, setProfileLogo] = useState()
  const profileImageRedux = useSelector((state) =>state.profileImage )
  const [open, setOpen] = useState(false);
  const [profile64Image, setProfile64Image] = useState([])
  const [ activeProfile, setActiveProfile] = useState(false)
  const [ activeServices, setActiveServices] = useState(false)
  const [ activeOrders, setActiveOrders] = useState(false)

  // const callSnackBar = () => {
  //   return (
  //     <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
  //       <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
  //         This is a success message!
  //       </Alert>
  //     </Snackbar>
  //   )
  // }


//  useEffect(() => {
// console.log(activeServices)
// console.log(activeProfile)

//  },[activeProfile,activeServices ])
  const handleImage = (e) => {
    // console.log(e.target.files)
    const selectedImage = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(selectedImage[0]);
    reader.onload = (e) => {
      // console.log(e.target.result)
      handlerProfileImage(e.target.result)
      setProfile64Image(e.target.result);
    };
  };
  
  const handlerProfileImage =  (base64code) => {
    fetch(API_URL+'user/profile-image',{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer '+ userData.token,
        }, 
        body: JSON.stringify({image: base64code}),
    }).then((res) => res.json())
    .then((data) =>
    {
      console.log(data)
      if(data.success == true) {
        setOpen(true)
        dispatch(profileImage(data.image_path))
        userData.data.image= data.image_path
    localStorage.setItem(
      "paw5-login-data",
      JSON.stringify(userData)

    );
        // callSnackBar();
        //alert("Image is updated")
  
      } else { alert("Something is wrong")}
  
    }
    )
    .catch((err) => console.log(err))
  }

  useEffect(() => {
    // console.log(profileImage)
    if (profileImageRedux.image)
    { 
    setProfileLogo(profileImageRedux.image)
    } else if(userData.data.image){
      setProfileLogo(userData.data.image)
    }
    else {
    //   console.log("none")
      setProfileLogo("images/user.jpg")
    }
    // setuserLocalStorageData(userData)
},[profileImageRedux])

const handleImageButtonForImage = (e) => {
  let element = document.getElementById("uploadFile");
  element.click();
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setOpen(false);
  };

  
  useEffect(() => {
    if(window.localStorage.getItem("paw5-login-data")){
        setuserData(JSON.parse(window.localStorage.getItem("paw5-login-data")));
        // setuserProfilePic(userData.data.image)
    } 
    localStorage.removeItem("amenitiesSelectedService");
    localStorage.removeItem("selectedServicesForAdd");

},[])

const openServices = () => {

  setActiveServices(true)
  setActiveProfile(false)
  navigate('/services')

}

const opeProfile = () => {

  setActiveProfile(true)
  setActiveServices(false)
  navigate('/p-profile')

}



  return (
 
        
    <div className="col-12 col-md-3 col-lg-4 col-xl-4">
    <aside className="box-shadow">
        <div className="user-header">
            <div className="user-pic">
            <img src=   {profileLogo  ? IMG_URL+profileLogo : "images/user.jpg" } 
                    alt="Image goes here"
                  />                  
                  <input  id = "uploadFile" 
              type="file" 
              onChange={handleImage}
              name="img"
              accept="image/*"
              style={{display: "none"}}
             />
              <button  
             className="edit"
             onClick={handleImageButtonForImage}>
             <i className="fa fa-pencil"></i>
             </button>            </div>
             <div className="username mt-3">{userData.data.first_name + ' '+ userData.data.last_name}</div>
        </div>
        <Snackbar open={open} autoHideDuration={999} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        Image is Updated
      </Alert>
    </Snackbar>
        <div className="user-body">
            <ul className="nav nav-pills flex-column sidenav">
                <li className="nav-item">
                <a
                      className={ activeProfile ?  "nav-link active" : "nav-link"}
                      aria-current="page"
                      onClick={opeProfile}
                    >
                    <i className="bi bi-person-fill"></i> Profile</a>
                </li>
                <li className="nav-item">
                <a className= { activeServices ?  "nav-link active" : "nav-link"} onClick={ openServices}>
                      <i className="bi bi-clipboard2-check-fill"></i> My Services
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" >
                      <i className="bi bi-clipboard-fill"></i> Orders
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" >
                      <i className="bi bi-chat-right-text-fill"></i> Inbox
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" >
                      <i className="bi bi-stars"></i> Reviews
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" >
                      <i className="bi bi-key-fill"></i> Manage Password
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" >
                      <i className="bi bi-question-circle-fill" ></i> Help
                    </a>
                  </li>
            </ul>
        </div>
    </aside>
</div>
  )
}

export default ProviderSideMenu