import React, {useState} from 'react'
import { useNavigate } from "react-router-dom";
import Step2 from './Step2'
import { API_URL, IMG_URL } from "../../constant";
// import Categories from '../../hooks/CategoriesFetch'

const Step1 = () => {
    const [hideShowStep1, sethideShowStep1] = useState(true)
    // const url = API_URL+'search/main-category-name'
    // const [data] = Categories(url);
    const navigate = useNavigate(); 
    const nextStep = () => {
        navigate("/step2")
        // sethideShowStep1(false)
    }
    // console.log(data)

  return (
    <>
     {/* { */}
          {/* hideShowStep1 && ( */}
            <section className="p-0">
            <div className="container-fluid">
                <div className="row clearfix">
                    <div className="col-6 p-0">
                        <div className="sp-bg" style={{background: `url('/images/sp-bg-1.jpg')`,  
                        backgroundRepeat: 'no-repeat', backgroundSize: "auto"}}>
                        </div>
                    </div>
                    <div className="col-6 p-0">
                        <div className="sp-content">
                            <a  className="btn btn-transparent btn-back"
                            onClick ={ () =>    {     navigate(-1);}}
                            ><i className="bi bi-arrow-left"></i> Back</a>
                            <div className="sp-content-box">
                                <h1>Become a Service Provider in Simple Easy Steps
                                    <span>Join us. We'll help you every step of</span></h1>
                                <a onClick={ nextStep} className="btn btn-theme btn-lg">Start
                                 <i className="bi bi-arrow-right-short"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
          {/* ) */}
         {/* } */}
    {/* {
        !hideShowStep1 && (
            <Step2 data={data} />
        )
    } */}
        
        </>
  )
}

export default Step1