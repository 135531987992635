import React from 'react'
import ServicesBox from './ServicesBox';

const Services = () => {
  return (
    <>
    <section>
            <div className="container">
                <div className="row clearfix">
                    <div className="col-12">
                        <h2>Trusted Pet Service Providers Near You</h2>
                    </div>
                </div>
                <div className="row mt-4 clearfix">
                            <ServicesBox />
                            <ServicesBox />
                            <ServicesBox />
                </div>
                <div className="row clearfix">
                    <div className="col-12 text-center">
                        <a href="#" className="btn btn-link">View all</a>
                    </div>
                </div>
            </div>
        </section>
        
        <section style={{background: `url('images/video-bg.jpg')`, 
        backgroundRepeat: `no-repeat`, backgroundPosition: `top center`, backgroundSize: `cover`}}>
            <div className="container">
                <div className="row clearfix">
                    <div className="col-12">
                        <div className="video-container">
                            <div className="video-text">Why <span>Choose</span><br /><span className="underline">PawFive</span></div>
                            <div className="play-btn"><a href="#"><img src="images/play-button.png" /></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
  )
}

export default Services