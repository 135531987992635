import React from 'react'

export const ServicesBox = () => {
  return (
    
    <div className="col-12 col-lg-4 col-xl-4">
                        <div className="servicesBox">
        <img src="images/img-1.jpg" alt="Image goes here" />
                            <div className="row clearfix">
                                <div className="col-7 col-lg-7 col-xl-7">
                                    <p className="title">Paraskennel</p>
                                </div>
                                <div className="col-5 col-lg-5 col-xl-5">
                                    <div className="rating"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star-o"></i></div>
                                </div>
                            </div>
                            <p className="info">Lorem Ipsum is simply dummy text of the printing and typesetting</p>
                            <div className="row clearfix">
                                <div className="col-7 col-lg-7 col-xl-7">
                                    <div className="services-footer clearfix">
                                        <span>Service Provide</span>
                                        <img src="images/icons/dog-walk-icon.png" alt="Image goes here" />
                                        <img src="images/icons/boarding-icon.png" alt="Image goes here" />
                                        <img src="images/icons/hair-cut-icon.png" alt="Image goes here" />
                                    </div>
                                </div>
                                <div className="col-5 col-lg-5 col-xl-5">
                                    <a href="#" className="btn btn-theme float-end mt-4">Book</a>
                                </div>
                            </div>
                            </div>
                    </div>  

  )
}

export default ServicesBox