import React from 'react'

const Favourite = () => {
  return (
    <div className="col-12 col-md-9 col-lg-8 col-xl-8">
    <div className="dashboard-title mb-2">My Favourites</div>
    <div className="favourites box-shadow mb-3">
        <img src="images/pawsitive-logo.jpg" alt="Image goes here" />
        <div className="favourites-content">
            <div className="cdetails">
                <div className="cname">Pawsitive Service</div>
                <div className="creviews">
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-half"></i>
                    <i className="bi bi-star"></i>
                    4.7 . 20 Reviews
                </div>
            </div>
            <ul className="otherinfo">
                <li><i className="bi bi-geo-alt-fill"></i> Sector-4, Rohini, Delhi-85</li>
                <li><i className="bi bi-signpost-split-fill"></i> 750  Mts away</li>
                <li><i className="bi bi-clock-fill"></i> 8:00am to 9:00pm</li>
            </ul>
        </div>
        <div className="heart"><i className="bi bi-heart-fill"></i></div>
    </div>
    <div className="favourites box-shadow mb-3">
        <img src="images/pawsitive-logo.jpg" alt="Image goes here" />
        <div className="favourites-content">
            <div className="cdetails">
                <div className="cname">Pawsitive Service</div>
                <div className="creviews">
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-half"></i>
                    <i className="bi bi-star"></i>
                    4.7 . 20 Reviews
                </div>
            </div>
            <ul className="otherinfo">
                <li><i className="bi bi-geo-alt-fill"></i> Sector-4, Rohini, Delhi-85</li>
                <li><i className="bi bi-signpost-split-fill"></i> 750  Mts away</li>
                <li><i className="bi bi-clock-fill"></i> 8:00am to 9:00pm</li>
            </ul>
        </div>
        <div className="heart"><i className="bi bi-heart-fill"></i></div>
    </div>
    <div className="favourites box-shadow mb-3">
        <img src="images/pawsitive-logo.jpg" alt="Image goes here" />
        <div className="favourites-content">
            <div className="cdetails">
                <div className="cname">Pawsitive Service</div>
                <div className="creviews">
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-half"></i>
                    <i className="bi bi-star"></i>
                    4.7 . 20 Reviews
                </div>
            </div>
            <ul className="otherinfo">
                <li><i className="bi bi-geo-alt-fill"></i> Sector-4, Rohini, Delhi-85</li>
                <li><i className="bi bi-signpost-split-fill"></i> 750  Mts away</li>
                <li><i className="bi bi-clock-fill"></i> 8:00am to 9:00pm</li>
            </ul>
        </div>
        <div className="heart"><a href="#"><i className="bi bi-heart-fill"></i></a></div>
    </div>
</div>
  )
}

export default Favourite