import React, { useEffect, useState,useRef } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL, IMG_URL } from "../../constant";
import GooglePlacesAutoComplete from "../SearchPageDetails/GooglePlacesAutoComplete";
import TimePicker from "rc-time-picker";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import ImagesUpload from "../../hooks/ImagesUpload";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoadingSpinner from "./LoadingSpinner";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Map from "../GoogleMap/Map";
import { storeMainCategoriesAction } from "../../redux/actions/action";
import { getProviderServicesAction } from "../../redux/actions/action";
import { useDispatch } from "react-redux";
import { storingUserData } from "../../redux/actions/action";
import { storeAccordionAllAmenitiesAction } from "../../redux/actions/action";

const Step3 = () => {
  const dispatch = useDispatch();
  const userDataRedux = useSelector((state) => state.storeUserDataReducer)
  const selectedAccordion = useSelector((state) => state.storeAccordionAllAmenitiesReducer);
  const businessDetails = userDataRedux.data.data;
  const[certificationIsemptyStatus, setcertificationIsemptyStatus] = useState()
  const [formValues, setFormValues] = useState(businessDetails);
  const time=userDataRedux.data.data.providers.provider_working_hours;
  const [formTimeValues, setformTimeValues] = useState(time);
  const [fieldErrorMsg, setfieldErrorMsg] = useState('')
  const [openFieldErrorMsg, setopenFieldErrorMsg] = useState(false)
  const providerServices = useSelector((state) => state.getProviderServicesAction);
  const selectedAmenitiesReducer = useSelector((state) => state.storeAccordionAllAmenitiesReducer);
// console.log("businessDetails", businessDetails)
const[timeIsEmpty,settimeIsEmpty]= useState(false)
  const [sharecapacityemptyError, setsharecapacityemptyError] = useState([{}])
  const [petSizeError, setpetSizeError] = useState([{}])
  const [imagesError, setimagesError] = useState([{}])
  const [priceEmptyError, setpriceEmptyError] = useState([{}])
const[timeErrorFun, settimeErrorFun] = useState([])
  const [isPriceValid, setisPriceValid] = useState()
  const [isShareCapacityValid, setisShareCapacityValid] = useState()
  const [isPetSizeValid, setisPetSizeValid] = useState()
  const [isimageValid, setisimageValidd] = useState()
  const [isamenitySelectedValid, setisamenitySelectedValid] = useState()
  const [skillsSelectionStatus, setskillsSelectionStatus] = useState()
const [SuperVisionLabelActiveState, setSuperVisionLabelActiveState] = useState()
const [accordionNotSelected, setaccordionNotSelected] = useState(false)
  const url1 = API_URL+'search/main-category-name';
  const [amenitySelectionError,setamenitySelectionError] = useState([{}])
  const [providerServicesAPIData, setproviderServicesAPIData] = useState([])
  //weekdays open close timing variables
  const [validationError, setValidationError] = useState(false)
  const autoAddressLatLong = useSelector(
    (state) => state.storeAutoAddressLatLongReducer
  );

  console.log("selectedAmenitiesReducer", selectedAmenitiesReducer)
  const deleteImageIcon = {
    position: "absolute",
   marginTop: "0px",
right: "10px",
color: "white",
fontWeight: "bold",
backgroundColor: "rgb(226,108,71)",
fontSize: "23px",
  cursor: "pointer",
  }


  const shareCapacityFocusRef = useRef(null);
  const petSizefocusRef = useRef(null);
  const picturesfocusRef = useRef(null);
const priceRef = useRef(null)
  const [userdata, setUserData] = useState(JSON.parse(window.localStorage.getItem("paw5-login-data")));
  const allSuperVisionLevels = ['Shared Pet Care', 'Dedicated Pet Care']

  const [base64Image, setbase64Image] = useState([]);
  const [amenityPriceInput, setAmenityPriceInput] = useState();
  const [accordiongOpenCloseStatus, setaccordiongOpenCloseStatus] =
    useState(false);
  const [profilePic, setProfilePic] = useState();
  const [petSizes, setPetSizes] = useState([]);
  const [dummyData, setdummyData] = useState([]);
  const amenitiesSelectedServices = JSON.parse(
    window.localStorage.getItem("amenitiesSelectedService")
  );
  const selectedServicesForAdd = JSON.parse(
    window.localStorage.getItem("selectedServicesForAdd")
  );
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [amenitiesAsPerMainCategories, setamenitiesAsPerMainCategories] =
    useState([]);
  let data = 0;
  const [base64APICode, setbase64APICode] = useState();
  const [sundayOpenTime, setsundayOpenTime] = useState("");
  const [sundayCloseTime, setsundayCloseTime] = useState("");
  const [mondayOpenTime, setmondayOpenTime] = useState("");
  const [mondayCloseTime, setmondayCloseTime] = useState("");
  const [tuesdayOpenTime, settuesdayOpenTime] = useState("");
  const [tuesdayCloseTime, settuesdayCloseTime] = useState("");
  const [wednesdayOpenTime, setwednesdayOpenTime] = useState("");
  const [wednesdayCloseTime, setwednesdayCloseTime] = useState("");
  const [thursdayOpenTime, setthursdayOpenTime] = useState("");
  const [thursdayCloseTime, setthursdayCloseTime] = useState("");
  const [fridayOpenTime, setfridayOpenTime] = useState("");
  const [fridayCloseTime, setfridayCloseTime] = useState("");
  const [saturdayOpenTime, setsaturdayOpenTime] = useState("");
  const [saturdayCloseTime, setsaturdayCloseTime] = useState("");
  //checkbox variables
  const [sundayOpenClose, setSundayOpenClose] = useState("0");
  const [mondayOpenClose, setMondayOpenClose] = useState("0");
  const [tuesdayOpenClose, setTuesdayOpenClose] = useState("0");
  const [wednesdayOpenClose, setWednesdayOpenClose] = useState("0");
  const [thursdayOpenClose, setThursdayOpenClose] = useState("0");
  const [fridayOpenClose, setFridayOpenClose] = useState("0");
  const [saturdayOpenClose, setSaturdayOpenClose] = useState("0");
  const [nonPremiumCat, setnonPremiumCat] = useState();
  const [premiumCat, setPremiumCat] = useState();
  const [allcats, setallcats] = useState();
  const amenitiesAPIURL = API_URL + "service/sub-categories/";
  // const [amenitiesFetch] = Categories(amenitiesAPIURL);
  const [selectedAminities, setselectedAminities] = useState([]);
  const [formBusinessValues, setBusinessFormValues] = useState([]);
  const [address, setAddress] = useState("");
  const [cordinates, setCordinates] = useState();
  const [shareCapacity, setShareCapacity] = useState([]);
  const [amenityPrices, setAmenityPrices] = useState([]);
  const [businessName, setBusinessName] = useState();
  const [description, setDescription] = useState();
  const [accordionID, setAccordionID] = useState();
  const [allAccordionsIDs, setAllAccordionsIDs] = useState([]);
  const [serviceSavedMessage, setServiceSavedMessage] = useState(false);
  const [supervision, setSupervision] = useState([]);
  const [base64PDF, setbase64PDF] = useState([]);
  const [skillsQualification, setskillsQualification] = useState([]);
  const [userSkillsQualification, setUserskillsQualification] 
  = useState(businessDetails.providers.skills_and_qualification);
  const [certificationFromApi, setcertificationFromApi] = useState([])
  const [combinedSuperVision, setcombinedSuperVision] = useState([])
  const [updatedLocationURL, setUpdatedURL] = useState(
    "https://maps.google.com/maps?q=28.631081,77.218307&z=15&output=embed"
  );
  //loading spinners
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const url = API_URL + "service/sub-categories";
  const [open, setOpen] = useState(false);
  const [errorForm, seterrorForm] = useState(false);
    const [businessDetailSuccessMsg, setbusinessDetailSuccessMsg] = useState()
  const [addedError, setaddedError] = useState(false);
  const [businessFieldsErrorMsg, setbusinessFieldsErrorMsg] = useState()

  const snackbarBoxStyling = {
    bottom: "250px",
    left: "800px",
    top: "-200px",
  };

  // const [data] = Categories(url);

  async function fetchSkillsQualifications()  {
        await fetch(API_URL+'skill-and-qualifications', {
      method: 'GET',
      headers: {
        'Accept': "application/json",
      }
      }).then((res) => res.json())
      .then((data) => {
        console.log(data)
        setskillsQualification(data.data)
      })
      .catch((error) => 
        console.log(error.response.data.errors)
      );
      
  }

  useEffect(() => {
    fetchSkillsQualifications();
 testing()
  },[])

  // console.log("selectedCategory", selectedCategory)

  function testing() {
 
    let addedSupervision = businessDetails.providers.supervision_label
    // console.log("addedSupervision", addedSupervision)
    let dummy = []

    const rs = allSuperVisionLevels.reduce((acc, p) => {
      const matchingTime = addedSupervision.find(t => t === p);
      const status = (matchingTime ? 1 :  0);
      // console.log("temp", status)
      acc.push({p, status});
      return acc;
    }, []);
  
   
    // console.log("rs", rs)
    setcombinedSuperVision(rs)
  }


  useEffect(() => {
    if(userdata.data.providers.certification)
    {
      
      setcertificationFromApi(userdata.data.providers.certification)
    }

    // console.log("amenitiesSelectedServices", amenitiesSelectedServices);
    // console.log("selectedServicesForAdd", selectedServicesForAdd)
    setSupervision(userdata.data.providers.supervision_label)
    setamenitiesAsPerMainCategories(amenitiesSelectedServices);
    setSelectedCategory(selectedServicesForAdd);
  }, []);

  useEffect(() => {
    testing()
  },[supervision])
  
  const navigate = useNavigate();
  //console.log(props)
  const format = "h:mm a";

  const handleImage = (dd) => {
    // console.log(allcats)
    console.log("dd", dd)
    console.log("dd", dd.length)

    const temparray = [...base64Image];
    for (let i = 0; i <= dd.length; i++) {
      // setProfilePic(URL.createObjectURL(dd[i]));
      const selectedImage = dd;
      let reader = new FileReader();
      reader.readAsDataURL(selectedImage[i]);
      const len = base64Image.length
      reader.onload = (e) => {
        temparray.push({
          id: len+1,
          accordiongId: accordionID,
          code: e.target.result,
        });
        // console.log("temparray add", temparray);
        let temp=[...imagesError]
        let ind = temp.findIndex((f) => f.id == accordionID)
        console.log("ind", ind)
        if(ind>=0)
        {
          temp.splice(ind, 1)
        }
        setimagesError(temp)
        setbase64Image(temparray);
      };
    }
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    seterrorForm(false)
    setaddedError(false)
    setbusinessDetailSuccessMsg(false)
    setbusinessFieldsErrorMsg(false)
  };

  const handleSkills = (e) => {
    e.preventDefault();
    setskillsSelectionStatus(false)
    let d = e.target.value
    // console.log(d.replace("- Select -", ""))
if(d.replace("- Select -", "")== '')
{
  setskillsSelectionStatus(true)
  setUserskillsQualification(d.replace("- Select -", ""))
} else {
  setUserskillsQualification(d)
  setskillsSelectionStatus(false)
}
    // console.log(e.target.value.str("- Select -", ""))
    

  }

//   useEffect(() => {
// console.log("userSkillsQualification", userSkillsQualification)
//   },[userSkillsQualification])
// console.log("userSkillsQualification", userSkillsQualification)


  const handleChange = (e) => {
    e.preventDefault();
   
    if(e.target.value === '')
    {
      setopenFieldErrorMsg(true)
      setfieldErrorMsg(e.target.id + " cannot be empty")
      setbusinessFieldsErrorMsg(true)
    } else {
      setopenFieldErrorMsg(false)
      setFormValues({ ...formValues, [e.target.id]: e.target.value });
    }
  };

  const handleTimeChange = (e) => {
    // e.preventDefault();
// console.log(e.target.value)

// console.log(e.target.id)
// console.log(e.target.checked)
let breakingstring = e.target.id.split("_")
// console.log(breakingstring)
// console.log(formTimeValues)
const dummy=[...formTimeValues]
if(e.target.value == '' || e.target.value == undefined)
{
  alert("time cannot be empty")
  settimeIsEmpty(true)
} else {
if(breakingstring[0]==="sunday")
{
  if(breakingstring[1]=="open")
  { 
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].open_time=e.target.value


    setformTimeValues(dummy)
    settimeIsEmpty(false)
  } else if(breakingstring[1]=="close"){
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].close_time=e.target.value

    settimeIsEmpty(false)

    setformTimeValues(dummy)
  }
  else if(breakingstring[1]=="dayoff"){
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].time_diss=e.target.checked == true ? 1 : 0


    setformTimeValues(dummy)
  }
}


if(breakingstring[0]==="monday")
{
  if(breakingstring[1]=="open")
  { 
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].open_time=e.target.value
    setformTimeValues(dummy)
  } else if(breakingstring[1]=="close"){
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].close_time=e.target.value
    setformTimeValues(dummy)
  }
  else if(breakingstring[1]=="dayoff"){
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].time_diss=e.target.checked == true ? 1 : 0
    setformTimeValues(dummy)
  }
}
if(breakingstring[0]==="tuesday")
{
  if(breakingstring[1]=="open")
  { 
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].open_time=e.target.value
    setformTimeValues(dummy)
  } else if(breakingstring[1]=="close"){
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].close_time=e.target.value
    setformTimeValues(dummy)
  }
  else if(breakingstring[1]=="dayoff"){
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].time_diss=e.target.checked == true ? 1 : 0
    setformTimeValues(dummy)
  }
}
if(breakingstring[0]==="wednesday")
{
  if(breakingstring[1]=="open")
  { 
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].open_time=e.target.value
    setformTimeValues(dummy)
  } else if(breakingstring[1]=="close"){
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].close_time=e.target.value
    setformTimeValues(dummy)
  }
  else if(breakingstring[1]=="dayoff"){
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].time_diss=e.target.checked == true ? 1 : 0
    setformTimeValues(dummy)
  }
}
if(breakingstring[0]==="thursday")
{
  if(breakingstring[1]=="open")
  { 
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].open_time=e.target.value
    setformTimeValues(dummy)
  } else if(breakingstring[1]=="close"){
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].close_time=e.target.value
    setformTimeValues(dummy)
  }
  else if(breakingstring[1]=="dayoff"){
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].time_diss=e.target.checked == true ? 1 : 0
    setformTimeValues(dummy)
  }
}
if(breakingstring[0]==="friday")
{
  if(breakingstring[1]=="open")
  { 
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].open_time=e.target.value
    setformTimeValues(dummy)
  } else if(breakingstring[1]=="close"){
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].close_time=e.target.value
    setformTimeValues(dummy)
  }
  else if(breakingstring[1]=="dayoff"){
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].time_diss=e.target.checked == true ? 1 : 0
    setformTimeValues(dummy)
  }
}
if(breakingstring[0]==="saturday")
{
  if(breakingstring[1]=="open")
  { 
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].open_time=e.target.value
    setformTimeValues(dummy)
  } else if(breakingstring[1]=="close"){
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].close_time=e.target.value
    setformTimeValues(dummy)
  }
  else if(breakingstring[1]=="dayoff"){
    let ind = dummy.findIndex((f) => f.week == breakingstring[0])
    // console.log("ind", ind)
    dummy[ind].time_diss=e.target.checked == true ? 1 : 0
    setformTimeValues(dummy)
  }
}
}
   
  };

  function tConvert(time) {
    // Check correct time format and split into components
    if (time) {
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(" ");
    }

    // return adjusted time or original string
  }
  const handlePdfConvert = (e) => {
    // console.log(e.target.files);

    let dd = e.target.files;
    const temparray = [...base64PDF];
    for (let i = 0; i <= dd.length; i++) {
      //   setProfilePic(URL.createObjectURL(dd[i]));
      const selectedImage = dd;
      let reader = new FileReader();
      reader.readAsDataURL(selectedImage[i]);
      reader.onload = (e) => {
        //console.log(e.target.result)
        temparray.push(e.target.result);
        // console.log(temparray);
        setcertificationIsemptyStatus(false)
        setbase64PDF(temparray);
      };
    }
  };

  function checkSizeValidation(e) {
  //  console.log(11)
    let dummy = e.target.files;
    console.log("d", dummy)
    for (let i = 0; i < dummy.length; i++) {
      data += dummy[i].size / 1024 / 1024;
      if (data >= 20) {
        alert("exceededl limit");
      } else if (data <= 20) {
        handleImage(dummy);
      }
    }
  }

  
  const handlerdeleteImageFromAPI = (e) => {
    let imgSrc = document.getElementById(e.target.id).previousSibling.src;
    let newImagePath=imgSrc.replace("https://paw5.digiinteracts.in/", "")
    // console.log(newImagePath)
    let indexvalue=0
    let dummy=JSON.parse(window.localStorage.getItem("paw5-login-data"))
// console.log(JSON.parse(window.localStorage.getItem("paw5-login-data")).data)
    indexvalue = dummy.data.providers.certification.findIndex((i,index) => index == e.target.id);
    //  console.log("indexvalue", indexvalue)
    if (indexvalue >= 0) {
      dummy.data.providers.certification.splice(indexvalue, 1);

      console.log("dummy", dummy);
      localStorage.setItem('paw5-login-data', JSON.stringify(dummy))
      // setcertificationFromApi(dummy)
    }

    // console.log(e.target.id)
    fetch(API_URL+'user/provider/service/certificate_image', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        "Authorization": "Bearer " + userdata.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({id: userdata.data.providers.id, certification_img:newImagePath }),
     }).then((res) => res.json())
     .then((data) => {
      if(data ==1 )
      {
        console.log(data)
        let indexvalue=0
        console.log(certificationFromApi)
        let dummy=[...certificationFromApi]
        indexvalue = dummy.findIndex((i,index) => index == e.target.id);
         console.log("indexvalue", indexvalue)
        if (indexvalue >= 0) {
          dummy.splice(indexvalue, 1);
    
          console.log("dummy", dummy);
          setcertificationFromApi(dummy)
   
        }
      }
      

     })

  };


  const removeImage = (e) => {
    e.preventDefault();
    // console.log("base", base64Image)
    // console.log("id", e.target.id);
    let indexvalue = 0;
    // console.log(base64Image)
    indexvalue = base64Image.findIndex((i) => i.id == e.target.id);
    //  console.log("indexvalue", indexvalue)
    if (indexvalue >= 0) {
      let dummy = [...base64Image];

      dummy.splice(indexvalue, 1);

      // console.log("dummy", dummy);
      setbase64Image(dummy);
    }
  };
  const removeItems = (e) => {
    e.preventDefault();
    // console.log(e.target.id);
    let indexvalue = 0;
    indexvalue = base64PDF.findIndex((i, index) => (i = e.target.id));
    //console.log(indexvalue)
    if (indexvalue >= 0) {
      let dummy = [...base64PDF];
      dummy.splice(indexvalue, 1);
      setcertificationIsemptyStatus(true)
      setbase64PDF(dummy);
    }
  };

  const updateSuperVision = (e) => {
    console.log(e.target.value)
    let indexvalue = -1;

      indexvalue = combinedSuperVision.findIndex((i) => i.p == e.target.value);

      const copyingValue = [...combinedSuperVision];
      for(let key in copyingValue)
      {
        if(copyingValue[key].p === e.target.value)
        {
          if(copyingValue[key].status === 1){
            copyingValue[key].status = 0
          } else {
            copyingValue[key].status = 1
          }
        }
      }
// console.log("copyingValue",copyingValue)
      setcombinedSuperVision(copyingValue);
  };

  // useEffect(() => {
  //   console.log("allAccordionsIDs", allAccordionsIDs)
  // },[allAccordionsIDs])
   

  ////imagesupload functions ended here

  const fetchAmenitiesNames = (id) => {
    // console.log("id", id);
    setAccordionID(id);

  let ind = allAccordionsIDs.findIndex((f) => f == id)
  // console.log("id",id)
  if(ind == -1)
  {
    setAllAccordionsIDs(all => [...all, id])
  }
    

    // console.log(props.amenitiesData[e.target.id])
    // setallcats(props.amenitiesData[e.target.id])
    //     fetch(amenitiesAPIURL+e.target.id, {
    //         method: 'POST',
    //         headers: {
    //           'Accept': 'application/json',
    //         },
    //       })
    //         .then((res) => res.json())
    //         .then((data) =>
    //         setallcats(data.data)
    // //   console.log(data.data)
    //         );
  };
  ///save business details api hit///

  useEffect(() => {
    console.log("formTimeValues", formTimeValues);
    console.log("formValues", formValues);

  }, [formTimeValues,formValues]);

  const uploadBusinessDetails = (event) => {
    event.preventDefault();
    // console.log("formValues", formValues)

    let cord = JSON.parse(window.localStorage.getItem("profle-latlng"))
    // console.log("local", userdata.data.providers.latitude)
const updatedAddress = JSON.parse(window.localStorage.getItem("map-address-marker"));
// console.log("userSkillsQualification", userSkillsQualification)
// console.log(1,sundayOpenTime,sundayCloseTime, mondayOpenTime,
//   mondayCloseTime, tuesdayOpenTime,tuesdayCloseTime,
//   wednesdayCloseTime, wednesdayOpenTime,
//   thursdayOpenTime,thursdayCloseTime,
//   fridayOpenTime,fridayCloseTime,
//   saturdayOpenTime,saturdayCloseTime
//    )
    
    // let sunday = [];
    // let monday = [];
    // let tuesday = [];
    // let wednesday = [];
    // let thursday = [];
    // let friday = [];
    // let saturday = [];
    // let daysData = userdata.data.providers.provider_working_hours

    // if(sundayOpenTime !== null && sundayCloseTime!== null &&
    //   mondayOpenTime!== null && mondayCloseTime !== null&&
    //   tuesdayOpenTime !== null && tuesdayCloseTime !== null&&
    //   wednesdayOpenTime!== null && wednesdayCloseTime !== null&&
    //   thursdayOpenTime!== null && thursdayCloseTime !== null
    //   &&  fridayOpenTime!== null &&  fridayCloseTime!== null
    //   && saturdayOpenTime!== null && saturdayCloseTime!== null)
    //   { 

    //     console.log("sundayOpenTime", sundayOpenTime)
    //   // setopenFieldErrorMsg(false)
    //   settimeIsEmpty(false)
    //   sunday = {
    //     ...sunday,
    //     ["open_time"]: 
    //     sundayOpenTime ? sundayOpenTime 
    //     : settimeIsEmpty(true),
    //     ["close_time"]: sundayCloseTime
    //     ? sundayCloseTime 
    //     : settimeIsEmpty(true),
    //     ["time_diss"]: sundayOpenClose ?
    //     sundayOpenClose : '',
    //   };

// console.log("userSkillsQualification", userSkillsQualification)
if(userSkillsQualification == '' ||
userSkillsQualification == undefined )
{  
  setskillsSelectionStatus(true)

} else { 
  setskillsSelectionStatus(false)

}
    //   monday = {
    //     ...monday,
    //     ["open_time"]: mondayOpenTime ?
    //     mondayOpenTime : settimeIsEmpty(true),
    //     ["close_time"]: mondayCloseTime?
    //     mondayCloseTime
    //     :settimeIsEmpty(true),
    //     ["time_diss"]: mondayOpenClose 
    //     ? mondayOpenClose
    //     : '',
    //   };
    


    //   tuesday = {
    //     ...tuesday,
    //     ["open_time"]: tuesdayOpenTime 
    //     ?
    //     tuesdayOpenTime : settimeIsEmpty(true),
    //     ["close_time"]: tuesdayCloseTime
    //     ? tuesdayCloseTime
    //     : settimeIsEmpty(true),
    //     ["time_diss"]: tuesdayOpenClose
    //     ? tuesdayOpenClose : '',
    //   };



    //   wednesday = {
    //     ...wednesday,
    //     ["open_time"]: wednesdayOpenTime
    //     ?
    //     wednesdayOpenTime : settimeIsEmpty(true),
    //     ["close_time"]: wednesdayCloseTime
    //     ? wednesdayCloseTime
    //     :settimeIsEmpty(true),
    //     ["time_diss"]: wednesdayOpenClose
    //     ? wednesdayOpenClose :'',
    //   };



    //   thursday = {
    //     ...thursday,
    //     ["open_time"]: thursdayOpenTime
    //     ?
    //     thursdayOpenTime : settimeIsEmpty(true),
    //     ["close_time"]: thursdayCloseTime
    //     ? thursdayCloseTime
    //     :settimeIsEmpty(true),
    //     ["time_diss"]: thursdayOpenClose
    //     ? thursdayOpenClose : '',
    //   };



    //   friday = {
    //     ...friday,
    //     ["open_time"]: fridayOpenTime
    //     ?
    //     fridayOpenTime :settimeIsEmpty(true),
    //     ["close_time"]: fridayCloseTime
    //     ? fridayCloseTime
    //     :settimeIsEmpty(true),
    //     ["time_diss"]: fridayOpenClose
    //     ? fridayOpenClose : '',
    //   };



    //   saturday = {
    //     ...saturday,
    //     ["open_time"]: saturdayOpenTime
    //     ?
    //     saturdayOpenTime : settimeIsEmpty(true),
    //     ["close_time"]: saturdayCloseTime
    //     ? saturdayCloseTime
    //     :settimeIsEmpty(true),
    //     ["time_diss"]: saturdayOpenClose
    //     ? saturdayOpenClose : '',
    //   };
    // }
    //  else { alert(112)
      
    //   settimeIsEmpty(true)

    // }

      // console.log( "setcombinedSuperVision", combinedSuperVision)
      let supervision_label_data=[]
      combinedSuperVision.map((i) => {
        if(i.status ==1)
        {
          supervision_label_data.push(i.p)
        }
      })
      // console.log( "supervision_label_data", supervision_label_data.length)
      // console.log( "supervision_label_data", supervision_label_data)

      if(supervision_label_data.length ==0)
      {
        setSuperVisionLabelActiveState(true)
      } else {
        setSuperVisionLabelActiveState(false)
      }
// console.log("base64PDF", base64PDF.length)
      
    if(base64PDF.length ===0 ) {
        
        setcertificationIsemptyStatus(true)
      } else {
        setcertificationIsemptyStatus(false)

      }
      // console.log("f", formTimeValues)
    let object = {
      store_name: 
      formValues.providers.store_name ,
      about_us: formValues.providers.about_us ,
      address: updatedAddress ? updatedAddress : formValues.providers.address ,
      latitude: cord ? cord.lat : formValues.providers.latitude ,
      longitude:  cord ? cord.lng: formValues.providers.longitude,
      phone:  formValues.phone ,
      sunday: {["open_time"]: formTimeValues[0].open_time,
      ["close_time"]: formTimeValues[0].close_time,
      ["time_diss"]: formTimeValues[0].time_diss},
      monday: {["open_time"]: formTimeValues[1].open_time,
      ["close_time"]: formTimeValues[1].close_time,
      ["time_diss"]: formTimeValues[1].time_diss},
      tuesday: {["open_time"]: formTimeValues[2].open_time,
      ["close_time"]: formTimeValues[2].close_time,
      ["time_diss"]: formTimeValues[2].time_diss},
      wednesday: {["open_time"]: formTimeValues[3].open_time,
      ["close_time"]: formTimeValues[3].close_time,
      ["time_diss"]: formTimeValues[3].time_diss},
      thursday: {["open_time"]: formTimeValues[4].open_time,
      ["close_time"]: formTimeValues[4].close_time,
      ["time_diss"]: formTimeValues[4].time_diss},
      friday: {["open_time"]: formTimeValues[5].open_time,
      ["close_time"]: formTimeValues[5].close_time,
      ["time_diss"]: formTimeValues[5].time_diss},
      saturday: {["open_time"]: formTimeValues[6].open_time,
      ["close_time"]: formTimeValues[6].close_time,
      ["time_diss"]: formTimeValues[6].time_diss},
      skills_and_qualification: userSkillsQualification ? userSkillsQualification : formValues.providers.skills_and_qualification ,
      certification:base64PDF,
      supervision_label: supervision_label_data ? supervision_label_data : userDataRedux.providers.supervision_label ,
    };
    // console.log("object", object)
  //   console.log(2,
  //     fieldErrorMsg , timeIsEmpty
  // , certificationIsemptyStatus ,
  // SuperVisionLabelActiveState
  //   )setskillsSelectionStatus
setTimeout(() => {
  setIsLoading(true)
    setShow(true);
  if(openFieldErrorMsg == false  && timeIsEmpty ==false
    && SuperVisionLabelActiveState==false  &&
    skillsSelectionStatus == false
     && !certificationIsemptyStatus )
  {
    setIsLoading(false)
    setShow(false);
    postName();

  // alert("good")
  } else {
    setIsLoading(false)
    setShow(false);
  }
  //  else {

  //   alert("error hai form mein")
  // }
}, 1000);
// console.log("openFieldErrorMsg", openFieldErrorMsg)

    // console.log("fieldErrorMsg", fieldErrorMsg)
    // console.log("timeIsEmpty", timeIsEmpty)
    // console.log("SuperVisionLabelActiveState", SuperVisionLabelActiveState)
    // console.log("setcertificationIsemptyStatus", certificationIsemptyStatus)
    // console.log("skillsSelectionStatus", skillsSelectionStatus)

    async function postName() {
      setIsLoading(true)
    setShow(true);
      let object = {
        store_name: 
        formValues.providers.store_name ,
        about_us: formValues.providers.about_us ,
        address: updatedAddress ? updatedAddress : formValues.providers.address ,
        latitude: cord ? cord.lat : formValues.providers.latitude ,
        longitude:  cord ? cord.lng: formValues.providers.longitude,
        phone:  formValues.phone ,
        sunday: {["open_time"]: formTimeValues[0].open_time,
        ["close_time"]: formTimeValues[0].close_time,
        ["time_diss"]: formTimeValues[0].time_diss},
        monday: {["open_time"]: formTimeValues[1].open_time,
        ["close_time"]: formTimeValues[1].close_time,
        ["time_diss"]: formTimeValues[1].time_diss},
        tuesday: {["open_time"]: formTimeValues[2].open_time,
        ["close_time"]: formTimeValues[2].close_time,
        ["time_diss"]: formTimeValues[2].time_diss},
        wednesday: {["open_time"]: formTimeValues[3].open_time,
        ["close_time"]: formTimeValues[3].close_time,
        ["time_diss"]: formTimeValues[3].time_diss},
        thursday: {["open_time"]: formTimeValues[4].open_time,
        ["close_time"]: formTimeValues[4].close_time,
        ["time_diss"]: formTimeValues[4].time_diss},
        friday: {["open_time"]: formTimeValues[5].open_time,
        ["close_time"]: formTimeValues[5].close_time,
        ["time_diss"]: formTimeValues[5].time_diss},
        saturday: {["open_time"]: formTimeValues[6].open_time,
        ["close_time"]: formTimeValues[6].close_time,
        ["time_diss"]: formTimeValues[6].time_diss},
        skills_and_qualification: userSkillsQualification ? userSkillsQualification : formValues.providers.skills_and_qualification ,
        certification:base64PDF,
        supervision_label: supervision_label_data ? supervision_label_data : userDataRedux.providers.supervision_label ,
      };
      // console.log(object);
      const response = await fetch(API_URL + "user/provider-update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + userdata.token,
          "Accept": "application/json",
        },
        body: JSON.stringify(object),
      });
      const responseText = await response.json();
      if (responseText.success == true); // logs 'OK'
      console.log(responseText)
localStorage.setItem('paw5-login-data', JSON.stringify(responseText))
dispatch(storingUserData(responseText))

        setIsLoading(false)
      setShow(false);
      setbusinessDetailSuccessMsg(true);

      // alert("Data is saved")
    }


  };


  
  ///
  const sendData = (cord) => {
    setCordinates(cord);
    // console.log("cord step3", cord);
    localStorage.setItem("profle-latlng", JSON.stringify(cord));

    // console.log(cord.lat)
    setUpdatedURL(
      `https://maps.google.com/maps?q=${cord.lat},${cord.lng}&z=15&output=embed`
    );
  };


//   useEffect(() => {
// console.log("effec", shareCapacity)
//   },[shareCapacity])
  
  const sendData1 = (add) => {
    setAddress(add);
    localStorage.setItem('map-address-marker', JSON.stringify(add))

    // console.log(add);
  };
  // const handleServicesSaveInfo = (e) => {
  //     // console.log(e.target.id)
  //     // console.log(e.target.value)
  //     setBusinessFormValues({ ...formBusinessValues, [e.target.id]: e.target.value });
  //   };

  const updateShareCapacity = (e) => {
    // console.log(3,Math.abs(e.target.value))
    let inputValue = Math.abs(e.target.value)
    // console.log(3,e.target.value)
// console.log(shareCapacity.length, "len")
    // alert("target", e.target.value)
  //  setsharecapacityemptyError()
  let dummy=[...sharecapacityemptyError]
  let ind = dummy.findIndex((f) => Number(f.id) == Number(accordionID))
  if(ind>=0)
  {
    dummy.splice(ind, 1)
  }
  setsharecapacityemptyError(dummy)
    if (shareCapacity.length === 0) {
      let arr=[...shareCapacity]
      arr.push({ acdId: accordionID, capacity: Math.abs(e.target.value) });
      setShareCapacity(arr);
    } else if( inputValue !==0){
      for(let i=0; i<=shareCapacity.length-1; i++)
      {
        if(Number(shareCapacity[i].acdId) !== Number(accordionID))
        { 
          setShareCapacity([...shareCapacity, {acdId: accordionID, capacity: inputValue}])
        } else {
          let arr= [...shareCapacity]
          let ind = arr.findIndex((f) => Number(f.acdId) === Number(accordionID))
          // console.log("ind", ind)
          arr.splice(ind, 1)
          arr.push({acdId: accordionID, capacity: inputValue})
          setShareCapacity(arr)
          // setShareCapacity([...shareCapacity, {acdId: accordionID, capacity: inputValue}])
          // console.log("arr not 0",arr)
        }
      }

      } 
      else if(inputValue ===0 ){
        for(let i=0; i<=shareCapacity.length-1; i++)
        {
        if(shareCapacity[i].acdId === accordionID)
        {
          let arr= [...shareCapacity]
          let ind = arr.findIndex((f) => Number(f.acdId) === Number(accordionID))
          arr.splice(ind, 1)
          setShareCapacity(arr)
          // console.log("arr is 0 loop", arr)

        } 
      }
      } 
    }

  const handleBusinessSaveInfo = (e) => {
    // console.log(e.target.id)
    // console.log(e.target.value)
    setBusinessFormValues({
      ...formBusinessValues,
      [e.target.id]: e.target.value,
    });
  };

  const getAminitiesNames = (e) => {
    console.log(12,selectedAminities)
    // console.log(e);
    // console.log("accordionID", accordionID)
    // console.log(allcats[0].main_category_id)
    console.log(e.target.id);
    console.log("val", e.target.value);

selectedAmenitiesReducer.map((se) => {
  if(se.id == e.target.id )
  {
    se.active= e.target.checked
  }
})

let dummy=[...amenitySelectionError]
  let ind = dummy.findIndex((f) => Number(f.id) == Number(accordionID))
  if(ind>=0)
  {
    dummy.splice(ind, 1)
  }
  setamenitySelectionError(dummy)
    // console.log(1, dummy)
    let indexvalue = 0;
    indexvalue = selectedAminities.findIndex((i) =>
     i.id == e.target.id && i.mainId === accordionID);
    //console.log(indexvalue)

    if (e.target.checked === false) {
      if (indexvalue >= 0) {
        let tempamenities = [...selectedAminities];
        tempamenities.splice(indexvalue, 1);
        // console.log("tempamenities", tempamenities);

        setselectedAminities(tempamenities);
      }
    }

    if (e.target.checked === true) {
      if (indexvalue == -1) {
        const arrayvalue = [...selectedAminities];
        arrayvalue.push({
          id: e.target.id,
          name: e.target.value,
          mainId: accordionID,
        });
        setselectedAminities(arrayvalue);
        console.log("arrayvalue", arrayvalue);
      }
    }
  };


const formControlInputClass = sharecapacityemptyError
? 'form-control blur-input' : 'form-control';


  const handleBlur = () => {
    setsharecapacityemptyError("Share capacity cannot be empty" )

  }
  //    console.log(selectedAminities)
  // useEffect(() => {
  //   console.log("se", selectedAminities)
   
  // }, [selectedAminities]);


// submit service function

  const addServiceDetails = (event) => {
    event.preventDefault();
    // setIsLoading(true);
    // setShow(true);
    // console.log(shareCapacity)
    // console.log(selectedAminities)
    // // console.log(allcats[0].main_category_id)
    // console.log(amenityPrices)
    // console.log(base64Image)
    // console.log(petSizes)
    // console.log(petSizes.length, "len")
    let PriceValid = false;
    let isAmountNotEmpty=[]
    let ShareCapacityValid= false;
    let PetSizeValid= false;
    let imageValid = false;
    let amenitySelectedValid = false;
    let images = [];

    // image validation logic

    if(base64Image.length ===0){

      let dummy = [...imagesError]
      let ind = dummy.findIndex((f) => f.id == accordionID)
      if(ind === -1){
        imageValid=false

        setimagesError([...imagesError,{id:accordionID, error:"Image must be selected"}])
      } 
}
else {
let imageLogicStatus = base64Image.some((f) => f.accordiongId == accordionID)
    //  console.log("imageLogicStatus", imageLogicStatus)
     if(!imageLogicStatus)
     {
      let dummy = [...imagesError]
      let ind = dummy.findIndex((f) => f.id == accordionID)
      if(ind === -1){
        imageValid=false

        setimagesError([...imagesError,{id:accordionID, error:"Image must be selected"}])
      } 
     } else {
      imageValid=true
          for (const img in base64Image) {
        if (base64Image[img].accordiongId == accordionID)
          images.push(base64Image[img].code);
      }
     }
    }

    // if (base64Image.length ===0 )
    // {
    //   let dummy = [...imagesError]
    //   let ind = dummy.findIndex((f) => f.id == accordionID)
    //   if(ind === -1){
    //     setimagesError([...imagesError,{id:accordionID, error:"Image must be selected"}])
    //   }
    //   setimagesError( [...imagesError,{id:accordionID, error: "Image must be selected"}])
    //   isimageValid=false;
    // } else {
    //   let res = base64Image.some((f) => f.accordiongId == accordionID)
    // // console.log("res", res)
    // if(!res)
    // {
    //   isimageValid=false;
    //   let dummy=[...imagesError];
    //   let ind = dummy.findIndex((f) => f.accordiongId == accordionID)
    //   if(ind == -1)
    //   setimagesError( dummy)
    // }
      // base64Image.map((item) => {
      //   if(item.accordiongId !== accordionID)
      //   {
      //     setimagesError( [...imagesError,{id:accordionID, error:  "Image must be selected"}])

      //   } else {
      //     let dummy=[{...imagesError}]
      //     let ind=  dummy.findIndex((f) => f.accordiongId == accordionID)
      //     if(ind >=0)
      //     dummy.splice(ind,1)
      //     setimagesError(dummy)

      //   } 
       
      // })
  //     for (const img in base64Image) {
  //     if (base64Image[img].accordiongId == accordionID)
  //     {


  //   if(base64Image.length <= 0 && base64Image !== null )
  //   {
      
  //     setimagesError({id:accordionID, error: "Image must be selected"})
  //     isimageValid = false
  //     // picturesfocusRef.current.focus()
  //   } else {
  //     isimageValid = true

  //     for (const img in base64Image) {
  //       if (base64Image[img].accordiongId == accordionID)
  //         images.push(base64Image[img].code);
  //     }
  //   }
  // } else {
  //   setimagesError({id:accordionID, error: "Image must be selected"})
  //     isimageValid = false
  //   // alert("image is not selected for this accordion")
  // }
  //   }
  // }

    // amenity selection logic validation

    if(selectedAminities.length == 0){
      let dummy = [...amenitySelectionError]
      let ind = dummy.findIndex((f) => f.id == accordionID)
      if(ind === -1){
        setamenitySelectionError([...amenitySelectionError,{id:accordionID, error:"One amenity must be selected for this category"}])
        amenitySelectedValid = false;

      } 

      // setamenitySelectionError([...amenitySelectionError, {id:accordionID, error:"One amenity must be selected for this category"}])
    } else {

      let res = selectedAminities.some((f) => f.mainId == accordionID )
      console.log("select res", res)
      if(!res)
      {
        let dummy=[...amenitySelectionError];
        let ind = dummy.findIndex((f) => f.id == accordionID)
        if(ind == -1)
        setamenitySelectionError([...amenitySelectionError,{id:accordionID, error:"One amenity must be selected for this category"}])
        amenitySelectedValid = false;

      } else {
        amenitySelectedValid = true
      }

  //   for (const am in selectedAminities) {
  //     if (selectedAminities[am].mainId == accordionID)
  //     {
  //   if(selectedAminities.length <= 0){
  //     setamenitySelectionError({id:accordionID, error:"One amenity must be selected for this category"})
  //     isamenitySelectedValid = false;
  //   } else {

  //     isamenitySelectedValid= true;

  //   }
  // } else {
  //   setamenitySelectionError(
  //     {id:accordionID, error:"One amenity must be selected for this category"})
  //     isamenitySelectedValid = false;
  // }
// }
    }


// price validation
    let prices = [];

    if(selectedAminities.length ===0){

          let dummy = [...priceEmptyError]
          let ind = dummy.findIndex((f) => f.id == accordionID)
          if(ind === -1){
            setpriceEmptyError([...priceEmptyError,{id:accordionID, error:"Price is not entered for this category"}])
            PriceValid=false
          } 
    }
    else {
    let priceLogicStatus = selectedAminities.some((f) => f.mainId == accordionID
         && f.amount === undefined || f.amount === '' ||  f.amount === '0')
         console.log("priceLogicStatus", priceLogicStatus)
         if(priceLogicStatus)
         {
          let dummy = [...priceEmptyError]
          let ind = dummy.findIndex((f) => f.id == accordionID)
          if(ind === -1){
            setpriceEmptyError([...priceEmptyError,{id:accordionID, error:"Price is not entered for this category"}])
            PriceValid=false
          } 
         } else {
          PriceValid=true
            for (const max in selectedAminities) {

        if (selectedAminities[max].mainId == accordionID)
          prices = {
            ...prices,
            [selectedAminities[max].id]: selectedAminities[max].amount,
          };
      }
         }
        }
  //   if(selectedAminities.length === 0){
  //     isPriceValid = false;
  //     if(priceEmptyError.length ===0)
  //     {
  //     setpriceEmptyError([{id:accordionID, error:"Price is not entered for this category"}])

  //     } else {
  //       let res = selectedAminities.some((f) => f.mainId == accordionID
  //       && f.amount === undefined || f.amount === '')
  //       if(!res)
  //       {
  //         setpriceEmptyError([...priceEmptyError,{id:accordionID, error:"Price is not entered for this category"}])

  //       }

  //     // setpriceEmptyError([...priceEmptyError,{id:accordionID, error:"Price is not entered for this category"}])
  //   }
  //   } else {
  //     let res = selectedAminities.some((f) => f.mainId == accordionID
  //     && f.amount === undefined || f.amount === '')
  //     // let res1 = selectedAminities.some((f) => f.amount === undefined || f.amount === '')
  //     console.log("resprice", res)

  //     // console.log("res1", res1)
  //     if(!res)
  //     {
  //       isPriceValid = false;
  //       // let dummy=[...priceEmptyError];
  //       // let ind = dummy.findIndex((f) => f.id == accordionID)
  //       // if(ind == -1)
  //       // setpriceEmptyError( dummy)
  // setpriceEmptyError([...priceEmptyError,{id:accordionID, error:"Price is not entered for this category"}])

  //     }

      // selectedAminities.map((item) => {
      //   if(item.mainId !== accordionID)
      //   {
      //     setpriceEmptyError( [...priceEmptyError,{id:accordionID, error: "Price is not entered for this category"}])

      //   } else {
      //     let dummy=[...priceEmptyError]
      //     let ind=  dummy.findIndex((f) => f.id == accordionID)
      //     if(ind >=0)
      //     dummy.splice(ind,1)
      //     setpriceEmptyError(dummy)

      //   }
       
      // })
    // for (const am in selectedAminities) {
    //   if (selectedAminities[am].mainId == accordionID)
    //   {
    // if(selectedAminities.length === 0){
    //   isPriceValid = false;
    //   // alert(1)
    // } else {

    // for(let i=0; i<= selectedAminities.length-1; i++)
    // {
    //   if(selectedAminities[i].amount<=0 ||
    //     selectedAminities[i].amount == undefined )
    //   { 
    //     isAmountNotEmpty.push(false);
    //     setpriceEmptyError({id:accordionID, error:"Price is not entered for this category"})
    //     // priceRef.current.focus()

    //   } else {
    //     isAmountNotEmpty.push(true);
    //   for (const max in selectedAminities) {

    //     if (selectedAminities[max].mainId == accordionID)
    //       prices = {
    //         ...prices,
    //         [selectedAminities[max].id]: selectedAminities[max].amount,
    //       };
    //   }
    // }
  // }
  // }
// } else {
//   isAmountNotEmpty.push(false);
//   setpriceEmptyError({id:accordionID, error:"Price is not entered for this category"})
//   priceRef.current.focus()
// }
    // }
  // }
  

    let res = isAmountNotEmpty.some(function(amt){
      return amt == false;}); 
    //   console.log("res", res)

    // console.log("price boolean array", isAmountNotEmpty);
    // // console.log(1,shareCapacity.length)
    // console.log("shareCapacity",shareCapacity.length)


    // share capacity validation logic
    let capacity = 0;

    if(shareCapacity.length ===0)
    {
      let dummy = [...sharecapacityemptyError]
      let ind = dummy.findIndex((f) => f.id == accordionID)
      // console.log("ind1", ind)
      if(ind === -1){
       
        setsharecapacityemptyError([...sharecapacityemptyError,{id:accordionID, error:"Share capacity cannot be empty for this category"}])
        ShareCapacityValid=false;

      } 
      // setsharecapacityemptyError([...sharecapacityemptyError,
      //   {
      //     id: accordionID,
      //     error: "Share capacity cannot be empty for this category"
      //   }
      // ]);
    }
    else
    {
      // console.log("shareCapacity", shareCapacity)
      // console.log("accordionID", accordionID)

    let res = shareCapacity.some((f) => f.acdId == accordionID)
    // console.log("res", res)
    if(!res)
    {
      let dummy=[...sharecapacityemptyError];
      // console.log("dummy", dummy)
      let ind = dummy.findIndex((f) => f.id == accordionID)
      // console.log("ind2", ind)
      if(ind == -1){

      setsharecapacityemptyError([...sharecapacityemptyError,{id:accordionID, error:"Share capacity cannot be empty for this category"}])
      ShareCapacityValid=false;
      } 

    } else {
      for (const p in shareCapacity) {
        if (shareCapacity[p].acdId == accordionID)
          capacity = shareCapacity[p].capacity;
      }
      ShareCapacityValid=true;
    }
  }




// pet size validation logic
    let sizes = [];

    if(petSizes.length ==0)
    {
      let dummy = [...petSizeError]
      let ind = dummy.findIndex((f) => f.id == accordionID)
      if(ind === -1){
        setpetSizeError([...petSizeError,{id:accordionID, error:"Pet Size must be checked"}])
        PetSizeValid = false;
        petSizefocusRef.current.focus()

      } 
      // setpetSizeError([...petSizeError,{id: accordionID, error: "Pet Size must be checked"}])

    } 
    
    else {
      let res = petSizes.some((f) => f.acdId == accordionID)
      // console.log("res", res)
      if(!res)
      {
        let dummy=[...petSizeError];
        let ind = dummy.findIndex((f) => f.id == accordionID)
        if(ind == -1)
        setpetSizeError([...petSizeError,{id:accordionID, error:"Pet Size must be checked"}])
      petSizefocusRef.current.focus()
      PetSizeValid = false;


      } else {
        PetSizeValid = true
              for (const s in petSizes) {
        if (petSizes[s].acdId == accordionID)
          // console.log(petSizes[s].id)
          sizes = { ...sizes, [petSizes[s].id]: petSizes[s].id };
      }
      }
      // petSizes.map((item) => {
      //   if(item.acdId !== accordionID)
      //   {
      //     setpetSizeError( [...petSizeError,{id:accordionID, error: "Pet Size must be checked"}])

      //   } else {
      //     let dummy=[...sharecapacityemptyError]
      //     let ind=  dummy.findIndex((f) => f.id == accordionID)
      //     if(ind >=0)
      //     dummy.splice(ind,1)
      //     setpetSizeError(dummy)

      //   }
       
      // })
//     for (const img in petSizes) {
//       if (petSizes[img].acdId == accordionID)
//       {
//     console.log("petSizes", petSizes)
//     if(petSizes.length ==0)
//     {
//       setpetSizeError({id: accordionID, error: "Pet Size must be checked"})
//       isPetSizeValid = false;
//       petSizefocusRef.current.focus()
//     } else {
//       isPetSizeValid = true;
//       for (const s in petSizes) {
//         if (petSizes[s].acdId == accordionID)
//           // console.log(petSizes[s].id)
//           sizes = { ...sizes, [petSizes[s].id]: petSizes[s].id };
//       }
//     }
//   } else {
//     setpetSizeError({id: accordionID, error: "Pet Size must be checked"})
//     isPetSizeValid = false;
//   }
// }
    }
    
 

    // console.log("providerServices", providerServicesAPIData)
    // console.log("accordionID", accordionID)

    const result = providerServicesAPIData.filter((service) => {
        return Number(accordionID) === service.main_categories.id
    })

       console.log("imagesError", imagesError)
    console.log("amenitySelectionError", amenitySelectionError)
    console.log("priceEmptyError", priceEmptyError)
    console.log("sharecapacityemptyError", sharecapacityemptyError)
    console.log("petSizeError", petSizeError)


    // console.log("img", isPriceValid)
    // console.log("img", isShareCapacityValid)
    // console.log("img", isPetSizeValid)
    // console.log("img", isimageValid)
    // console.log("img", isamenitySelectedValid)


// if(isShareCapacityValid && isPetSizeValid
//   && isamenitySelectedValid &&
//   isPriceValid && isimageValid){
//   alert("no error")
// } else {
//   alert("has errors")
// }
    setIsLoading(true);
    setShow(true);
    setTimeout(() => {
      
      checkValidation(result)

    }, 1000);

function checkValidation(result) {
  console.log("result", result)


  // console.log(result.length)
  setIsLoading(false);
  setShow(false);
  console.log(22,ShareCapacityValid, PetSizeValid, amenitySelectedValid,
    PriceValid, imageValid, result )

  if(ShareCapacityValid && PetSizeValid
    && amenitySelectedValid &&
    PriceValid && imageValid && result.length ===0 )
  {

    
    // alert("its passed")
    // saveService();
    console.log(accordionID,
         prices, capacity, sizes,
         images)
    sendServiceData(accordionID,
      prices, capacity, sizes,
      images
      )
  } else if(result.length >0 ) {
    // alert("This service is already added")
    setaddedError(true)

  } 
  else {
    // alert("error in the forms")
    seterrorForm(true)

  }
}

    // console.log("r", result)
   
    //console.log(amenityPrices)
  };


  const sendServiceData  = async (accordionID,
    prices, capacity, sizes,
    images) => {
//  setIsLoading(true);
//     setShow(true);
      await axios({
       method: "post",
       url: API_URL + "user/provider/service/store",
 
       headers: {
         Accept: "application/json",
         Authorization: "Bearer " + userdata.token,
       },
       data: {
         main_category_id: accordionID,
         price: prices,
         share_capacity: capacity,
         pet_size: sizes,
         gallery_images: images,
       },
     })
       .then((response) => {
         // console.log(response);
         if (response.data.success === true) {
           console.log(response);
           // alert("Service is Saved")
           setIsLoading(false);
           setShow(false);
           setOpen(true);
          //  setServiceSavedMessage(true);
           updateProviderServices();
         } else if (response.data.success === false)
           console.log(response.data.data);
       })
       .catch((error) => {
         console.log(error.response.data.errors);
       });
     }


  const handlePetSizes = (e) => {
    // console.log("e", e.target.id)
    // console.log("name", e.target.name)
    let temp=[...petSizeError]
    let ind = temp.findIndex((f) => f.id == accordionID)
    if(ind>=0)
    {
      temp.splice(ind, 1)
    }
    setpetSizeError(temp)
    // setPetSizes([...petSizes, { id: e.target.id, acdId: accordionID }]);
    let indexValue = 0;
    // console.log("accordionID", accordionID)
    let matchedResult = petSizes.filter((pet) => 
      pet.acdId == accordionID)
    indexValue = petSizes.findIndex((i) => 
    i.id == e.target.id && i.acdId == accordionID 
  
    );
    // console.log("indexValue", indexValue)
    if (indexValue >= 0) {
      let temp = [...petSizes];
      temp.splice(indexValue, 1);
      // console.log("temp", temp)
      setPetSizes(temp);
    } else if (indexValue == -1) {
      //         const arrayvalue = [...petSizes];
      // arrayvalue.push({id:e.target.id, name: e.target.value})
      setPetSizes([...petSizes, { id: e.target.id, acdId: accordionID }]);
    }
  };


// useEffect(() => {
//   console.log("base64Image",base64Image)
// },[base64Image])

  function updateProviderServices() {
    fetch(API_URL + "user/provider/service/index", {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        "Authorization": "Bearer " + userdata.token,
      },
    })
      .then((res) => res.json())
      .then((data) => 
      {
console.log("hooks data", data)
setproviderServicesAPIData(data.data)
      }
)
  }

  useEffect(() => {
    updateProviderServices();
  },[])
 

  const fetchCategories = (url) => {
      
    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => 
      {
// console.log("hooks data", data)
 setproviderServicesAPIData(data.data)

dispatch(storeMainCategoriesAction(data.data))
      }
      );

  // return [data];
};
// useEffect(() => {
//   fetchCategories(url1)
//   // dispatch(storeMainCategoriesAction(data))
// },[])


  const savePriceAmenities = (e) => {
    e.preventDefault();

    let dummy=[...priceEmptyError]
    let ind = dummy.findIndex((f) => f.id == accordionID)
    if(ind>=0)
    {
      dummy.splice(ind, 1)
    }
    setpriceEmptyError(dummy)
    let temp = [...selectedAminities]
     selectedAminities.map(function (o) {
      // console.log(e.target.value);
      if (o.name == e.target.name 
        && o.mainId == accordionID) 
        o.amount = e.target.value;
        console.log("o", o)
    let ind = selectedAminities.findIndex((f) => f.id == e.target.id)
console.log("ind", ind)
if(ind >=0 ){
  selectedAminities[ind].amount = e.target.value
} else {
    setselectedAminities([...selectedAminities, o]);

}
      // return o;
    });
  };
  useEffect(() => {
    console.log("slec", selectedAminities)
  }, [selectedAminities]);

  useEffect(() => {
    console.log("amenitySelectionError", amenitySelectionError)
  }, [amenitySelectionError]);

  const getPremiumAminitiesNames = (e) => {
    // console.log(e.target.checked)
    let dummy=[...amenitySelectionError]
    let ind = dummy.findIndex((f) => Number(f.id) == Number(accordionID))
    if(ind>=0)
    {
      dummy.splice(ind, 1)
    }
    setamenitySelectionError(dummy)
    selectedAmenitiesReducer.map((se) => {
      if(se.id == e.target.id )
      {
        se.active= e.target.checked
      }
    })
    let indexvalue = 0;
    indexvalue = selectedAminities.findIndex((i) => i.id == e.target.id);
    if (e.target.checked === false) {
      if (indexvalue >= 0) {
        let tempamenities = [...selectedAminities];
        tempamenities.splice(indexvalue, 1);
        setselectedAminities(tempamenities);
      }
    }
    if (e.target.checked === true) {
      if (indexvalue == -1) {
        const arrayvalue = [...selectedAminities];
        arrayvalue.push({
          id: e.target.id,
          name: e.target.value,
          mainId: accordionID,
        });
        setselectedAminities(arrayvalue);
        console.log(arrayvalue)
      }
      //console.log(selectedAminities)
    }
  };


  const savingAllSericesHandler = () => {
// console.log(shareCapacity)
// console.log(petSizes)
// console.log(selectedAminities)
// console.log(base64Image)
// console.log(100, allAccordionsIDs)
//validations status variables
let isPriceValid = true;
    let isShareCapacityValid= true;
    let isPetSizeValid= true;
    let isimageValid = true;
    let isamenitySelectedValid = true;

    //api variables
   
if(selectedCategory.length == allAccordionsIDs.length )
{
    for(let key in allAccordionsIDs )
    {
      // console.log(1,allAccordionsIDs[key])
      let capacity = 0;
      let prices = [];
      let images = [];
      let sizes = [];
      //share capacity logic
      // console.log("shareCapacity len", shareCapacity.length)
      // console.log("shareCapacity ", shareCapacity)

      if(shareCapacity.length>0 ||shareCapacity.length !==undefined)
      { 
        for(let i=0; i<shareCapacity.length; i++)
        {
          isShareCapacityValid = true;
          if(shareCapacity[i].acdId == allAccordionsIDs[key]){

            capacity=shareCapacity[i].capacity
          }
        }

      } else {
        isShareCapacityValid = false;

        setsharecapacityemptyError( all => [...all,{id:accordionID, error: "Share capacity cannot be empty for this category"}])
        shareCapacityFocusRef.current.focus()
      }
// console.log("isShareCapacityValid", isShareCapacityValid)
      //images logic
      if(base64Image.length>0)
      {
        for(let i=0; i<base64Image.length; i++)
        {
          isimageValid = true;
          if(base64Image[i].accordiongId == allAccordionsIDs[key]){

            images.push(base64Image[i].code)
          }
        }

      } else {
        setimagesError({id:accordionID, error: "Image must be selected"})
        isimageValid = false;
      }
      // console.log("isimageValid", isimageValid)

      //petsize logic
      if(petSizes.length>0)
      {
        for(let i=0; i<petSizes.length; i++)
        {
          isPetSizeValid = true;
          if(petSizes[i].acdId == allAccordionsIDs[key]){

            sizes = { ...sizes, [petSizes[i].id]: petSizes[i].id };
            // sizes.push(petSizes[i].id)
          }
        }

      } else {
        setpetSizeError({id: accordionID, error: "Pet Size must be checked"})
        isPetSizeValid = false;
      petSizefocusRef.current.focus()
      }
      // console.log("sizes", sizes)
      // console.log("isPetSizeValid", isPetSizeValid)

      //price logic
      if(selectedAminities.length>0)
      {
        for(let i=0; i<selectedAminities.length; i++)
        {
          isPriceValid = true;
          if(selectedAminities[i].mainId == allAccordionsIDs[key]){

            prices = {
              ...prices,
              [selectedAminities[i].id]: selectedAminities[i].amount,
            };
          }
        }

      } else {
        isPriceValid = false;
        setpriceEmptyError({id:accordionID, error:"Price is not entered for this category"})
        // priceRef.current.focus()
      }
// console.log("prices", prices)
// console.log("isamenitySelectedValid", isamenitySelectedValid)

// validation of main cateogyr if its not added already

const result = providerServicesAPIData.filter((service) => {
  return Number(allAccordionsIDs[key]) === service.main_categories.id
})
// console.log("resullt", result)

console.log("api", allAccordionsIDs[key],   prices, capacity, sizes,   images)

      //validation of all data
      setIsLoading(true);
      setShow(true);
      setTimeout(() => {
        checkValidation(result)
      }, 1000);
  
  function checkValidation(result) {
    setIsLoading(false);
  setShow(false);
  console.log(2323, 
    isShareCapacityValid, isPetSizeValid
      , isamenitySelectedValid,
      isPriceValid, isimageValid, result.length )
    if(isShareCapacityValid && isPetSizeValid
      && isamenitySelectedValid &&
      isPriceValid && isimageValid && result.length ===0 )
    {
      // alert("its passed")
  // console.log("api", allAccordionsIDs[key],   
  // prices, capacity, sizes,   images)
      sendServiceData(allAccordionsIDs[key],
        prices, capacity, sizes,
        images
        )
        // navigate("/p-profile")

    } else if(result.length >0 ) {
      // alert("This service is already added")
      setaddedError(true)
    } 
    else {
      // alert("error in the forms")
      seterrorForm(true)
    }
  }
    }
  } else {
    
alert("fill service details")
  }
  }

  // useEffect(() => {
  //   console.log("sharecapacityemptyError",sharecapacityemptyError)
  //   console.log("imagesError",imagesError)

  // },[sharecapacityemptyError,imagesError])
 
//  console.log("setselectedAminities", selectedAminities)
  return (
    <section className="p-0">
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="col-6 p-0">
            <div
              className="sp-bg"
              style={{
                background: `url('/images/sp-bg-3.jpg')`,
                backgroundTop: "top",
                backgroundCenter: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <div className="sp-bg-content-box">
                <h1 className="py-5">
                  Tell us
                  <br />
                  about your place
                </h1>
              </div>
              <div className="bottom-content">
                In this step, we’ll ask you which type of property you have and
                if guests will book the entire place or just a room. then let us
                know the location and how many guests can stay.
              </div>
            </div>
          </div>

          <div className="col-6 p-0">
            <div className="sp-content">
              <div className="float-end">
                <a href="#" className="btn btn-transparent btn-back me-1">
                  Help
                </a>
                {/* <a href="#" className="btn btn-transparent btn-back">
                  Save & Continue
                </a> */}
              </div>
              <Snackbar
                style={snackbarBoxStyling}
                open={addedError}
                autoHideDuration={500}
                onClose={handleCloseSnackBar}
              >
                <Alert
                  onClose={handleCloseSnackBar}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                 This serivce is already added
                </Alert>
              </Snackbar>
              <Snackbar
                style={snackbarBoxStyling}
                open={accordionNotSelected}
                autoHideDuration={500}
                onClose={handleCloseSnackBar}
              >
                <Alert
                  onClose={handleCloseSnackBar}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                 This serivce is already added
                </Alert>
              </Snackbar>
              <Snackbar
                style={snackbarBoxStyling}
                open={errorForm}
                autoHideDuration={900}
                onClose={handleCloseSnackBar}
              >
                <Alert
                  onClose={handleCloseSnackBar}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  There is/are some error(s) in the form.
                </Alert>
              </Snackbar>
              <Snackbar
                style={snackbarBoxStyling}
                open={businessDetailSuccessMsg}
                autoHideDuration={500}
                onClose={handleCloseSnackBar}
              >
                <Alert
                  onClose={handleCloseSnackBar}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Business Detail is Updated
                </Alert>
              </Snackbar>

              <Snackbar
                style={snackbarBoxStyling}
                open={businessFieldsErrorMsg}
                autoHideDuration={500}
                onClose={handleCloseSnackBar}
              >
                <Alert
                  onClose={handleCloseSnackBar}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {fieldErrorMsg}
                </Alert>
              </Snackbar>
              
              <div className="clearfix"></div>
              <div className="sp-form">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item >
                    <Accordion.Header>Business Details</Accordion.Header>
                    <Accordion.Body>
                    
                      <form method="post" action="" id="pdcform" role="form">
                        <div className="form-group position-relative mb-3">
                        {
                  openFieldErrorMsg   && 
                <p style={{color: "red"}}>{fieldErrorMsg}</p>
              }
                        <div className="form-title">Store Name</div>
                          <input
                            type="text"
                            className="form-control"
                            name="store_name"
                            id="store_name"
                            defaultValue={
                              businessDetails
                                ? businessDetails.providers.store_name
                                : "Your Business Name"
                            }
                            onChange={handleChange}
                            required
                          />
                          <div className="custom-icon">
                            <i className="bi bi-person-fill"></i>
                          </div>
                        </div>

                        <div className="form-group mb-3">
                        <div className="form-title">About Us</div>

                          <textarea
                            className="form-control"
                            name="about_us"
                            id="about_us"
                            rows="3"
                            defaultValue={
                              businessDetails
                                ? businessDetails.providers.about_us
                                : "A short description about place"
                            }
                            onChange={handleChange}
                          ></textarea>
                        </div>
                    
                        <div className="form-group mb-3">
                        <div className="form-title">Phone</div>
                          <input
                            type="tel"
                            id="phone"
                            className="form-control mb-3"
                            defaultValue={businessDetails.phone}
                            placeholder="Phone"
                            maxLength="12"
                            onChange={handleChange}
                            required
                          />
                        </div>
                            
                        <div className="form-title">Operation Timings</div>
                        {
                                timeIsEmpty && (
                                  <p style={{color: "red"}}>One of Operation Timing is empty</p>
                                )
                              }
                        <table className="table table-borderless align-middle">
                          <thead>
                        

                              
                            <tr>
                              <th></th>
                              <th>Open</th>
                              <th>Close</th>
                              <th>Closed</th>
                            </tr>
                          </thead>
                          <tbody>
                            
                            {
                              userDataRedux.data.data.providers.provider_working_hours.map((time) => {
                                return (
                                  <tr>
                                  <td>{time.week}</td>
                                  <td>
                                    <div className="input-group">
                                <input className="form-control" 
                                defaultValue = {time.open_time}
                                id={time.week + "_"+ "open_time"}
                                onChange={handleTimeChange}
                                type="time"  name="open_time"/>
                                    </div>
                                    </td>
                                    <td>
                                    <div className="input-group">
                                <input className="form-control" 
                                value = {time.close_time}
                                onChange={handleTimeChange}
                                type="time" id={time.week + "_"+ "close_time"}
                                 name="close_time"/>
                                    </div>
                                  </td>
                                  <td className="text-center">
                                    <label className="customcheck">
                                      <input
                                        type="checkbox"
                                        name="closing-day"
                                        id={time.week+ "_"+ "dayoff"}
                                        onChange={handleTimeChange}
                                        defaultChecked={
                                          time.time_diss == 1
                                        }
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </td>
                                </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                        <div className="form-title mb-2">Your Address</div>
                        <p>{address}</p>
                        <div className="form-group position-relative mb-3">
                          <GooglePlacesAutoComplete
                            sendData={sendData}
                            sendData1={sendData1}
                          />
                          <div className="custom-icon">
                            <i className="bi bi-geo-alt-fill"></i>
                          </div>
                        </div>

                        {/* <iframe
                          src={updatedLocationURL}
                          width="100%"
                          height="240"
                          style={{ border: "0" }}
                          allowFullScreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        ></iframe> */}
                        <Map
                          google={cordinates}
                          center={{
                            lat: autoAddressLatLong
                              ? autoAddressLatLong.lat
                              : userdata.data.providers.latitude,
                            lng: autoAddressLatLong
                              ? autoAddressLatLong.lng
                              : userdata.data.providers.latitude,
                          }}
                          height="200px"
                          zoom={15}
                        />

                        <div className="row mt-3 clearfix">
                          <div className="col-6">
                            <div className="form-title mb-2">
                              Skills & Qualifications
                            </div>
                            {
                                skillsSelectionStatus && (
                                  <p style={{color: "red"}}>Skills must be selected</p>
                                )
                              }
                            <div className="form-group align-middle">
                              <select
                                className="form-select"
                                onChange={handleSkills}
                              >
                               
                                <option> - Select - </option>
                                {
                                  skillsQualification.map((skill, index) => {
                                 return (
                                <option key={ skill.id}
                                defaultValue={skill.id}
                               selected={skill.name == businessDetails.providers.skills_and_qualification}
                                >
                                  {skill.name}
                                </option>
                                 )
                                 })
                                }
                              </select>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-title mb-2">
                              Certification
                              <span>(if available)</span>
                            </div>
                            <div className="file-upload">
                              <input
                                type="file"
                                id="file"
                                accept="image/jpg, application/pdf"
                                onChange={handlePdfConvert}
                              />
                              {
                                certificationIsemptyStatus && (
                                  <p style={{color: "red"}}>Certification must be uploaded</p>
                                )
                              }
                              <div>
                                <i className="bi bi-plus-circle-fill"></i>
                                Image
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* show pdf or image */}

                        <div style={{ display: "flex",
                      flexDirection: 'row', flexWrap: "wrap" }}>
                          {certificationFromApi
                            ? certificationFromApi.map((img, index) => {
                                return (
                                    <div
                                      className=" position-relative"
                                      
                                      key={index}
                                    >
                                      <img
                                     
                                        src={IMG_URL+img}
                                        width="100px"
                                        height="100px"
                                        
                                      />
                                      <i
                                        className="bi bi-x "
                                        id={index}
                                        style={
                                          deleteImageIcon
                                        }
                                        onClick={ handlerdeleteImageFromAPI}
                                        key={img}
                                       
                                      ></i>
                                    </div>
                                );
                              })
                            : null}
                        </div>
                        <div style={{ display: "flex" }}>
                          {base64PDF
                            ? base64PDF.map((img, index) => {
                                return (
                                  <>
                                    <div
                                      className=" position-relative"
                                      style={{
                                        width: "20%",
                                        height: "20%",
                                      }}
                                      key={index}
                                    >
                                      <embed
                                        src={img}
                                        type="application/pdf"
                                        width="100px"
                                        height="100px"
                                      />
                                      <i
                                        className="bi bi-x "
                                        id={index}
                                        style={deleteImageIcon}
                                        key={img}
                                        onClick={removeItems}
                                      ></i>
                                    </div>
                                  </>
                                );
                              })
                            : null}
                        </div>

                        <div className="form-title mb-3 mt-3">
                          Supervison Level
                        </div>
                        {
                                SuperVisionLabelActiveState && (
                                  <p style={{color: "red"}}>Supervision must be selected</p>
                                )
                              }
                        <div className="row mb-3 mt-3 clearfix">

                          {
                            combinedSuperVision.map((level, index) =>{
                              return (
                            <div key={index} className="col-6">
                            <div className="customradio">
                              <input
                                type="checkbox"
                                id={index}
                                defaultValue={level.p}
                                name="supervisionlevel"
                                onChange={updateSuperVision}
                                defaultChecked={level.status === 1}
                                
                              />
                              <label htmlFor="sharedpetcare">
                                {level.p}
                              </label>
                            </div>
                          </div>
                              )
                            })
                          }
                          
                        
                        </div>

                        <div className="text-center">
                          <input
                            type="submit"
                            name="submit"
                            id="submit"
                            className="btn btn-theme"
                            defaultValue="SAVE"
                            onClick={uploadBusinessDetails}
                          />
                        </div>

                        <div>
                          {isLoading && (
                            <Modal show={show} onHide={handleClose}>
                              <Modal.Body>
                                {" "}
                                <LoadingSpinner />{" "}
                              </Modal.Body>
                            </Modal>
                          )}
                        </div>
                      </form>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion defaultActiveKey="0">
                  {selectedCategory?.map((item, index) => {
                    return (
                      <Accordion.Item eventKey={item.id} key={item.id}>
                        <div onClick={() => fetchAmenitiesNames(item.id)}>
                          <Accordion.Header id={item.id}>
                            {item.name}
                          </Accordion.Header>
                        </div>
                        <Accordion.Body>
                          <form
                            method="post"
                            action=""
                            id="pdcform"
                            // onSubmit={handleServicesSaveInfo}
                          >
                            <div className="form-title mb-2 mt-2">
                              Share Capacity
                            </div>
                           {/* {
                            sharecapacityemptyError?.id ==accordionID && (
                              <p 
                              style={{color: "red"}}>{sharecapacityemptyError?.error}</p>
                            )
                           } */}
                            <div className="form-group position-relative mb-3">
                              <input
                                type="number"
                                ref={shareCapacityFocusRef}
                                className={formControlInputClass}
                                id="sharecapacity"
                                min="0"
                                placeholder="How many dogs can you care for?"
                                // onBlur={handleBlur}
                                defaultValue={shareCapacity.filter((f) => f.acdId==accordionID)}
                                onChange={updateShareCapacity}
                                required
                              />
                              {
                                sharecapacityemptyError?.map((i) => {
                                  if(Number(i.id)  === Number(accordionID)) {
                                    return (
                                    <p style={{color: "red"}}>{i.error}</p>)
                                  }
                                })
                              }
                              <div className="custom-icon">
                                <i className="fa fa-paw"></i>
                              </div>
                            </div>
                            <div className="form-title mb-3 mt-3">
                              Accepted Pet Size
                            </div>
                            {
                                petSizeError?.map((i) => {
                                  if(Number(i.id)  === Number(accordionID)) {
                                    return (
                                    <p style={{color: "red"}}>{i.error}</p>)
                                  }
                                })
                              }
                           
                            <div className="row clearfix">
                              <div className="col-4">
                                <label className="customcheck">
                                  <input
                                    type="checkbox"
                                    ref={petSizefocusRef}
                                    name="1-5 kg"
                                    id="1-5 kg"
                                    onChange={handlePetSizes}
                                  />
                                  <span className="checkmark"></span>
                                  1-5 Kg
                                </label>
                              </div>
                              <div className="col-4">
                                <label className="customcheck">
                                  <input
                                    type="checkbox"
                                    name="5-10 kg"
                                    id="5-10 kg"
                                    onChange={handlePetSizes}
                                  />
                                  <span className="checkmark"></span>
                                  5-10 Kg
                                </label>
                              </div>
                              <div className="col-4">
                                <label className="customcheck">
                                  <input
                                    type="checkbox"
                                    name="10-20 kg"
                                    id="10-20 kg"
                                    onChange={handlePetSizes}
                                  />
                                  <span className="checkmark"></span>
                                  10-20 Kg
                                </label>
                              </div>
                              <div className="col-4">
                                <label className="customcheck">
                                  <input
                                    type="checkbox"
                                    name="20+ kg"
                                    id="20+ kg"
                                    onChange={handlePetSizes}
                                  />
                                  <span className="checkmark"></span>
                                  20+ Kg
                                </label>
                              </div>
                            </div>
                            <div className="form-title mb-2 mt-2">
                              Set Amenities
                            </div>
                            
                            <div className="amenities-box">
                              <div className="form-subtitle mb-2">
                                Essential Amenities
                              </div>
                              {
                                amenitySelectionError ?.map((i) => {
                                  if(Number(i.id)  === Number(accordionID)) {
                                    return (
                                    <p style={{color: "red"}}>{i.error}</p>)
                                  }
                                })
                              }
                            
                             
                              <ul className="amenities-select">
                              
                                {selectedAmenitiesReducer
                                  ? selectedAmenitiesReducer.map(
                                      (cat, index) => {
                                        if (Number(cat.premium) === 0 
                                        && Number(cat.main_category_id == accordionID)
                                        && cat.status ==1
                                        )
                                          return (
                                            <li key={index}>
                                              <div className="amenities-checkbox petdaycare">
                                                <input
                                                  type="checkbox"
                                                  id={cat.id}
                                                  defaultValue={cat.sub_category_name}
                                                  onClick={getAminitiesNames}
                                                  defaultChecked={cat.active}
                                                />
                                                <div>
                                                  <img
                                                    src={IMG_URL + cat.image}
                                                  />
                                                  {cat.sub_category_name}
                                                </div>
                                              </div>
                                            </li>
                                          );
                                      }
                                    )
                                  : null}
                              </ul>
                              <div className="form-subtitle mb-2">
                                Premium Amenities
                              </div>
                              <ul className="amenities-select">
                                {selectedAmenitiesReducer
                                  ? selectedAmenitiesReducer.map(
                                      (cat, index) => {
                                        if (Number(cat.premium) === 1 
                                        && Number(cat.main_category_id == accordionID)
                                        && cat.status ==1)
                                          return (
                                            <li key={index}>
                                              <div className="amenities-checkbox bath">
                                                <input
                                                  type="checkbox"
                                                  id={cat.id}
                                                  defaultValue={cat.sub_category_name}
                                                  min="0"
                                                  onClick={
                                                    getPremiumAminitiesNames
                                                  }
                                                  defaultChecked={cat.active}
                                                />
                                                <div>
                                                  <img
                                                    src={IMG_URL + cat.image}
                                                  />
                                                  {cat.sub_category_name}
                                                </div>
                                              </div>
                                            </li>
                                          );
                                      }
                                    )
                                  : null}
                              </ul>
                            </div>
                            <div className="form-title mb-2">Set Price</div>
                            {
                                priceEmptyError?.map((i) => {
                                  if(Number(i.id)  === Number(accordionID)) {
                                    return (
                                    <p style={{color: "red"}}>{i.error}</p>)
                                  }
                                })
                              }
                            
                            <ul className="set-price">
                              {selectedAminities?.map((amenity, index) => {
                                if (Number(amenity.mainId) == accordionID) {
                                  // console.log("amenity", amenity)
                                  // console.log("accordionID", accordionID)

                                  return (
                                    <li key={amenity.id}>
                                      {amenity.name}
                                      <span>
                                        <i className="fa fa-inr"></i>
                                        <input
                                          type="number"
                                          id={amenity.id}
                                          name={amenity.name}
                                          ref={priceRef}
                                          defaultValue={amenity.amount}
                                          min="1"
                                          // onChange={e => setAmenityPrices({...amenityPrices, [e.target.id]:e.target.value})}/>
                                          onChange={savePriceAmenities}
                                          required
                                        />
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                            <div className="form-title mb-2">Add Pictures</div>


                            <ul className="amenities-select">
                              <li>
                                <div className="file-upload">
                                  {/* <input type="file" id="image" multiple
                                                    /> */}
                                  <div>
                                    <i className="bi bi-plus-circle-fill">
                                      <input
                                        onChange={checkSizeValidation}
                                        type="file"
                                        id="img"
                                        name="img"
                                        accept="image/jpg"
                                        required
                                        multiple
                                      />
                                    </i>
                                    Image
                                  </div>
                                </div>
                              </li>
                            </ul>
                            {/* {
                            imagesError?.id == accordionID && (
                              <p 
                              style={{color: "red"}}>{imagesError?.error}</p>
                            )
                           } */}
                             {
                                imagesError?.map((i) => {
                                  if(Number(i.id)  === Number(accordionID)) {
                                    return (
                                    <p style={{color: "red"}}>{i.error}</p>)
                                  }
                                })
                              }
                            <div style={{ display: "flex" }}>
                              {base64Image
                                ? base64Image.map((img) => {
                                    if (img.accordiongId == accordionID) {
                                      return (
                                        <>
                                          <div
                                            className=" position-relative"
                                            style={{
                                              width: "20%",
                                              height: "20%",
                                            }}
                                            key={img.id}
                                          >
                                            <img
                                              src={img.code}
                                              key={img}
                                              ref={picturesfocusRef}
                                              style={{
                                                display: "inline",
                                                width: "100px",
                                                height: "100px",
                                              }}
                                            />
                                            <i
                                              className="bi bi-x "
                                              id={img.id}
                                              style={deleteImageIcon}
                                              key={img.id}
                                              onClick={removeImage}
                                            ></i>
                                          </div>
                                        </>
                                      );
                                    }
                                  })
                                : null}
                            </div>

                            <div className="text-center">
                              <input
                                type="submit"
                                name="submit"
                                id="submit"
                                className="btn btn-theme"
                                defaultValue="SAVE"
                                onClick={addServiceDetails}
                              />
                            </div>

                            <Snackbar
                              style={snackbarBoxStyling}
                              open={open}
                              autoHideDuration={900}
                              onClose={handleCloseSnackBar}
                            >
                              <Alert
                                onClose={handleCloseSnackBar}
                                severity="success"
                                sx={{ width: "100%" }}
                              >
                                Service is saved
                              </Alert>
                            </Snackbar>
                          </form>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>

                <div className="d-flex justify-content-center mt-3">
                  <a
                    onClick={() => navigate(-1)}
                    className="btn btn-transparent btn-back"
                  >
                    <i className="bi bi-arrow-left-short"></i> Back
                  </a>
                  <a
                    className="btn btn-theme"
                    // onClick={savingAllSericesHandler}
                    onClick={() => navigate(-4)}
                  >
                    Next
                    <i className="bi bi-arrow-right-short"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Step3;
