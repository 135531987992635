import {SET_NAME, 
  SET_PROFILE_IMAGE, LOG_OUT, 
  GET_BANNERS, 
  SET_NAVBAR_SEARCH_DROPDOWN_DISPLAY_STATUS,
  SET_BANNER_SEARCH_DROPDOWN_DISPLAY_STATUS,
  SET_PROVIDER_SERVICES,
  SET_SUB_CATEGORIES,
  STORE_EDIT_DATA,
  STORE_MAIN_CATEGORIES_DATA,
  STORE_AMENITIES_FOR_SELECTED_SERVICE_ADD_MODULE,
  STORE_AUTO_ADDRESS_LOCATION,
  SET_LOGIN_STATUS,
  SET_USER_DATA,
  SET_ACCORDION_AMENITIES_DATA,
  SET_ACCORDION_MAIN_CAT_IDS,
  SET_ACCORDION_CHECKED_STATUS
} from '../constant'


export const fullName = (data='', action) => {

  switch(action.type) {
    case SET_NAME: 
    return action.name;

    default: return data;
  }
}

export const storeUserDataReducer = (data=[], action) => {

  switch(action.type) {
    case SET_USER_DATA: 
    return action;

    default: return data;
  }
}

export const storeAccordionAllAmenitiesReducer = (data=[], action) => {

  switch(action.type) {
    case SET_ACCORDION_AMENITIES_DATA: 
    return action.data;

    default: return data;
  }
}

export const storeAccordioncheckedStatusReducer = (data=[], action) => {

  switch(action.type) {
    case SET_ACCORDION_CHECKED_STATUS: 
    return action.data;

    default: return data;
  }
}

export const storeAccordionMainCatIDSReducer = (data=[], action) => {

  switch(action.type) {
    case SET_ACCORDION_MAIN_CAT_IDS: 
    return action.data;

    default: return data;
  }
}

export const profileImage = (data='', action) => {
  switch(action.type) {
    case SET_PROFILE_IMAGE: 
    return { image: action.image};

    default: return data;
  }
}


export const logout = (data='', action) => {

  switch(action.type) {

    case LOG_OUT:     

    return action.data;

    default: return data;
  }
}

export const banners = (data=[], action) => {
// console.log(action.images)
  switch(action.type) {

    case GET_BANNERS:     

    return action.images;

    default: return data;
  }
}


export const searchNavBarDropdownDisplayStatus = (data='', action) => {


  switch(action.type) {
    case SET_NAVBAR_SEARCH_DROPDOWN_DISPLAY_STATUS:
      return action.status;

      default: return data;
  }
}

export const searchBannerDropdownDisplayStatus = (data='', action) => {


  switch(action.type) {
    case SET_BANNER_SEARCH_DROPDOWN_DISPLAY_STATUS:
      return action.status;

      default: return data;
  }
}

export const getProviderServicesAction = (data='', action) => {

  switch(action.type) {
    case SET_PROVIDER_SERVICES:
      return action.serv;

      default: return data;
  }
}


export const getSubCategories = (data='', action) => {

  switch(action.type) {
    case SET_SUB_CATEGORIES:
      return action.subCat;

      default: return data;
  }
}


export const getEditDataReducer = (data='', action) => {

  switch(action.type) {
    case STORE_EDIT_DATA:
      return action.data;

      default: return data;
  }
}


export const storeMainCategoriesReducer = (data='', action) => {
  switch(action.type){
    case STORE_MAIN_CATEGORIES_DATA:
      return action.data;

      default: return data;
  }
}

export const storeAmenitiesForSelectedServiceReducer = (data='', action) => {
  switch(action.type){
    case STORE_AMENITIES_FOR_SELECTED_SERVICE_ADD_MODULE:
      return action.data;

      default: return data;
  }
}

export const storeAutoAddressLatLongReducer = (data='', action) => {
  switch(action.type){
    case STORE_AUTO_ADDRESS_LOCATION:
      return action.data;

      default: return data;
  }
}

export const storeLoginStatusReducer = (data='', action) => {
  switch(action.type){
    case SET_LOGIN_STATUS:
      return action.payload;

      default: return data;
  }
}