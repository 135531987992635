import React, { useEffect,useState} from 'react'
import { API_URL, IMG_URL } from "../constant";
import { getProviderServicesAction } from "../redux/actions/action";
import { useDispatch} from 'react-redux'



const FetchExistingServices = () => {
  const dispatch = useDispatch();
  const userdata = JSON.parse(window.localStorage.getItem("paw5-login-data"))

  // console.log("existing services called")
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(API_URL+'user/provider/service/index', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer '+ userdata.token,
    },
    })
      .then((res) => res.json())
      .then((data) => 
      {
// console.log("hooks existing", data)
dispatch(getProviderServicesAction(data.data))
      }
// setData(data.data)
      );
  }, []);

  return [data];
};

export default FetchExistingServices;
