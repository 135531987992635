import React, { useState, useEffect, useMemo} from 'react'
import StepOne from './Step1'
import { useNavigate } from "react-router-dom";
import { API_URL, IMG_URL } from "../../constant";
import axios from "axios";
import ServiceEdit from './ServiceEdit'
import { useDispatch} from 'react-redux'
import { useSelector} from 'react-redux';
import {editService} from '../../redux/actions'
import { getProviderServicesAction } from "../../redux/actions/action";
import { getSubCategories } from "../../redux/actions/action";
import LoadingSpinner from './LoadingSpinner'
import Modal from 'react-bootstrap/Modal';
import { storeEditData } from "../../redux/actions/action";
// import Categories from '../../hooks/CategoriesFetch'
import { storeMainCategoriesAction } from "../../redux/actions/action";
import ProviderDashboard from '../../pages/ProviderDashboard';
import  ProviderSideMenu from './ProviderSideMenu';


const Services = (props) => {
    const navigate = useNavigate(); 
    const userdata = JSON.parse(window.localStorage.getItem("paw5-login-data"))
    const [existingServices, setExistingServies] = useState([])
    const [fullAmenities, setFullAmenities] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [providerServiceData,setProviderServiceData]= useState([])
    const [openEdit, setOpenEdit] = useState(true)
    const [allData, setAllData] = useState([])
    const dispatch = useDispatch();
    const providerServices = useSelector((state) => state.getProviderServicesAction);
    const serviceData = useSelector((state) =>state.serviceData )
    const [selectedAminities, setselectedAminities] = useState([]);
    const [show, setShow] = useState(false);
    const url = API_URL+'search/main-category-name'
    // const [data] = Categories(url);
// console.log("providerServices", providerServices)
   

    const fetchCategories = (url) => {
      
        fetch(url, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
          },
        })
          .then((res) => res.json())
          .then((data) =>{ 
    // console.log("hooks data", data)
dispatch(storeMainCategoriesAction(data.data))
          }
          );

      // return [data];
    };
    useEffect(() => {
      fetchCategories(url)
      getNewCategories();
      // dispatch(storeMainCategoriesAction(data))
    },[])
const addServices = () => {
     navigate("/step1");

}

function getNewCategories() {
  fetch(API_URL+'user/provider/service/index',
{
    method: 'GET',
    headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer '+ userdata.token,
    },
}).then((res) => res.json())
.then((data) => {
console.log(data.data)
dispatch(getProviderServicesAction(data.data))
setExistingServies(data.data)
})
// setOpenEdit(true)
}

useEffect(() => {
  // console.log("prov", providerServices)
  setExistingServies(providerServices)



// 
//console.log(data.data));
},[providerServices])




async function getCategories(id) { 
    await fetch(API_URL+'service/sub-categories/'+id,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ userdata.token,
        },
    }).then((res) => res.json())
    .then((data) =>
    {
    if(data.success === true){
    //  console.log("getcat", data)
    dispatch(getSubCategories(data.data))
     setAllData(data.data)
    }
    })
}

const EditService = async (id) => {
    //dispatch(editService(id))
    // dispatch(editServiceData)
// console.log(serviceData)

    //props.sendStatus("sdsds")
    // console.log(id)
    setIsLoading(true)
    setShow(true);
     await   fetch('https://paw5.digiinteracts.in/api/user/provider/service/edit', {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ userdata.token
        },
        body: JSON.stringify({ provider_service_id: id }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("api data", data.data);
        setProviderServiceData(data.data)
        getCategories(data.data[0].main_category_id)
        dispatch(storeEditData(data.data))
        setShow(false);
        setIsLoading(false)
        // setTimeout(setIsLoading(false), 5000);
        // collectEmenities()
        // setOpenEdit(false)
        // console.log(1)
        navigate('/editService', {
        state:{ data}
        }
        )
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }

    async   function  validateData  () {
     
      if(providerServiceData)
      {
        setShow(false);
        setIsLoading(false)
        // console.log("validate", providerServiceData)
      } else {
        // console.log("empty")
      }
    }


 
    // useEffect(() => {
    //  console.log(allData.length)
    //  console.log(providerServiceData.length)
    //  callme();
    // },[]) {
   
    
    // useEffect(() => {
    //   setTimeout(render, 5000);
    // },[])

    // function collectEmenities() { 
     
    //     let allAme=providerServiceData[0].provider_sub_service;
    //     let anotherTemp=[];
    //     let userSelectedData=allData
    //     // console.log("all", allData)
    //     // console.log("api", providerServiceData[0].provider_sub_service[0].sub_categories)

    //     allAme.map((d) => {
    //       userSelectedData.filter((t) => {
    //         if(t.sub_categories.sub_category_name == d.sub_category_name){
    //           if(anotherTemp.length ==0) {
    //           anotherTemp.push({
    //             id: d.id,
    //             status: 1,
    //             main_category_id: d.main_category_id,
    //             image: d.image,
    //             sub_category_name: d.sub_category_name,
    //             premium: d.premium,
    //           })
    //         }
    //         else {              
    //           let ind = anotherTemp.findIndex((fin) => 
    //           fin.sub_category_name === d.sub_category_name)
    //           if(ind < 0){
    //           anotherTemp.push({
    //             id: d.id,
    //             status: 1,
    //             main_category_id: d.main_category_id,
    //             image: d.image,
    //             sub_category_name: d.sub_category_name,
    //             premium: d.premium,
    //           })
    //         }
    //         }
    //       }
    //         else {
    //           if(anotherTemp.length ==0) {
    //           console.log(0)
    //           }
    //           else {
    //             console.log(anotherTemp)
    //             let ind = anotherTemp.findIndex((fin) => 
    //             fin.sub_category_name !== d.sub_category_name)
    //             console.log(t.sub_categories.sub_category_name)
    //             console.log(d.sub_category_name)
    //             console.log(ind)
    //             if(ind < 0){
    //               anotherTemp.push({
    //                 id: d.id,
    //                 status: 0,
    //                 main_category_id: d.main_category_id,
    //                 image: d.image,
    //                 sub_category_name: d.sub_category_name,
    //                 premium: d.premium,
                   
    //               })
    //             }
    //           } 
    //         }
    //       })
    //     })
    //     // console.log("another", anotherTemp)
    //     setselectedAminities(anotherTemp)
    //   }
    



    const DeleteService = (id) => {
        // console.log(id)
        setIsLoading(true)
    setShow(true);
        fetch('https://paw5.digiinteracts.in/api/user/provider/service/destroy', {
            method: 'POST', // or 'PUT'
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ userdata.token
            },
            body: JSON.stringify({id: id}),
          }).then((response) => response.json())
            .then((data) => {
              // console.log(data);
              
              getNewCategories();
              setShow(false);
        setIsLoading(false)
            })
            .catch((error) => {
              console.error('Error:', error);
            });
        }

  return (
    <>
    <section>
    <div className="container">
        <div className="row clearfix">
  <ProviderSideMenu/>
    <div className="col-12 col-md-9 col-lg-8 col-xl-8">
         {
        openEdit && (
            <>
    <div className="dashboard-title">My Services<span><a onClick={addServices}
    style={{cursor: "pointer"}}>Add Services</a></span></div>
    <div className="d-block box-shadow p-4 mt-3 mb-3">                            
        <table className="table align-middle add-service">
        {isLoading && (
                <Modal show={show} onHide={handleClose}>
                  <Modal.Body>
                    {" "}
                    <LoadingSpinner />{" "}
                  </Modal.Body>
                </Modal>
              )}
            <tbody>
           
           
           
                {
                    existingServices.length>0 ? existingServices.map((service) => {
                        return (
                            <tr key={service.id}>
                            <td className="w-10">
                                <img src="images/icons/pet-day-care.png" /></td>
                            <td>{service.main_categories.main_category_name}</td>
                            <td className="w-15">
                                <a className="me-2">
                            <i className="bi bi-pencil-fill"
                            style={{
                                cursor: "pointer"
                            }}
                            onClick={ e => EditService(service.id)}
                            ></i>
                           
                            </a>
                             <a >
                                <i className="bi bi-trash-fill"
                                style={{
                                    cursor: "pointer"
                                }}
                                onClick={(e) => DeleteService(service.id)}
                                ></i>
                                </a>
                                </td>
                        </tr>
                        )
                    }) :
                    <tr>
                    <td style={{textAlign: "center"}}>No Service is added</td>
                    </tr>
                }
            </tbody> 
            
        </table>
    </div>
    </>
        )}
        {
            !openEdit && (
                <ServiceEdit 
                providerServiceData={providerServiceData} 
                // allData={allData}
                // selectedAminities={selectedAminities}
                />
            )
        }
        
</div>
</div>
</div>
</section>
</>
  )
}

export default Services