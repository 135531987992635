import React, { useState, useEffect, useMemo} from 'react'
import Profile from '../components/UserDashboard/Profile'
import Inbox from '../components/UserDashboard/Inbox'
import Favourite from '../components/UserDashboard/Favourite'
import Help from '../components/UserDashboard/Help'
import ManagePassword from '../components/UserDashboard/ManagePassword'
import Pets from '../components/UserDashboard/Pets'
import Reviews from '../components/UserDashboard/Reviews'
import MyOrders from '../components/UserDashboard/MyOrders'
import { API_URL, IMG_URL } from "../constant";
import { useSelector} from 'react-redux';
import { searchNavBarDropdownDisplayStatus } from "../redux/actions/action";
import { useDispatch } from "react-redux";


const Dashboard = () => {
    const dispatch = useDispatch();

    const userData = JSON.parse(window.localStorage.getItem("paw5-login-data"));
    const profileImage = useSelector((state) =>state.profileImage )
// console.log(profileImage)
const userName = useSelector((state) =>state.fullName )
const [userFullName, setUserFullName] = useState()
const [profileLogo, setProfileLogo] = useState()
const [ userLocalStorageData, setuserLocalStorageData] = useState()
// console.log(userName)
    // const [userProfilePic, setuserProfilePic] = useState()
    // const [ userData, setuserData] = useState()
    const [profileStatus, setProfileStatus] = useState(true);
    const [ordersStatus, setOrdersStatus] = useState(false);
    const [inboxStatus, setInboxStatus] = useState(false);
    const [reviewsStatus, setReviewsStatus] = useState(false);
    const [FavStatus, setFavStatus] = useState(false);
    const [petsStatus, setPetsStatus] = useState(false);
    const [passwordStatus, setPasswordStatus] = useState(false);
    const [helpStatus, setHelpStatus] = useState(false);
    const [image, setImage] = useState()
    const searchNavBarDisplayStatus = useSelector((state) => state.searchNavBarDropdownDisplayStatus)

    const sendImage = (path) => {
        // console.log(path)
        setImage(path)
    }

    useEffect(() => {
        // console.log(profileImage)
        if (profileImage.image)
        { 
        setProfileLogo(profileImage.image)
        } else {
        //   console.log("none")
          setProfileLogo("images/user.jpg")
        }
        // setuserLocalStorageData(userData)
    },[profileImage])
  
    const openProfile = () => {
        setProfileStatus(true)
        setOrdersStatus(false)
        setInboxStatus(false)
        setReviewsStatus(false)
        setFavStatus(false)
        setPetsStatus(false)
        setPasswordStatus(false)
        setHelpStatus(false)
    }

    const openMyOrders = () => {
        setOrdersStatus(true)
        setProfileStatus(false)
        setInboxStatus(false)
        setReviewsStatus(false)
        setFavStatus(false)
        setPetsStatus(false)
        setPasswordStatus(false)
        setHelpStatus(false)
    }

    const openInbox = () => {
        setInboxStatus(true)
        setProfileStatus(false)
        setReviewsStatus(false)
        setFavStatus(false)
        setPetsStatus(false)
        setPasswordStatus(false)
        setHelpStatus(false)
        setOrdersStatus(false)
    }
    const openReviews = () => {
        setReviewsStatus(true)
        setProfileStatus(false)
        setInboxStatus(false)
        setFavStatus(false)
        setPetsStatus(false)
        setPasswordStatus(false)
        setHelpStatus(false)
        setOrdersStatus(false)

    }
    const openFav = () => {
        setFavStatus(true)
        setProfileStatus(false)
        setInboxStatus(false)
        setReviewsStatus(false)
        setPetsStatus(false)
        setPasswordStatus(false)
        setHelpStatus(false)
        setOrdersStatus(false)

    }
    const openPets = () => {
        setPetsStatus(true)
        setProfileStatus(false)
        setInboxStatus(false)
        setReviewsStatus(false)
        setFavStatus(false)
        setPasswordStatus(false)
        setHelpStatus(false)
        setOrdersStatus(false)

    }
    const OpenPassword = () => {
        setPasswordStatus(true)
        setProfileStatus(false)
        setInboxStatus(false)
        setReviewsStatus(false)
        setFavStatus(false)
        setPetsStatus(false)
        setHelpStatus(false)
        setOrdersStatus(false)

    }
    const openHelep = () => {
        setHelpStatus(true)
        setProfileStatus(false)
        setInboxStatus(false)
        setReviewsStatus(false)
        setFavStatus(false)
        setPetsStatus(false)
        setPasswordStatus(false)
        setOrdersStatus(false)

    }
    useEffect(() => {
        if (JSON.parse(window.localStorage.getItem("paw5-login-data"))) {
            setUserFullName(JSON.parse(window.localStorage.getItem("paw5-login-data")).data.first_name + 
          ' ' +
          JSON.parse(window.localStorage.getItem("paw5-login-data")).data.last_name)

        //   console.log(IMG_URL+
        //     JSON.parse(window.localStorage.getItem("paw5-login-data")).data.image)
          setProfileLogo(IMG_URL+
            JSON.parse(window.localStorage.getItem("paw5-login-data")).data.image)

        } 

//         if(profileImage)
// {
//   console.log("pro")
//   setProfileLogo(IMG_URL+profileImage)
// } else 

      }, [userLocalStorageData]);
    

      const changeSearchBarDisplayStatus = () => {
        const allWithClass = Array.from(document.getElementsByClassName("autocomplete-dropdown-container"));
        let displayStatus = allWithClass[0].style.display
        if(displayStatus === '')
        {
          document.getElementsByClassName("autocomplete-dropdown-container")[0].style.display = 'none';
          dispatch(searchNavBarDropdownDisplayStatus('none'))
        } 
      }

      const handleImage = (data) => {
        let reader = new FileReader();
        reader.readAsDataURL(data[0]);
        reader.onload = (e) => {
          console.log(e.target.result)
        //   setbase64Image(e.target.result);
        };
      };

      const handlerProfileImage = (e) => {
        handleImage(e);
        // fetch(IMG_URL, 'api/user/profile-image',{
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Authorization': 'Bearer '+ userData.token
        //     }, 
        //     body: JSON.stringify({image: base64})
        // }).then((res) => res.json()).
        // then((data) => console.log(data))
        // .catch((err) => console.log(err))
      }
  return (
    <section>
            <div className="container">
                <div className="row clearfix" onClick={changeSearchBarDisplayStatus}>
                    <div className="col-12 col-md-3 col-lg-4 col-xl-4">
                        <aside className="box-shadow">
                            <div className="user-header">
                                <div className="user-pic">
                                    <img src=
                                    {profileLogo  ? IMG_URL+profileLogo : "images/user.jpg" } 
                                    alt="Image" />
                                    <a onClick={handlerProfileImage} className="edit"><i className="fa fa-pencil"></i></a>
                                </div>
                                <div className="username mt-3">{userFullName}</div>
                            </div>
                            <div className="user-body">
                                <ul className="nav nav-pills flex-column sidenav">
                                    <li className="nav-item">
                                      <a className={profileStatus ? "nav-link active" : "nav-link"} aria-current="page" 
                                      onClick={openProfile}
                                      ><i className="bi bi-person-fill"></i> Profile</a>
                                    </li>
                                    <li className="nav-item">
                                      <a className="nav-link" 
                                        onClick={openMyOrders}><i className="bi bi-clipboard-fill"></i> My Orders</a>
                                    </li>
                                    <li className="nav-item">
                                      <a className="nav-link" 
                                        onClick={openInbox}><i className="bi bi-chat-right-text-fill"></i> Inbox</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" 
                                          onClick={openReviews}><i className="bi bi-stars"></i> My Reviews</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" 
                                          onClick={openFav}><i className="bi bi-heart-fill"></i> My Favourite</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" 
                                          onClick={openPets}><i className="bi bi-clipboard-fill"></i> My Pets</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={OpenPassword}
                                          ><i className="bi bi-key-fill"></i> Manage Password</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link"   onClick={openHelep}><i className="bi bi-question-circle-fill"></i> Help</a>
                                    </li>
                                </ul>
                            </div>
                        </aside>
                    </div>
                    {
                       profileStatus && (
                   <Profile sendImage={sendImage}/>
                   ) 
                }

                {
                    ordersStatus && (
                        <MyOrders />
                    )
                }
                   
                   {
                    inboxStatus && (
                        <Inbox />
                    )
                   }
                   
                   {
                    reviewsStatus && (
                        <Reviews />
                    )
                   }

                  { FavStatus && (
                     <Favourite />
                  )}
                  
                  {
                    petsStatus && (
                        <Pets />
                    )
                  }
                   
                   {
                    passwordStatus && (
                        <ManagePassword />
                    )
                   }
                  
                  {
                    helpStatus && (
                        <Help /> 
                    )
                  }
                  
                </div>
            </div>
        </section>
  )
}

export default Dashboard;