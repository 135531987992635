import React from "react";

const LeftSide = () => {
  return (
    <>
      <div className="col-12 col-lg-6 col-xl-6 text-center">
        <h2 className="signup">Hello Pawfive</h2>
        <ul className="list clearfix">
          <li>
            Lorem Ipsum is simply dummy text of the printing and typesett
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </li>
          <li>
            Lorem Ipsum is simply dummy text of the printing and typesett
            industry. Lorem Ipsum
          </li>
          <li>
            Lorem Ipsum is simply dummy text of the printing and typesett
            industry. Lorem Ipsum has been the industry's 1500s
          </li>
        </ul>
        <img
          src="images/signup-img.png"
          className="img-fluid mx-auto mt-3"
          alt="Image goes here"
        />
      </div>
    </>
  );
};

export default LeftSide;
