import {SET_NAME,
  SET_PROFILE_IMAGE, 
  LOG_OUT,GET_BANNERS, 
  SET_NAVBAR_SEARCH_DROPDOWN_DISPLAY_STATUS,
  SET_BANNER_SEARCH_DROPDOWN_DISPLAY_STATUS,
  SET_PROVIDER_SERVICES,
  SET_SUB_CATEGORIES,
  STORE_EDIT_DATA,
  STORE_MAIN_CATEGORIES_DATA,
  STORE_AMENITIES_FOR_SELECTED_SERVICE_ADD_MODULE,
  STORE_AUTO_ADDRESS_LOCATION,
  SET_LOGIN_STATUS,
  SET_USER_DATA,
  SET_ACCORDION_AMENITIES_DATA,
  SET_ACCORDION_MAIN_CAT_IDS,
  SET_ACCORDION_CHECKED_STATUS

} from '../constant'

export const fullName = (name) => {
  return {
    type: SET_NAME,
    name
  }
}

export const storingUserData = (data) =>{
  return {
    type: SET_USER_DATA,
    data
  }
}

export const storeAccordionAllAmenitiesAction = (data) =>{
  return {
    type: SET_ACCORDION_AMENITIES_DATA,
    data
  }
}

export const storeAccordionMainCatIDSAction = (data) =>{
  return {
    type: SET_ACCORDION_MAIN_CAT_IDS,
    data
  }
}

export const storeAccordionCheckedStatusAction = (data) =>{
  return {
    type: SET_ACCORDION_CHECKED_STATUS,
    data
  }
}

export const loginStatusAction = (status) => {
  return {
    type: SET_LOGIN_STATUS,
    payload: status
  }
}

export const profileImage = (image) => {

  return {
    type: SET_PROFILE_IMAGE,
    image
  }
}

export const logout = () => {
  console.log("action")
  return {
    type: LOG_OUT,
  }
}

export const banners = (images) => {
  return {
    type: GET_BANNERS,
    images
  }
}

export const searchNavBarDropdownDisplayStatus = (status) => {

  return{
    type: SET_NAVBAR_SEARCH_DROPDOWN_DISPLAY_STATUS,
    status
  }
}

export const searchBannerDropdownDisplayStatus = (status) => {

  return{
    type: SET_BANNER_SEARCH_DROPDOWN_DISPLAY_STATUS,
    status
  }
}

export const getProviderServicesAction = (serv) => {
  return {
    type: SET_PROVIDER_SERVICES,
    serv
  }
}

export const getSubCategories = (subCat) => {
  return {
    type: SET_SUB_CATEGORIES,
    subCat
  }
}

export const storeEditData = (data) => {
  return {
    type: STORE_EDIT_DATA,
    data
  }
}

export const storeMainCategoriesAction = (data) => {
  return {
    type: STORE_MAIN_CATEGORIES_DATA,
    data
  }
}

export const storeAmenitiesForSelectedServiceAction = (data) => {
  return {
    type: STORE_AMENITIES_FOR_SELECTED_SERVICE_ADD_MODULE,
    data
  }
}

export const storeAutoAddressLatLongAction = (data) => {
  return {
    type: STORE_AUTO_ADDRESS_LOCATION,
    data
  }
}
