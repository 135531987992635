import React,{useEffect} from 'react'

const WorkingHoursModal = (props) => {

  return (
    
    <div className="modal fade" id="viewWorkingHoursModals"  aria-labelledby="viewWorkingHoursModalsLabel"
            aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">View Working Hours</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>                        
                    </div>
                    <div className="modal-body text-center">  
                      
                        <div className="row text-right">
                            <div className="col-sm-2">&nbsp;</div>
                            <div className="col col-sm-5">
                                <h6><strong>Open</strong></h6>
                            </div>
                            <div className="col col-sm-5">
                                <h6><strong>Close</strong></h6>
                            </div>
                        </div>
                        {
                      props.hours.map((item, index) => {
                            return (   
                        <div className="form-group row mb-2 clearfix"
                        key={item.id}>
                            <label htmlFor="sunday" className="col-sm-2 mt-md-2">{item.week}</label>
                            <div className="col-sm-10">
                                <div className="row clearfix">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" 
                                            key={item.id}
                                            value={item.open_time}
                                            aria-label="HH:MM" aria-describedby="am" disabled />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" 
                                            key={item.id}
                                            value=  {item.close_time} aria-label="HH:MM" 
                                            aria-describedby="pm" disabled />
                                       </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            )

                           })
                          }    
                    </div>
                    
                </div>
            </div>
        </div>
  )
}

export default WorkingHoursModal