import React, { useEffect,useState} from 'react'
import { API_URL, IMG_URL } from "../constant";
import { storeMainCategoriesAction } from "../redux/actions/action";
import { useDispatch} from 'react-redux'



const FetchCategories = (url) => {
  const dispatch = useDispatch();

  // console.log("fetchcat called")
  const [data, setData] = useState(null);
  //const url = API_URL+'service/main-categories'
// console.log(url)
  useEffect(() => {
    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => 
// console.log("hooks data", data)
dispatch(storeMainCategoriesAction(data.data))
// setData(data.data)
      );
  }, [url]);

  return [data];
};

export default FetchCategories;

// const rawResponse = await fetch('https://httpbin.org/post', {
//     method: 'POST',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({a: 1, b: 'Textual content'})
//   });
//   const content = await rawResponse.json();