import React from 'react'

const Reviews = () => {
  return (
    <>
    <div className="col-12 col-md-9 col-lg-8 col-xl-8">
    <div class="dashboard-title">Reviews</div>
    <div className="inbox box-shadow align-middle">
    <img src="images/pawsitive-logo.jpg" alt="Image goes here" />
    <div className="notification-text">
        Pawsitive Service
        <span><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-half"></i><i className="bi bi-star"></i></span>
        <span className="d-block">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been dummy text of the printing and typesetting industry. The industry's standard dummy text ever since the 1500s, when an</span>
    </div>
</div>
<div className="inbox box-shadow align-middle">
    <img src="images/pawsitive-logo.jpg" alt="Image goes here" />
    <div className="notification-text">
        Pawsitive Service
        <span><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-half"></i><i className="bi bi-star"></i></span>
        <span className="d-block">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been dummy text of the printing and typesetting industry. The industry's standard dummy text ever since the 1500s, when an</span>
    </div>
</div>
<div className="inbox box-shadow align-middle">
    <img src="images/pawsitive-logo.jpg" alt="Image goes here" />
    <div className="notification-text">
        Pawsitive Service
        <span><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-half"></i><i className="bi bi-star"></i></span>
        <span className="d-block">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been dummy text of the printing and typesetting industry. The industry's standard dummy text ever since the 1500s, when an</span>
    </div>
</div>
</div>
</>
  )
}

export default Reviews